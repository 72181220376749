import styled, {keyframes} from 'styled-components';
import {IContainerProps} from '.';

const hideAnimation = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: -9999px; 
    }
`;

export const Wrapper = styled.div<IContainerProps>`
  background-color: var(--opacity-background);
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  display: ${props => props.isExpanded ? 'block' : 'none'};

  -webkit-animation: ${props => !props.isExpanded ? hideAnimation : 'none'} .2s forwards;
  -webkit-animation-iteration-count: 1;
  animation: ${props => !props.isExpanded ? hideAnimation : 'none'} .2s forwards;
  animation-iteration-count: 1;
`;

export const Container = styled.div<IContainerProps>`
  background-color: var(--background);
  position: absolute;
  display: ${props => props.isExpanded ? 'block' : 'none'};
  z-index: 3;
  top: 0;
  width: ${props => props.type === 'notificação' ? '30' : '80'}%;
  height: 100%;
  overflow: auto;
  right: ${props => props.isExpanded ? '0' : `${props.type === 'notificação' ? '-30%' : '-80%'}`};
  box-shadow: 5px 7px 3px -2px rgba(0,0,0,0.41);
  transition: .2s;

  @media(max-width: 870px) {
    width: 80%;
    right: ${props => props.isExpanded ? '0' : '-80%'};
  }
`;

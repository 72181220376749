import provider from './config';

export const getAddressCep = async (cep: string): Promise<any> =>{
  try {
    const response = await provider.get("/entidades/correios/", {
      params: {
        cep
      }  
    })

    return response.data
  } catch (error: any) {
    throw Error("Ocorreu um erro ao tentar calcular o pedido");
  }
}
import React, { useCallback } from 'react';
import { Item, Botao } from './styles';

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isSelected: boolean;
}

const TabTitle: React.FC<Props> = ({
  title,
  index,
  setSelectedTab,
  isSelected
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);
  
  return (
    <Item>
      <Botao onClick={onClick} isSelected={isSelected}>{title}</Botao>
    </Item>
  )
};

export default TabTitle;
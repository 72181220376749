/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosRequestHeaders } from 'axios';
import React, {useState, useEffect, useRef} from 'react';
import { ActionMeta } from 'react-select';
import {Container, AsyncSelect, ToolTip} from './styles';

import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../contexts/auth';
import { treeify } from '../../utils/format';
import { removeSpecials, treatClasses } from '../LookupInput';
import Toast from '../Toast';
import ReactTooltip from 'react-tooltip';

export interface ISelectItem {
  label: string;
  value: string;
}

interface Props {
    endpoint: string;
    field: string;
    labelField?: string;
    filters?: any;
    multi?: boolean;
    token?: string;
    uf?: string;
    isRecursoTable?: boolean;
    onChange?(options: any): void;
    disabled?: boolean;
    initialState?: any;
    menuPlacement?: "auto" | "top" | "bottom";
}

const LookupInput: React.FC<Props> = ({endpoint, field, isRecursoTable, labelField, filters, uf, token, disabled, onChange, initialState, multi = true}) => {
  const lookupHandler = useFetch({endpoint});
  const lookupRef = useRef<any>(null);
  const {user} = useAuth();
  const [value, setValue] = useState<ISelectItem[]>([]);
  const [baseOptions, setBaseOptions] = useState<ISelectItem[]>([]);
  const [tooltip, _setTooltip] = useState('');
  const tooltipRef = React.useRef(tooltip);
  const isClasses = endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/';

  useEffect(() => {
    if (lookupRef && lookupRef.current !== null) {
      lookupRef.current.addEventListener('copy', (event: any) => {
        event.preventDefault();
        handleCopyToClipboard(tooltipRef.current || '');
      });
    }
    return () => window.removeEventListener('copy', () => {});
  }, [lookupRef]);

  useEffect(() => {
    if (initialState) {
      if (initialState.nome && initialState.id) {
        const toReturn = {
          value: initialState.id,
          label: labelField && initialState[labelField] ? initialState[labelField] : initialState.nome,
          item: initialState,
        }
        handleChange(toReturn);
        // loadOptions('', true, true, toReturn);
      } else if (initialState.byName) {
        loadOptions(initialState.byName, true);
      } else if(typeof initialState === 'string') {
        loadOptions(initialState, true);
      } else if (initialState.byId) {
        loadOptions(initialState.byId, true, false, null, true);
      } else {
        setValue(initialState);
      }
    }
  }, [initialState]);

  useEffect(() => {
    if(uf && initialState && Object.keys(initialState).length <= 0){
      loadBaseOptions();
    }
  }, [uf])

  useEffect(() => {
    if (!initialState && !isRecursoTable) {
      loadBaseOptions();
    }
  }, []);

  const setTooltip = (data: string) => {
    tooltipRef.current = data;
    _setTooltip(data);
  };

  const loadBaseOptions = async() => {
    const options = await loadOptions('', true);
    setBaseOptions(options);
  }

  const loadOptions = async (
    inputValue: string,
    loadInitialState: boolean = false,
    onlyReturn: boolean = false,
    toReturn: any = null,
    isId: boolean = false,
  ): Promise<any[]> => {
    if (!loadInitialState) return [];
    if (onlyReturn) {
      return [toReturn];
    }

    const headers: AxiosRequestHeaders = {};
    const userToken = token ? token : user?.token;

    if (userToken) {
        headers.Authorization = `Bearer ${userToken}`;
    }
    
    let params: any = {
      // page: 1,
    };

    if (inputValue) {
      if (isId) {
        params = { ...params, id: inputValue };
      } else {
        params = { ...params, [field]: inputValue, };
      }
    } else {
      params = {...params, page: 1, take: 5};
    }

    if (uf) {
      params = {...params, uf};
    }

    if (filters) {
        params = {...params, ...filters, };
    }

    if (isClasses) {
      params = {
        ...params,
        all: true,
      }
    }
    
    let data = await lookupHandler.get(params, headers);

    if (data.docs) {
      data = data.docs;
    }
    
    if (data.length > 0 && isClasses) {
      const withChildrenClasses = [];

      for (let i = 0; i < data.length; i++) {
        const classe = data[i];
        if (!classe.hasOwnProperty('agrupamento') || classe.agrupamento === false) {
          withChildrenClasses.push({ ...classe, children: [] });
        }
      }
      const arvoreClasses = treeify(withChildrenClasses, 'idPai');
      data = treatClasses(arvoreClasses);
    }
    
    const formatted = [];

    if (data.length <= 0 && initialState) {
      handleChange([]);
    }

    for (const item of data) {
      let found = false;
      const codigo = labelField && item[labelField] ? item[labelField] : `${item.codigo ? item.codigo : ''}${item.nomeFantasia ? ` - ${item.nomeFantasia}` : item.nome ? ` - ${item.nome}` : ''}`;
      if (initialState) {
        if (initialState.byName) {
          let itemNome = item.nome ? item.nome.toLowerCase() : '';
          let initialStateByName = initialState.byName ? initialState.byName.toLowerCase() : '';
          let itemCodigo = item.codigo ? item.codigo.toLowerCase() : '';
          if (itemNome.includes(initialStateByName) || itemCodigo.includes(initialStateByName)) {
            handleChange({
              value: item.id,
              label: isClasses ? removeSpecials(item.nome).trim() : codigo,
              item,
            });
            found = true;
          } else {
            handleChange([])
          }
        } else if (initialState.byId && item.id === initialState.byId) {
          handleChange({
            value: item.id,
            label: isClasses ? removeSpecials(item.nome).trim() : codigo,
            item,
          });
          found = true;
        }
      } 

      formatted.push({
          value: item.id,
          label: isClasses ? item.nome : codigo,
          item,
      });

      if (found) {
        break;
      }
    }

    return formatted;
  };

  const handleChange = (newValue: unknown, actionMeta?: ActionMeta<unknown>) => {
    if (onChange && newValue) {
      let optionValue: any = newValue;
      
      if (isClasses && optionValue && !Array.isArray(optionValue)) {
        optionValue = {
          ...optionValue,
          label: removeSpecials(optionValue.label).trim(),
        }
      }
      const options = optionValue as ISelectItem[];
      onChange(options);
      setValue(options);
    } else if (onChange && newValue === null) {
      onChange(null);
      setValue([]);
    }
  }

  const handleMouseEnter = () => {
    const _value = value as any;
    if (_value.item) {
      const item = _value.item;
      const codigo = `${item.codigo ? item.codigo : ''}${item.nomeFantasia ? ` - ${item.nomeFantasia}` : item.nome ? ` - ${item.nome}` : ''}`;
      setTooltip(codigo);
    } else if(_value.label) {
      setTooltip(_value.label);
    }
  }

  const handleCopyToClipboard = (_value: string) => {
    navigator.clipboard.writeText(_value);
    Toast.show('Texto copiado com sucesso', 'success');
  }

  return (
    <Container ref={lookupRef}>
        <ReactTooltip id="global" place="top" effect="float" textColor='transparent' backgroundColor='transparent'>
          <ToolTip show={tooltip ? true : false}>{tooltip}</ToolTip>
        </ReactTooltip>
        <div data-tip data-for="global" onMouseEnter={handleMouseEnter} onMouseLeave={() => setTooltip('')}>
          <AsyncSelect
              isDisabled={disabled}
              isMulti={multi}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              maxMenuHeight={150}
              value={value}
              loadOptions={loadOptions}
              defaultOptions={baseOptions}
              onChange={handleChange}
              isClearable
              noOptionsMessage={() => "Nenhum item"}
              placeholder=""
              loadingMessage={() => "Carregando..."}
          />
        </div>
    </Container>
  );
}

export default LookupInput;
import React from "react";

import Logo from "../../assets/images/logo.png"
import { Container, LinhaVertical, Link } from "./styles";

const Footer = () => {

  const date = new Date()
  const yearDate = date.getFullYear()

  return (
    <Container>
      <img src={Logo} alt="Logo Devari Tecnologia" />
      &nbsp;<p>&copy;{yearDate} Devari Tecnologia</p>
      <LinhaVertical />
      <Link href="#">Suporte</Link>
    </Container>
  )
}

export default Footer;
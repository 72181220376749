import React, {useState, ReactElement} from 'react';
import {Menu, Item, useContextMenu } from 'react-contexify';
import {Wrapper, Container} from './styles';
import {IoCheckmark} from 'react-icons/io5';

export interface IToggleMenuOption {
  id: number;
  name?: string;
  separator?: boolean;
  selectable?: boolean;
  selected?: boolean;
  onClick?(item: any): void; 
}

interface IToggleMenuProps {
  icon?(): ReactElement;
  options: Array<IToggleMenuOption>;
  item?: any;
  index?: number;
}

const ToggleMenuButton: React.FC<IToggleMenuProps> = ({icon, children, options, item, index, ...props}) => {
  const contextMenuId = `current-menu-context-${index}`;

  const { show } = useContextMenu({
    id: contextMenuId,
  });

  const [menu, setMenu] = useState<Array<IToggleMenuOption>>(options);

  const handleOnClick = (option: IToggleMenuOption) => {
    if (option.selectable) {
      handleSelectOption(option.id);
    } 

    if (option.onClick) {
      option.onClick(item);
    }
  }

  const handleSelectOption = (id: number) => {
    const newOptions = menu.map(option => {
      if (option.id === id) {
        return {...option, selected: !option.selected};
      }

      return option;
    });

    setMenu(newOptions);
  }

  return (
    <Wrapper>
        <Container onClick={show} {...props}>
          {icon && icon()}
          {children}
        </Container>
        <Menu id={contextMenuId}>
          {menu.map(action => (
            <Item
              key={action.id}
              onClick={() => handleOnClick(action)}
            >
              {action.name}
              {action?.selectable && action?.selected && <IoCheckmark color="green" size={20} fontWeight="600" />}
            </Item>
          ))}
        </Menu>
    </Wrapper>
  );
}

export default ToggleMenuButton;
import React from 'react';
import { Container, Content, RoutesContainer } from './styles';

import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import Footer from '../Footer';

import AuthRoutes from '../../navigation/auth';

const Layout: React.FC<any> = (props) => {
  const path = props?.location?.state?.from?.state?.from?.pathname;
  const pathname = path ? path : null;
  return (
    <Container>
      <Header />
      <Content>
        <LeftSidebar />
        <RoutesContainer>
          <AuthRoutes initialPath={pathname} />
        </RoutesContainer>
        <Footer />
      </Content>
      <RightSidebar />
    </Container>
  );
}

export default Layout;
import styled from 'styled-components';

import InputLayout from '../../components/Input';
import ButtonLayout from '../../components/Button';
import LinkButtonLayout from '../../components/LinkButton';

import Background from '../../assets/images/background.jpg';

export const Container = styled.div`
  background-image: url(${Background});
  width: 100%;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  background-color: var(--form-background);
  width: 30%;
  min-height: 300px;
  padding: 16px;
  box-shadow: 5px 7px 3px -2px rgba(0,0,0,0.41);
  border-radius: 2px;

  & form fieldset:nth-last-child(3n){
    margin-bottom: 32px;
  }

  @media(max-width: 1048px) {
    & {
      width: 80%;
    }
  }

  @media(max-width: 420px) {
    & {
      width: 90%;
    }
  }
`;

export const Form = styled.form``;

export const Input = styled(InputLayout)`
  margin-bottom: 16px;
`;

export const Button = styled(ButtonLayout)`
  margin-bottom: 16px;
`;

export const Link = styled(LinkButtonLayout)`
  margin-bottom: 16px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 70px;
  object-fit: contain;
  margin-bottom: 32px;
`;

export const Title = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: var(--primary-text-color);
`;

export const Subtitle = styled.p`
  font-size: 22px;
  font-weight: 300;
  color: var(--muted);
  margin-bottom: 32px;
`;
//-- child: campo objeto no payload de retorno.
//-- readonly: esse campo só e usado para visualização e não entra no fluxo de buscar os campos no xml

export interface IKey {
    field: string;
    label: string;
}

export const data = {
    fornecedores: {
        rootNode: 'emit',
        keys: {
            CNPJ: {
            field: 'cpfCnpj',
            label: 'CPF/CNPJ.....',
            },
            IE: {
            field: 'inscricaoEstadual',
            label: 'Insc Estadual',
            },
            xNome: {
            field: 'nome',
            label: 'Nome.........',
            },
            xFant: {
            field: 'nomeFantasia',
            label: 'Fantasia.....',
            },
            CEP: {
            field: 'cep',
            label: 'CEP..........',
            },
            UF: {
            field: 'idUF',
            label: 'UF...........',
            },
            xMun: {
            field: 'idCidade',
            label: 'Município....',
            },
            xBairro: {
            field: 'bairro',
            label: 'Bairro.......',
            },
            xLgr: {
            field: 'endereco',
            label: 'Endereço.....',
            },
            nro: {
            field: 'numeroEndereco',
            label: 'Número.......',
            },
            fone: {
            field: 'telefone',
            label: 'Telefone.....',
            },
        },
    },
    produtos: {
        rootNode: 'prod',
        keys: {
            cProd: {
                field: 'codigoFornecedor',
                label: 'Código Fornecedor...',
                child: {
                    field: 'codigos',
                    fieldChild: 'codigo'
                }
            },
            cEAN: {
                field: 'ean',
                label: 'EAN.................'
            },
            xProd: {
                field: 'nome',
                label: 'Nome................'
            },
            NCM: {
                field: 'idNCM',
                label: 'NCM.................'
            },
            // codNCM: {
            //     readonly: true,
            //     label: 'Código. NCM.........',
            //     child: {
            //         field: 'NCM',
            //         fieldChild: 'codigo'
            //     }
            // },
            uTrib: {
                field: 'idUnidadeMedida',
                label: 'Un. medida..........'
            },

        },
    }
}
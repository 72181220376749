import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--background);
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  transition: .2s;
  overflow: hidden;
  position: relative;
`;

export const RoutesContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  padding: 16px;
  transition: .2s;
  overflow: auto;
`;
import provider from './config';

export const calcularPedido = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/calcular/', payload);
  
      return response.data;
    } catch (err: any) {
      throw Error("Ocorreu um erro ao tentar calcular o pedido");
    }
}

export const getTitulosCondicao = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/gerar-titulos-condicao/', payload);
  
      return response.data;
    } catch (err: any) {
      throw Error("Ocorreu um erro ao tentar gerar os títulos da condição de pagamento");
    }
}

export const criarPedido = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('/pedidos/', payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar criar o pedido");
  }
}

export const updatePedido = async (id: number, payload: any): Promise<any> => {
  try {
    const response = await provider.put(`/pedidos/${id}`, payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar atualizar o pedido");
  }
}

export const deleteOrders = async (id: any, chCriacao: number, chaves?: any[]): Promise<any> => {
  const params: any = {
    usuario: id,
  };

  if (chaves && chaves.length > 0) {
    params.chaves = chaves.join(',');
  }

  try {
    const response = await provider.delete(`/pedidos/baixa/${chCriacao}`, {
      params
    })

    return response
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar excluir o pedido")
  }
}

export const getBaixa = async (chcriacao: any, baixa: any): Promise<any> => {
  const params: any = {
    id: chcriacao,
  };

  if (baixa !== null) params.baixa = baixa;
  try {
    const response = await provider.get('/pedidos/', {
      params,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a baixa");
  }
}

export const regraClasse = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-classe', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de classe");
  }
}

export const regraNucleo = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-nucleo', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de núcleo");
  }
}

export const regraCfop = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-cfop', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de CFOP");
  }
}

export const regraPreco = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/precificacao', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de preço");
  }
}

export const getChavePedido = async (idUsuario: any): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/chave-pedido', {
      params: {
        idUsuario,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a chave de criação");
  }
}

export const checkChavesParaBaixa = async (chaves: number[]): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/checar-baixas', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar as baixas dos items");
  }
}

export const checkIfPedidoHasBaixa = async (chaves: number[]): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/checar-baixa-pedido-aprovado', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao verificar se o pedido tem baixa");
  }
}

export const getItemsAndTitulos = async (chaves: number[], all = false): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/items-titulos', {
      params: {
        chaves: chaves.join(','),
        all,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar os items e títulos");
  }
}

export const getReport = async (page: number) => {
  try {
    const response = await provider.get('/pedidos/', {
      params: {
        page: page,
        classe: 'Rendas',
        baixa: true
      }
    })

    return response.data
  } catch (err: any) {
    throw Error("Erro ao buscar os pedidos de saída.")
  }
}

export const getPurchaseReport = async (params:any) => {
  try {
    const response = await provider.get('/relatorios/entradas', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório Pedido de entradas");
  }
}

export const getSellingReport = async (params:any) => {
  try {
    const response = await provider.get('/relatorios/saidas', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório Pedido de Saída");
  }
}

export const checkCHacessoNF = async (chacessonf: string) => {
  try {
    const response = await provider.get('/pedidos/xml', {
      params: {
        chacessonf
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a chave de acesso da NFe");
  }
}
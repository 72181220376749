/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { IoMenu, IoSettings } from 'react-icons/io5';
import ReactPaginate from 'react-paginate';
import {
  Container,
  Header,
  HeaderCell,
  Row,
  Body,
  Cell,
  Footer,
  FooterContent,
  SelectedContainer,
  PaginateContainer,
} from './styles';

import ToggleMenuButton, {IToggleMenuOption} from '../ToggleMenuButton';
import CellComponent from './CellComponent';

import {useModal} from '../../contexts/modal';

export interface ICellProps {
  dragOver: boolean;
}

export interface IField {
  campo: string;
  classe?: string;
  nome: string;
  codigo: string;
  editavel: boolean;
  mascara?: string;
  tabela?: string;
  tipo?: string;
  required?: boolean;
  opcoes?: Array<string>;
  data?: any;
}

interface IProps {
  fields: Array<IField>,
  type?: string,
  data: any[],
  onChangePage?: (value: number) => void;
  currentPage?: number;
  totalPages?: number;
  count?: number;
  rowMenuOptions?: Array<IToggleMenuOption>;
  withFooter?: boolean;
  multipleSelect?: boolean;
  changeSelectedIndexes?: (selectedIndexes: boolean[]) => void;
}

const ListTable: React.FC<IProps> = ({
  fields, 
  data, 
  type, 
  totalPages = 1,
  count = 0,
  onChangePage,
  rowMenuOptions,
  withFooter = true,
  multipleSelect,
  changeSelectedIndexes,
}) => {

  const {openModal} = useModal();
  const [cols, setCols] = useState(fields);
  const selectAll = false;
  const [selected, setSelected] = useState<any[]>([]);
  const [rows, setRows] = useState(data);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [dragOver, setDragOver] = useState("");

  useEffect(() => {
    setCols(fields);
  }, [fields]);

  useEffect(() => {
    setRows(data);

    if (data.length > 0) {
      const selectedIndexes = new Array(data.length).fill(false);
      setSelectedIndexes(selectedIndexes);
    }
  }, [data]);

  useEffect(() => {
    handleSelected(true);
  }, [selectAll]);

  useEffect(() => {
    if (multipleSelect && changeSelectedIndexes && selectedIndexes.length > 0) {
      changeSelectedIndexes(selectedIndexes);
    }
  }, [multipleSelect, changeSelectedIndexes, selectedIndexes]);

  const areAllSelected = (): boolean => selectedIndexes.length > 0 ? selectedIndexes.every((value: boolean) => value === true) : false;

  const toggleSelect = (ind: number | null = null) => {
    if (ind !== null) {
      const newSelected = selectedIndexes.map((oldValue: boolean, index: number) => {
        if (index === ind) {
          return !oldValue;
        } else {
          return oldValue;
        }
      });
      return setSelectedIndexes(newSelected);
    }

    if (selectedIndexes.every((value: boolean) => value === true)) {
      setSelectedIndexes(new Array(selectedIndexes.length).fill(false));
    } else {
      setSelectedIndexes(new Array(selectedIndexes.length).fill(true));
    }
  }

  const handleSelected = (isSelectAll: boolean, id?: number) => {
    let newSelected: any[] = [];

    if (isSelectAll) {
      if (selectAll) {
        newSelected = rows.map(row => row.id);
      }
      return setSelected(newSelected);
    } else {
      if (id) {
        if (selected.includes(id)) {
          newSelected = selected.filter(selectedId => selectedId !== id);
          return setSelected(newSelected);
        } else {
          newSelected = [...selected, id];
          return setSelected(newSelected);
        }
      }
    }
  }

  const handleDragStart = (e: any) => {
    const { id } = e.target;

    let indexOfDragged = -1;
    let col;

    for (let i = 0; i < cols.length; i++) {
      col = cols[i];
      if (col.nome === id) {
        indexOfDragged = i;
        break;
      }
    }

    if (indexOfDragged === -1) return;
    e.dataTransfer.setData("colIdx", indexOfDragged);
  };

  const handleDragOver = (e: any) => e.preventDefault();
  const handleDragEnter = (e: any) => {
    const { id } = e.target;
    setDragOver(id);
  };

  const handleOnDrop = (e: any) => {
    const { id } = e.target;

    let indexOfDroppedCol = -1;
    let droppedCol;

    for (let i = 0; i < cols.length; i++) {
      droppedCol = cols[i];
      if (droppedCol.nome === id) {
        indexOfDroppedCol = i;
        break;
      }
    }

    if (indexOfDroppedCol === -1) return;

    const droppedColIdx = indexOfDroppedCol;
    const draggedColIdx = e.dataTransfer.getData("colIdx");
    const tempCols = [...cols];

    tempCols[draggedColIdx] = cols[droppedColIdx];
    tempCols[droppedColIdx] = cols[draggedColIdx];
    setCols(tempCols);
    setDragOver("");
  };

  const renderRows = () => {
    const tableRows = rows.map((row: any, index: number) => {

      let menuOptions: IToggleMenuOption[] = []; 

      if (rowMenuOptions) {
        if (row.hasOwnProperty('editavel') || row.hasOwnProperty('excluivel')) {
          for (const _option of rowMenuOptions) {
            if (_option.name === 'Editar' && row.editavel === false) {
              _option.onClick = () => openModal({
                title: 'Atenção',
                type: 'submit',
                submitLabel: 'OK',
                onSubmit: () => {},
                children: () => (<p>Registro próprio do sistema não poderá ser editado ou excluído.</p>),
              });
              menuOptions.push(_option);
            } else if (_option.name === 'Excluir' && row.excluivel === false) {
              _option.onClick = () => openModal({
                title: 'Atenção',
                type: 'submit',
                submitLabel: 'OK',
                onSubmit: () => {},
                children: () => (<p>Registro próprio do sistema não poderá ser editado ou excluído.</p>),
              });
              menuOptions.push(_option);
            } else {
              menuOptions.push(_option);
            }
          }
        } else {
          menuOptions = rowMenuOptions;
        }
      }

      return (
        <Row id={String(index)} key={String(index)}>
          <Cell dragOver={false}>
            {menuOptions.length > 0 && (
              <ToggleMenuButton
                icon={() => <IoMenu size={20} color="var(--primary-text-color)" />}
                options={menuOptions}
                item={row}
                index={index}
              />
            )}
          </Cell>
          {multipleSelect && (
            <Cell dragOver={false}>
              <input type="checkbox" checked={selectedIndexes[index]} onChange={() => toggleSelect(index)} />
            </Cell>
          )}
          {cols.map(col => col.campo !== 'id' ? (
            <Cell key={`${index}${col.campo}`} dragOver={col.nome === dragOver}>
              <CellComponent
                key={`${index}${col.campo}`}
                type={col.tipo}
                value={row[col.campo]}
                editavel={col.editavel}
                mascara={col.mascara}
                options={col.opcoes}
              />
            </Cell>
          ) : null)}
        </Row>
      );

    });

    return tableRows;
  }

  return (
    <Container>
      <Header>
        <Row>
          <HeaderCell dragOver={false}>
            <button>
              <IoSettings size={20} color="var(--primary-text-color)" />
            </button>
          </HeaderCell>
          {multipleSelect && (
            <HeaderCell dragOver={false}>
              <input type="checkbox" checked={areAllSelected()} onChange={() => toggleSelect()} />
            </HeaderCell>
          )}
          {cols.map(col => col.campo !== 'id' ? (
            <HeaderCell
              id={col.nome}
              key={col.nome}
              draggable
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleOnDrop}
              onDragEnter={handleDragEnter}
              dragOver={col.nome === dragOver}
              style={col.nome === 'ID' ? {
                width: '160px',
              }: {}}
            >
              {col.nome}
            </HeaderCell>
          ) : null )}
        </Row>
      </Header>
      <Body>
        {renderRows()}
      </Body>
      {withFooter && (
        <Footer>
          <tr>
            <td colSpan={cols.length + 2}>
              <FooterContent>
                <SelectedContainer>
                  <p>Total: <b>{count}</b></p>
                </SelectedContainer>
                <PaginateContainer>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={({ selected }) => onChangePage ? onChangePage(selected) : selected}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< "
                    renderOnZeroPageCount={undefined}
                    activeClassName='active-page-item'
                  />
                </PaginateContainer>
              </FooterContent>
            </td>
          </tr>
        </Footer>
      )}
    </Container>
  );
}

export default ListTable;
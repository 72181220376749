import provider from './config';

export const transferirDepositos = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/movdepos/transferencia-deposito', payload);
  
      return response.data;
    } catch (err: any) {
      throw Error("Ocorreu um erro ao tentar gerar a transferência entre depósitos");
    }
}

export const checarEstoque = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('/movdepos/checar-movimentacao', payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar verificar o estoque");
  }
}

import React from 'react';

import {
  Wrapper,
  Title,
  Content,
} from './styles';

import Table from '../../components/Table';

const AccountPlan: React.FC = () => {
  return (
    <Wrapper>
      <Title>Plano de Conta</Title>
      <Content>
        <Table 
          title="Plano de Conta"
          cols={[
            {
              id: "1",
              name: "",
              size: "small",
              align: "center",
              type: "checkbox",
              editable: true,
            }, {
              id: "2",
              name: "Código",
              size: "small",
              align: "center",
              type: "input",
              editable: true,
            }, {
              id: "3",
              name: "Descrição",
              size: "large",
              align: "center",
              type: "input",
              editable: true,
            }, {
              id: "4",
              name: "Redutora",
              size: "small",
              align: "center",
              type: "input",
              editable: true,
            }, {
              id: "5",
              name: "Apelido",
              size: "large",
              align: "center",
              type: "input",
              editable: true,
            }
          ]}
        />
      </Content>
    </Wrapper>
  )
};

export default AccountPlan;
import React, { useState, useEffect, ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "filled" | "outlined";
}

export interface IVariantStyleProps {
  background: string;
  color: string;
}

const Button: React.FC<IButtonProps> = ({variant = "filled", children, ...props}) => {
  const [variantStyle, setVariantStyle] = useState<IVariantStyleProps>({
    background: 'transparent',
    color: '#000',
  });

  useEffect(() => {
    let style: IVariantStyleProps;
    switch (variant) {
      case "filled":
        style = {
          background: "var(--primary)",
          color: "var(--primary-complementary-text-color)",
        };
        return setVariantStyle(style);
      case "outlined":
        style = {
          background: "transparent",
          color: "var(--secondary)",
        };
        return setVariantStyle(style);
      default:
        break;
    }
  }, [variant]);

  return (
    <Container {...variantStyle} {...props}>
      {children}
    </Container>
  );
}

export default Button;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AiFillFileAdd } from 'react-icons/ai'

import NoImage from '../../assets/images/no-image.png';
import {
  Page,
  Container,
  ImageHolder,
  Image,
  Label,
} from './styles';

interface IImageInputProps {
  id: string;
  name: string;
  disabled?: boolean;
  require?: boolean;
  initialState?: any;
  onChange?(file: File): void;
  inTable?: boolean;
  error?: boolean;
}

const ImageInput: React.FC<IImageInputProps> = ({
  id,
  name,
  disabled,
  initialState,
  onChange,
  require,
  inTable,
  error
}) => {
  const [profileImage, setProfileImage] = useState(NoImage);
  const [image, setImage] = useState<File | null>(null);
  const [loadedInitialState, setLoadedInitialState] = useState(false);
  const ImageRef = useRef(null);
  // const PreviewRef = useRef(null);

  const handleImageInput = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (target.files && target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setProfileImage(reader.result as string);
        });
        reader.readAsDataURL(target.files[0]);
        setImage(target.files[0]);
      }
    },
    []
  );

  useEffect(() => {
    if (image && onChange) {
      onChange(image);
    }
  }, [image]);

  useEffect(() => {
    if (initialState && !loadedInitialState) {
      setProfileImage(initialState);
      setLoadedInitialState(true);
    }
  }, [initialState, loadedInitialState]);

  return (
    <Page>
      <Container>
        <ImageHolder>
          <Image src={profileImage} alt="profile" id="img" ref={ImageRef} />
        </ImageHolder>
        <input
          type="file"
          name={name}
          id={id}
          onChange={handleImageInput}
          accept="image/*"
        />
        <Label error={error ? true : false} bold={require}>
          <label htmlFor={id}>
            <AiFillFileAdd />
            Escolher Foto {require ? <span> *</span> : null}
          </label>
        </Label>
      </Container>
    </Page>
  );
};

export default ImageInput;
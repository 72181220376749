import React from 'react';
import {Container, Title, Subtitle, RemoveButton} from './styles';

import {IoMdClose} from 'react-icons/io';

enum Colors {
  DANGER = 'red',
  WARNING = '#F4C228',
  OK = 'green',
};

interface INotificationCardProps {
  color: keyof typeof Colors;
  id: string;
  title: string;
  description: string;
  dismiss(id: string): void;
}

export interface INotificationCardContainerProps {
  color: string;
}

const NotificationCard: React.FC<INotificationCardProps> = ({color = "DANGER", id, title, description, dismiss, ...props}) => {
  return (
    <Container color={Colors[color]} {...props}>
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
      <RemoveButton onClick={() => dismiss(id)}>
        <IoMdClose />
      </RemoveButton>
    </Container>
  );
}

export default NotificationCard;
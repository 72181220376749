import provider from './config';

export const updateEntidade = async (id: any, values: any): Promise<any> => {
  try {
    const response = await provider.put(`/entidades/${id}`, values);

    return response.data;
  } catch (error) {
    throw Error('Erro ao tentar editar entidade.')
  }
}

export const getConductors = async (params:any) => {
  try {
    const response = await provider.get('/relatorios/motoristas', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório motoristas");
  }
}

export async function getCarteirasCobranca(nome: string, onlyId: boolean = false) {
  try {
      const result = await provider.get('entidades', {
          params: {
              classe: 'Carteiras de Cobrança',
              nome
          },
      });

      if(onlyId) {
          if (Array.isArray(result.data) && result.data.length > 0) {
              const filter = result.data.filter((uni) => uni.codigo === nome)[0];
  
              if (filter) {
                  return filter.id;
              } else {
                  return null;
              }
          } else {
              return null;
          }
      } else {
          return result.data;
      }
  } catch (error) {
      throw Error(`Carteira de cobrança "${nome}" não cadastrado na nossa base`);
  }
}
import React from 'react';
import {
  CardTableContainer,
  Title,
  DataTitle,
  DataString,
} from './styles';

export type CardTableProps = {
  title: string;
  data: any[];
  columns?: string[];
}

const CardTable: React.FC<CardTableProps> = ({
  title,
  data,
  columns
}) => {
  return (
    <CardTableContainer>
      <Title>{title}</Title>
      {data.map((item: any) => {
        if (item.type === 'title') {
          return (
            <DataTitle>{item.value}</DataTitle>
          )
        } else {
          return (
            <DataString>{item.value}</DataString>
          )
        }
      })}
    </CardTableContainer>
  );
}

export default CardTable;
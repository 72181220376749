import Provider from "./config";

export const getProviders = async (details: any) => {
  const payload: any = {
    ...details
  }
  
  if(payload.idAgrupamento){
    const agrupador = payload.idAgrupamento
    payload['agruparPor'] = agrupador
    delete payload.idAgrupamento;
  }
  if(payload.Ordem) {
    let order = '';

    payload.Ordem.map((or:any) => {
      order = `${order}${or},`;
      return null;
    });
    payload['ordenarPor'] = order;
    delete payload.Ordem;
  }
  
  try {
    const result = await Provider.get('relatorios/fornecedores/', {
      params:  payload
    })

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar os fornecedores.")
  }
}

export const getStockExtract = async (details: any, initSaldo: boolean = false) => {
  const payload: any = {
    ...details
  }
  
  if(payload.idAgrupamento){
    const agrupador = payload.idAgrupamento
    payload['agruparPor'] = agrupador
    delete payload.idAgrupamento;
  }
  if(payload.Ordem) {
    let order = '';

    payload.Ordem.map((or:any) => {
      order = `${order}${or},`;
      return null;
    });
    payload['ordenarPor'] = order;
    delete payload.Ordem;
  }
  
  try {
    let url = initSaldo ? 'relatorios/extrato-estoque-saldo-inicial/' : 'relatorios/extrato-estoque/';
    const result = await Provider.get(url, {
      params:  payload
    })

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar o extrato de estoque.")
  }
}

export const getStockBalance = async (details: any, initSaldo: boolean = false) => {
  const payload: any = {
    ...details
  }
  
  if(payload.idAgrupamento){
    const agrupador = payload.idAgrupamento
    payload['agruparPor'] = agrupador
    delete payload.idAgrupamento;
  }
  if(payload.Ordem) {
    let order = '';

    payload.Ordem.map((or:any) => {
      order = `${order}${or},`;
      return null;
    });
    payload['ordenarPor'] = order;
    delete payload.Ordem;
  }
  
  try {
    let url = 'relatorios/saldo-estoque';
    const result = await Provider.get(url, {
      params:  payload
    })

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar o saldo de estoque.")
  }
}

export const getClassesReport = async (details: any) => {
  const payload: any = {
    ...details
  }
  
  try {
    const url = 'relatorios/classes/';
    const result = await Provider.get(url, {
      params:  payload
    })

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar a listagem de classes.")
  }
}

export const getProvider = async (id: string) => {
  try {
    const result = await Provider.get(`entidades/${id}`)

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar o fornecedor.")
  }
}

export const getProviderByCNPJ = async (nome: string) => {
  try {
    const result = await Provider.get('entidades', {
      params: {
        nome
      },
    });
    return result.data;
  } catch (error) {
    throw Error ("Ocorreu um erro ao tentar buscar entidade.")
  }
}

export const createProvider = async (payload: any) => {
  try {
    const result = await Provider.post('entidades', payload);
    return result.data;
  } catch (error) {
    throw Error ("Ocorreu um erro ao tentar criar entidade.")
  }
}
import React from 'react';
import { Paragraph, Container, Spinner } from './styles';

interface IProps {
    message: string;
}

const TextProcess: React.FC<IProps> = ({message}) => {
    
    return (
        <Container>
            <Spinner />
            <Paragraph>{message}</Paragraph>
        </Container>
    )
}

export default TextProcess;
import Provider from './config';

export async function getUF(nome: string) {
    try {
        const result = await Provider.get('tabela', {
            params: {
                classe: 'Unidades da Federação',
                nome,
            }
        });

        if (Array.isArray(result.data) && result.data.length > 0) {
            const filter = result.data.filter((uf) => uf.codigo === nome)[0];

            if (filter) {
                return filter.id;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } catch (err) {
        throw Error(`UF "${nome}" não cadastrada na nossa base`);
    }
}

export async function getMunicipio(nome: string, uf?: string) {
    try {
        const params: any = {
            classe: 'Municípios',
            nome,
        };

        if (uf) {
            params.uf = uf;
        }

        const result = await Provider.get('tabela', {
            params,
        });

        if (Array.isArray(result.data) && result.data.length > 0) {
            return result.data[0].id;
        } else {
            return null;
        }
    } catch (err) {
    }
}

export async function searchUFCitiesByCode(codigoMunicipio: string, uf?: string) {
    try {
        const params: any = {
            classe: 'Municípios',
        };

        if (uf) {
            params.uf = uf;
        }

        const result = await Provider.get('tabela', {
            params,
        });

        if (Array.isArray(result.data) && result.data.length > 0) {

            const city = result.data.filter((municipio) => municipio.codigoIBGE === codigoMunicipio)[0];

            if (city) {
                return city.id;
            } else {
                return null;
            }

        } else {
            return null;
        }
    } catch (err: any) {
        console.log(err.message);
    }
}

export async function getUnidadeMedida(nome: string) {
    try {
        const result = await Provider.get('tabela', {
            params: {
                classe: 'Unidades de Medidas',
                nome
            },
        });
        if (Array.isArray(result.data) && result.data.length > 0) {
            const filter = result.data.filter((uni) => uni.codigo === nome)[0];

            if (filter) {
                return filter.id;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } catch (error) {
        throw Error(`Unidade de Medida "${nome}" não cadastrada na nossa base`);
    }
}

export async function getNCM(nome: string) {
    try {
        const result = await Provider.get('tabela', {
            params: {
                classe: 'NCM - Nomenclatura Comum do Mercosul',
                nome
            },
        });

        if (Array.isArray(result.data) && result.data.length > 0) {
            const filter = result.data.filter((NCM) => NCM.codigo === nome)[0];

            if (filter) {
                return filter.id;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } catch (error) {
        throw Error(`NCM - Nomenclatura Comum do Mercosul "${nome}" não cadastrada na nossa base`);
    }
}

export async function getProcedencia(nome: string) {
    try {
        const result = await Provider.get('tabela', {
            params: {
                nome,
                classe: 'Procedência'
            },
        });
        if (Array.isArray(result.data) && result.data.length > 0) {
            const filter = result.data.filter((uni) => uni.codigo === nome)[0];

            if (filter) {
                return filter.id;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } catch (error) {
        throw Error(`Procedência ${nome} não encontrada na base`);
    }
}

export async function getTipoDeFrete(nome: string, onlyId: boolean = false) {
    try {
        const result = await Provider.get('tabela', {
            params: {
                classe: 'Tipos de Frete',
                nome
            },
        });

        if(onlyId) {
            if (Array.isArray(result.data) && result.data.length > 0) {
                const filter = result.data.filter((uni) => uni.codigo === nome)[0];
    
                if (filter) {
                    return filter.id;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return result.data;
        }
    } catch (error) {
        throw Error(`Tipo de Frete "${nome}" não cadastrada na nossa base`);
    }
}

export async function getTipoDeDocumento(nome: string, onlyId: boolean = false) {
    try {
        const result = await Provider.get('tabela', {
            params: {
                classe: 'Tipos de Documentos',
                nome
            },
        });

        if(onlyId) {
            if (Array.isArray(result.data) && result.data.length > 0) {
                const filter = result.data.filter((uni) => uni.codigo === nome)[0];
    
                if (filter) {
                    return filter.id;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return result.data;
        }
    } catch (error) {
        throw Error(`Tipo de Documento "${nome}" não cadastrado na nossa base`);
    }
}

export const getVehicleSupply = async (params:any) => {
    try {
      const response = await Provider.get('/relatorios/abastecimentos', {
        params: params
      });
      return response.data;
    } catch (error) {
      throw Error("Erro ao buscar relatório abastecimentos");
    }
  }
import React from 'react';

import { Container, Row, Col } from 'react-grid-system';

import {
  Wrapper,
  Title,
  Content,
  ButtonContainer,
} from './styles';

import Button from '../../components/Button';
import TextInput from '../../components/TextInput';

const AccountingEntry: React.FC = () => {
  return (
    <Wrapper>
      <Title>Lançamento Contábil</Title>
      <Content>
        <Container fluid style={{width: "100%"}}>
          <Row>
            <Col md={8}>
              <h1>Lançamento Contábil</h1>
              <h2>Cabeçalho</h2>
              <Row>
                <Col md={8}>
                  <TextInput label="Classe do Lançamento Contábil" id="accounting_entry_class"/>
                </Col>
                <Col md={4}>
                  <TextInput label="Registro Contábil" id="accounting_register" />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <TextInput label="Classe do Plano de Contas" id="accounting_plan_class" />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <TextInput label="Loc. Escrituração" id="bokkeeping"/>
                </Col>
                <Col md={4}>
                  <TextInput label="Data de Emissão" id="emission_date" />
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <ButtonContainer>
                <Button>
                  Novo
                </Button>
                <Button>
                  Gravar
                </Button>
                <Button>
                  Fechar
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
          <h2>Lançamento</h2>
          <Row>
            <Col>
              <input type="checkbox" id="debit"/>
              <label htmlFor="debit">  Vários Débitos [F2]</label>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextInput label="Conta Débito" id="debit_count" />
            </Col>
            <Col md={3}>
              <TextInput label="Centro de Custo" id="cost_center" />
            </Col>
            <Col md={3}>
              <TextInput label="Valor" id="value" />
            </Col>
            <Col md={3} style={{display: "flex", alignItems: "center", color: "var(--primary)"}}>
              <p>1a Fórmula</p>
            </Col>
          </Row>
          <Row direction="row-reverse">
            <Col md={3} style={{display: "flex", alignItems: "center", color: "var(--primary)"}}>
              <p>2a Fórmula</p>
            </Col>
          </Row>
          <Row direction="row-reverse">
            <Col md={3} style={{display: "flex", alignItems: "center", color: "var(--primary)"}}>
              <p>3a Fórmula</p>
            </Col>
          </Row>
          <Row direction="row">
            <Col md={6}>
              <TextInput label="Histórico" id="historic" />
            </Col>
            <Col md={3}>
              <TextInput label="Saldo" id="balance" />
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col>
              <input type="checkbox" id="debit"/>
              <label htmlFor="credit">  Vários Créditos [F2]</label>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextInput label="Conta Crédito" id="credit_count" />
            </Col>
            <Col md={3}>
              <TextInput label="Centro de Custo" id="cost_center" />
            </Col>
            <Col md={3}>
              <TextInput label="Valor" id="value" />
            </Col>
            <Col md={3} style={{display: "flex", alignItems: "center", color: "var(--primary)"}}>
              <p>1a Fórmula</p>
            </Col>
          </Row>
          <Row direction="row-reverse">
            <Col md={3} style={{display: "flex", alignItems: "center", color: "var(--primary)"}}>
              <p>2a Fórmula</p>
            </Col>
          </Row>
          <Row direction="row-reverse">
            <Col md={3} style={{display: "flex", alignItems: "center", color: "var(--primary)"}}>
              <p>3a Fórmula</p>
            </Col>
          </Row>
          <Row direction="row">
            <Col md={6}>
              <TextInput label="Histórico" id="historic" />
            </Col>
            <Col md={3}>
              <TextInput label="Saldo" id="balance" />
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      </Content>
    </Wrapper>
  );
}

export default AccountingEntry;
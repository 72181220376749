import provider from './config';

export const getTitlesReport = async (params:any) => {
  try {
    const response = await provider.get('/relatorios/titulos', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório títulos");
  }
}
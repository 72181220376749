

export async function readXML(file: any) {
    try {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsText(file);
            reader.onloadend = evt => {
                if (evt && evt.target) resolve(evt.target.result);
                else reject();
            }
        })
    } catch (err: any) {
        console.log(err.message);
    }
}

export async function readXMLText(file: any) {
    try {
        const fileTxt: any = await readXML(file);
        const parser = new DOMParser();
        const xml = parser.parseFromString(fileTxt, 'text/xml');
        return xml;
    } catch (err: any) {
        console.log(err.message);
    }
}

export async function XMLFileToString(file: any) {
    try {
        const fileStr: any = await readXML(file);
        return fileStr;
    } catch (err: any) {
        console.log(err.message);
    }
}
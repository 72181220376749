/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';

import NoImage from '../../../assets/images/no-image.png';
interface ICellProps {
  type?: string;
  options?: Array<string>;
  editavel: boolean;
  mascara?: string;
  value: string | boolean;
  onChange?: (value: string) => void;
}

const CellComponent: React.FC<ICellProps> = ({
  type = "texto",
  value,
  options,
  editavel,
  mascara,
  onChange = () => { }
}) => {
  const [cellValue, setCellValue] = useState<any>(value);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (typeof cellValue === 'object') {
      onChange(cellValue);
    } else {
      onChange(cellValue as string);
    }
  }, [cellValue]);

  useEffect(() => {
    setCellValue(value);
  }, [value]);

  const renderTextCell = () => {
    if(focus && editavel) {
      return <input
        type="text"
        value={cellValue as string}
        onChange={(e) => setCellValue(e.target.value)}
        onBlur={(e) => setFocus(false)}
        readOnly={!editavel} /> 
    } else {
      if(cellValue) {
        if(mascara) {
          if(mascara.startsWith('data')) {
            return <p onClick={(e) => setFocus(true)}>
              {format(new Date(cellValue as string), 'dd/MM/yyyy HH:mm')}
            </p>
          } else if(mascara === 'dinheiro') {
            return <p onClick={(e) => setFocus(true)}>
              {new Intl.NumberFormat([], {style: 'currency',currency: 'BRL'}).format(cellValue)}
            </p>
          } else if(mascara === 'codigo') {
            const reg = /^\d+$/;
            const value = cellValue;
            if(value.length === 6 && reg.test(value)) {
              return <p onClick={(e) => setFocus(true)}>
                {value.slice(0, 3)+'.'+value.slice(3, 6)}
              </p>
            }
          }
        }
        
        return <p onClick={(e) => setFocus(true)}>
          {cellValue}
        </p>
        
      } else {
        return <p onClick={(e) => setFocus(true)}>---</p>
      }
    }
  }

  const renderCheckBoxCell = () => {
    if(focus && editavel) {
      return <input
        type="checkbox"
        checked={cellValue ? true : false}
        onChange={() => { cellValue ? setCellValue('') : setCellValue('1') }}
        onBlur={(e) => setFocus(false)}
        readOnly={!editavel} />
    } else {
      return <p onClick={(e) => setFocus(true)}>{(cellValue ? "Sim" : "Não")}</p>;
    }
  }

  const renderSelectCell = () => {
    if (focus && editavel && options) {
      return <select
        name="select"
        defaultValue={cellValue as string}
        onBlur={(e) => setFocus(false)}
        disabled={!editavel}>
          {options.map(option => {
            return (<option value={option}>{option}</option>);
          })}
      </select>
    } else {
      return <p onClick={(e) => setFocus(true)}>{cellValue ? cellValue : "---"}</p>
    }
  }

  const renderUploadCell = () => {
    if(focus && editavel) {
      return <input
        type="file"
        value=""
        onChange={() => { }}
        onBlur={(e) => setFocus(false)}
        readOnly={!editavel}
      />
    } else {
      if(cellValue) {
        return <img 
          src={NoImage} width="120" alt={cellValue} 
          onClick={(e) => setFocus(true)} 
        />
      }

      return <p onClick={(e) => setFocus(true)}>---</p>;
    }
  }

  const renderLookupCell = () => {
    if(typeof cellValue === 'object') {
      if(cellValue) {
        if(cellValue.hasOwnProperty("codigo") && cellValue.codigo) {
          return <p>{cellValue.codigo}</p>
        } else if(cellValue.nome){
          return <p>{cellValue.nome}</p>
        } else {
          return <p>{cellValue.id}</p>
        }
      } else {
        return <p>---</p>
      }
    } else {
      return <p>{cellValue}</p>
    }
  }

  const renderCell = () => {
    switch (type) {
      case "texto":
        return renderTextCell();
      case "checkbox":
        return renderCheckBoxCell();
      case "lookup":
        return renderLookupCell();
      case "select":
        return renderSelectCell();
      case "upload":
        return renderUploadCell();
      default:
        return renderTextCell();
    }
  }

  return <div>{renderCell()}</div>;
}

export default CellComponent;
import styled from 'styled-components';

import NotificationCardLayout from '../NotificationCard';

export const Container = styled.div`
  padding: 16px;
`;

export const Card = styled(NotificationCardLayout)`
  margin-bottom: 16px;
`;

export const Title = styled.p`
  font-size: 28px;
  font-weight: 500;
  color: var(--primary-text-color);
  margin-bottom: 16px;
`;
import { Column } from "../../components/Table/types";
import { maskPhone } from "../../utils/format";
import { convertToDate } from "../../utils/date";
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

export const detalhesData = (payload: any, lookups: any, observacaoToShow: string) => {
    let dtEmissao = convertToDate(payload.emissao);
    let emissao = dtEmissao ? format(dtEmissao, 'dd/MM/yy eeeeee HH:mm', {
      locale: ptBR,
    }) : payload.emissao;
    return [
        {
          type: 'title',
          value: payload.emissao ? 'Emissão' : '',
        },
        {
          value: emissao ? emissao : '',
        },
        {
          type: 'title',
          value: lookups.LocEscrituracao ? 'Local de escrituração' : ''
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.nome 
          ? lookups.LocEscrituracao.item.nome : '' : '',
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.telefone 
          ? maskPhone(lookups.LocEscrituracao.item.telefone) : '' : ''
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.endereco 
          ? lookups.LocEscrituracao.item.endereco : '' : ''
        },
        {
          type: 'title',
          value: lookups.Estabelecimento ? 'Estabelecimento' : ''
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.nome 
          ? lookups.Estabelecimento.item.nome : '' : '',
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.telefone 
          ? lookups.Estabelecimento.item.telefone : '' : ''
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.email 
          ? lookups.Estabelecimento.item.email : '' : ''
        },
        {
          type: 'title',
          value: lookups.Pessoa ? 'Pessoa' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.cpfCnpj 
          ? lookups.Pessoa.item.cpfCnpj : '' : '',
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.nomeFantasia 
          ? lookups.Pessoa.item.nomeFantasia : '' : '',
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.razaoSocial 
          ? lookups.Pessoa.item.razaoSocial : '' : '',
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item 
          ? `${lookups.Pessoa.item.endereco || ''}, ${lookups.Pessoa.item.numeroEndereco || ''} - ${lookups.Pessoa.item.bairro || ''}` : '' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item 
          ? `${lookups.Pessoa.item.Cidade ? lookups.Pessoa.item.Cidade.nome : ''} - ${lookups.Pessoa.item.UF ? lookups.Pessoa.item.UF.codigo : ''}` : '' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item 
          ? `${lookups.Pessoa.item.complemento || ''}` : '' : ''
        },
        {
          type: 'title',
          value: lookups.CondicaoPagamento ? 'Pagamento' : '',
        },
        {
          value: lookups.CondicaoPagamento 
          ? lookups.CondicaoPagamento.item && lookups.CondicaoPagamento.item.nome 
          ? lookups.CondicaoPagamento.item.nome : '' : ''
        },
        {
          type: 'title',
          value: payload.observacaoToShow ? 'Observação' : '',
        },
        {
          value: observacaoToShow || '',
        },
      ];
}

export const itemCols = (fromXml?: boolean): Column[] => {
    const custom: any[] = [];
    if (fromXml) {
      custom.push({
        campo: 'item',
        nome: 'IT',
        editavel: false,
        tipo: 'texto',
        fixo: true,
      });
    }
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        ...custom,
        {
          campo: 'Recurso',
          nome: 'Produto',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
        },
        {
          campo: 'Classe',
          nome: 'Classe',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'classes',
          classe: 'Entradas',
          classeBase: '61cd273aad47e0a02eab6802',
          fixo: true,
        },
        {
          campo: 'Nucleo',
          nome: 'Núcleo',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Núcleos',
          fixo: true,
        },
        {
          campo: 'Veiculo',
          nome: 'Veículo',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
          campoLabel: 'codigo',
          classe: 'Veículos',
          fixo: true,
          tabelaRecurso: true,
        },
        {
          campo: 'Lote',
          nome: 'Lote',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Lotes',
          fixo: true,
          tabelaRecurso: true,
        },
        {
          campo: 'CFOP',
          nome: 'CFOP',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'CFOP',
          fixo: true,
          campoLabel: 'codigo',
          tabelaRecurso: true,
        },
        {
          campo: 'quantidade',
          nome: 'Qtde',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'unitario',
          nome: 'Pr. Unitário',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'vrItem',
          nome: 'Vr. Item',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'acrescimo',
          nome: 'Acréscimo',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'acrescimoItem',
          nome: 'Acr. Item',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'desconto',
          nome: 'Desconto',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'descontoItem',
          nome: 'Desc. Item',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'frete',
          nome: 'Frete',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'seguro',
          nome: 'Seguro',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'ipi',
          nome: 'IPI',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'valorTotal',
          nome: 'Valor Total',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}
export const itemColsNaoEditavel = (fromXml?: boolean): Column[] => {
    const custom: any[] = [];
    if (fromXml) {
      custom.push({
        campo: 'item',
        nome: 'IT',
        editavel: false,
        tipo: 'texto',
        fixo: true,
      });
    }
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        ...custom,
        {
          campo: 'Recurso',
          nome: 'Produto',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
        },
        {
          campo: 'Classe',
          nome: 'Classe',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'classes',
          classe: 'Entradas',
          classeBase: '61cd273aad47e0a02eab6802',
          fixo: true,
        },
        {
          campo: 'Nucleo',
          nome: 'Núcleo',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Núcleos',
          fixo: true,
        },
        {
          campo: 'Veiculo',
          nome: 'Veículo',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
          campoLabel: 'codigo',
          classe: 'Veículos',
          fixo: true,
        },
        {
          campo: 'Lote',
          nome: 'Lote',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Lotes',
          fixo: true,
          tabelaRecurso: true,
        },
        {
          campo: 'CFOP',
          nome: 'CFOP',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'CFOP',
          fixo: true,
          campoLabel: 'codigo',
          tabelaRecurso: true,
        },
        {
          campo: 'quantidade',
          nome: 'Qtde',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'unitario',
          nome: 'Pr. Unitário',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'vrItem',
          nome: 'Vr. Item',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'acrescimo',
          nome: 'Acréscimo',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'acrescimoItem',
          nome: 'Acr. Item',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'desconto',
          nome: 'Desconto',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'descontoItem',
          nome: 'Desc. Item',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'frete',
          nome: 'Frete',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'seguro',
          nome: 'Seguro',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'ipi',
          nome: 'IPI',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'valorTotal',
          nome: 'Valor Total',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}

export const tituloCols = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'TipoDocumento',
          nome: 'Tipo',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Tipos de Documentos',
        },
        {
          campo: 'Carteira',
          nome: 'Carteira',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Carteiras de Cobrança',
        },
        {
          campo: 'prazo',
          nome: 'Prazo',
          editavel: true,
          tipo: 'texto',
          mascara: 'inteiro',
          fixo: true,
        },
        {
          campo: 'vencimento',
          nome: 'Vencimento',
          editavel: true,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'correcao',
          nome: 'Correção',
          editavel: true,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'valorPrincipal',
          nome: 'Vr. Principal',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'juros',
          nome: 'Juros',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'juroPercm',
          nome: 'Juros (%)',
          editavel: true,
          tipo: 'texto',
          mascara: 'decimal',
          fixo: true,
        },
        {
          campo: 'multa',
          nome: 'Multa',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'multaPerc',
          nome: 'Multa (%)',
          editavel: true,
          tipo: 'texto',
          mascara: 'decimal',
          fixo: true,
        },
        {
          campo: 'acrescimo',
          nome: 'Acréscimo',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'desconto',
          nome: 'Desconto',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'valorCorrigido',
          nome: 'Vr. Corrigido',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}

export const tituloColsNaoEditavel = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'TipoDocumento',
          nome: 'Tipo',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Tipos de Documentos',
        },
        {
          campo: 'Carteira',
          nome: 'Carteira',
          editavel: false,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Carteiras de Cobrança',
        },
        {
          campo: 'prazo',
          nome: 'Prazo',
          editavel: false,
          tipo: 'texto',
          mascara: 'inteiro',
          fixo: true,
        },
        {
          campo: 'vencimento',
          nome: 'Vencimento',
          editavel: false,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'correcao',
          nome: 'Correção',
          editavel: false,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'valorPrincipal',
          nome: 'Vr. Principal',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'juros',
          nome: 'Juros',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'juroPercm',
          nome: 'Juros (%)',
          editavel: false,
          tipo: 'texto',
          mascara: 'decimal',
          fixo: true,
        },
        {
          campo: 'multa',
          nome: 'Multa',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'multaPerc',
          nome: 'Multa (%)',
          editavel: false,
          tipo: 'texto',
          mascara: 'decimal',
          fixo: true,
        },
        {
          campo: 'acrescimo',
          nome: 'Acréscimo',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'desconto',
          nome: 'Desconto',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'valorCorrigido',
          nome: 'Vr. Corrigido',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {IVariantStyleProps} from '.';

export const Wrapper = styled.div`
  
  background-color: red;
`;

export const Container = styled(Link)<IVariantStyleProps>`
  display: block;
  background: ${props => props.background};
  color: ${props => props.color};
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .2s;
  cursor: pointer;
  width: 100%;
  padding: 10px 0 10px 0;
  text-align: center;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;

import styled from 'styled-components';

export const CardTableContainer = styled.div`
  background-color: var(--background);
  width: auto;
  min-height: 200px;
  max-height: 350px;
  overflow: auto;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.p`
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
`;

export const Table = styled.table`
  border-spacing: 0 5px 0;
  width: 100%;
  height: auto;

  display: block;
  overflow-x: auto;
  white-space: nowrap;
`;

export const TableHeader = styled.thead`
  color: var(--primary);
  font-size: 12px;
  padding: 2px;
  border: 1px solid #333;
  width: auto;

  th {
    letter-spacing: .5px;
    width: auto;
    min-width: 50px;
    text-align: left;
  }

`;

export const DataTitle = styled.p`
  font-weight: bold;
  margin-top: 8px;
  font-size: 14px;
`;

export const DataString = styled.p`
  font-size: 14px;
  white-space: wrap;
  word-break: break-all;
`;
import React, {createContext, useState, useContext} from 'react';
import FormModal, {IModalProps} from '../components/FormModal';

interface ModalContextData {
  openModal(content: any): void;
  closeModal(): void;
}

type ModalProps = IModalProps | null; //Pode adicionar o | para multiplos tipos de modal tipo "IConfirm | ITeste"

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const FormModalProvider: React.FC = ({children}) => {
  const [props, setProps] = useState<ModalProps>(null);
  const [open, setOpen] = useState(false);

  function openModal(content: ModalProps) {
    setOpen(true);
    setProps(content);
  }

  function closeModal() {
    if (props?.onCancel) {
      props.onCancel();
    }
    
    setOpen(false);
    setProps(null);
  }

  return (
    <ModalContext.Provider
      value={{openModal, closeModal}}>
      {children}
      {open && props && (<FormModal {...props} />)}
    </ModalContext.Provider>
  );
};

function useFormModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within an ModalProvider.');
  }

  return context;
}

export {FormModalProvider, useFormModal};
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const Lista = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;
import React, {useState} from 'react';
import {Container, Title, Card} from './styles';

enum Colors {
  DANGER = 'red',
  WARNING = '#F4C228',
  OK = 'green',
};

interface INotification {
  id: string;
  title: string;
  description: string;
  type?: string;
}

const NotificationList: React.FC = () => {
  const [notifications, setNotifications] = useState<Array<INotification>>([
    {
      id: '5mkl21m23',
      title: 'Título do card',
      description: 'Aqui fica a descrição da notificação ou alguma coisa desse tipo.',
      type: 'URGENT',
    },
    {
      id: 'm5tlkrm3',
      title: 'Título do card',
      description: 'Aqui fica a descrição da notificação ou alguma coisa desse tipo.',
    },
    {
      id: 'tm5lk1edk',
      title: 'Título do card',
      description: 'Aqui fica a descrição da notificação ou alguma coisa desse tipo.',
      type: 'WARNING',
    },
    {
      id: 'm5tkl4m3ke',
      title: 'Título do card',
      description: 'Aqui fica a descrição da notificação ou alguma coisa desse tipo.',
    },
  ]);

  const handleColor = (type: string | undefined): keyof typeof Colors => {
    switch (type) {
      case "URGENT":
        return "DANGER";
      case "WARNING":
        return "WARNING";
      default:
        return 'OK';
    }
  }

  const removeNotification = (id: string) => {
    const newNotifications = notifications.filter(notification => notification.id !== id);
    return setNotifications(newNotifications);
  }

  return (
    <Container>
      <Title>Notificações</Title>
      {notifications.map(notification => (
        <Card
          key={notification.id}
          id={notification.id}
          title={notification.title}
          description={notification.description}
          dismiss={removeNotification}
          color={handleColor(notification.type)}
        />
      ))}
    </Container>
  );
}

export default NotificationList;
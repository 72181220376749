/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Title, ToolBar } from './styles';
import PaymentModalForm from '../../PaymentModalForm';
import { useAuth } from '../../../contexts/auth';
import { useModal } from '../../../contexts/modal';

export type TableToolBarProps = {
  title?: string;
  actions?: any[];
  onInsert?: any;
  updateFormaPagamento: any;
}

const TableToolBar = ({
  title,
  actions,
  onInsert,
  updateFormaPagamento,
}: TableToolBarProps) => {
  const [openPagamentoModal, setOpenPagamentoModal] = useState(false);
  const [insertPagamento, setInsertPagamento] = useState(false);
  const [pagamentoToAdd, setPagamentoToAdd] = useState<any>(null);
  const { user } = useAuth();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (openPagamentoModal) {
      openModal({
        type: 'submit',
        title: 'Inserir forma de pagamento',
        submitLabel: 'Inserir',
        children: () => (
          <PaymentModalForm token={user?.token} handleChange={(payload: any) => setPagamentoToAdd(payload)} />
        ),
        onSubmit: () => setInsertPagamento(true),
        onCancel: () => setOpenPagamentoModal(false),
        closeOnAction: false,
      });
    }
  }, [openPagamentoModal]);

  useEffect(() => {
    if (insertPagamento && pagamentoToAdd) {
      const items = [];

      if (pagamentoToAdd.parcelas >= 1) {
        for (let i = 0; i < pagamentoToAdd.parcelas; i++) {
          const {parcelas, ...data} = pagamentoToAdd;
          const item = {...data};
          items.push(item);
        }
      }
      updateFormaPagamento(items);
      closeModal();
      setInsertPagamento(false);
    } else if (insertPagamento && !pagamentoToAdd){
      closeModal();
      setInsertPagamento(false);
    }
  }, [insertPagamento]);


  if (!title) return null;

  const onClickInsert = () => {
    if (title === 'Formas de Pagamento') {
      setOpenPagamentoModal(true);
    } else {
      onInsert();
    }
  }

  return (
    <ToolBar>
      <Title>{title}</Title>
      <div className="react-icons-button">
        {actions && actions.includes('insert') && (
          <IoAddCircleOutline type="button" size={20} onClick={onClickInsert} color="var(--primary-text-color)" />
        )}
      </div>
    </ToolBar>
  )
}

export default TableToolBar;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {
  Container,
  HeaderContent,
  Title,
  ActionsContainer,
  Button,
  Content,
  ContentRow,
} from './styles';

import { IToggleMenuOption } from '../../components/ToggleMenuButton';
import ListTable, { IField } from '../../components/ListTable';
import { TabItems, TabContent } from '../../components/TabSelector';

import useFetch from '../../hooks/useFetch';
import { useModal } from '../../contexts/modal';
import { useAuth } from '../../contexts/auth';

import PermissionsForm from './permissions/PermissionsForm';
import GroupForm from './groups/GroupForm';

export interface IAction {
  name: string;
  onClick(): void;
}

const Users: React.FC = () => {
  const {openModal, closeModal} = useModal();
  const {user} = useAuth();
  const fieldsHandler = useFetch({endpoint: '/userGroups/campos'});
  const userGroupsHandler = useFetch({endpoint: '/userGroups/'});

  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [totalUsersPages, setTotalUsersPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersList, setUsersList] = useState([]);

  const [currentGroupsPage, setCurrentGroupsPage] = useState(1);
  const [totalGroupsPages, setTotalGroupsPages] = useState(1);
  const [totalGroups, setTotalGroups] = useState(0);
  const [groupsList, setGroupsList] = useState([]);

  const [cols, setCols] = useState<Array<IField>>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [permissionsOpened, setPermissionsOpened] = useState(false);
  const [permissionsActions, setPermissionsActions] = useState<IAction[]>([]);
  const [permissionsPayload, setPermissionsPayload] = useState<any>({});
  const [savePermissions, setSavePermissions] = useState(false);

  const [groupPayload, setGroupPayload] = useState<any>(null);
  const [removedUsersFromGroup, setRemovedUsersFromGroup] = useState<any>(null);
  const [insertGroup, setInsertGroup] = useState(false);
  const [updateGroup, setUpdateGroup] = useState(false);

  const groupActions: IAction[] = [
    {
      name: 'Inserir',
      onClick: () => openModal({
        type: 'submit',
        title: 'Inserir grupo', 
        submitLabel: 'Salvar',
        children: () => (
          <GroupForm
            token={user?.token}
            onChange={setGroupPayload}
          />
        ),
        onSubmit: () => setInsertGroup(true),
        closeOnAction: false,
      }),
    },
  ];

  useEffect(() => {
    getData(true, true);
  }, []);

  useEffect(() => {
    if (insertGroup) {
      handleInsertGroup();
    }
  }, [insertGroup]);

  useEffect(() => {
    if (updateGroup) {
      handleUpdateGroup();
    }
  }, [updateGroup]);

  useEffect(() => {
    if (savePermissions) {
      handleSavePermissions();
    }
  }, [savePermissions]);

  useEffect(() => {
    getData(selectedTab === 0, false);
  }, [currentUsersPage, currentGroupsPage]);

  const getData = async (getUsers = false, firstRender = false) => {
    if (getUsers) {
      const users = await userGroupsHandler.get({
        page: currentUsersPage,
        classe: 'Usuários'
      });
      
  
      setUsersList(users.docs);
      setTotalUsersPages(users.totalPages);
      setTotalUsers(users.total);
    } else {
      const groups = await userGroupsHandler.get({
        page: currentGroupsPage,
        classe: 'Grupos'
      });
  
      setGroupsList(groups.docs);
      setTotalGroupsPages(groups.totalPages);
      setTotalGroups(groups.total);
    }

    if (firstRender) {
      const cols = await fieldsHandler.get();
      setCols(cols);
    }
  };

  useEffect(() => {
    if (permissionsOpened) {
      setPermissionsActions([
        {
          name: 'Salvar permissões',
          onClick: () => setSavePermissions(true),
        },
        {
          name: 'Fechar',
          onClick: () => setPermissionsOpened(false),
        },
      ]);
    } else {
      setPermissionsActions([]);
    }
  }, [permissionsOpened]);

  const handleSavePermissions = async () => {
    const payload = Object.keys(permissionsPayload).map((key: string) => {
      return {
        classe: key,
        funcoes: permissionsPayload[key],
      };
    });

    await userGroupsHandler.put(selectedItem.id, {
      permissoes: payload,
    });
    closeModal();
    setSelectedItem(null);
    getData(false, false);

    setSavePermissions(false);
  }

  const handleChangeSelectedTab = (tab: number) => {
    if (tab === 1) {
      getData(false, false);
    } else if (tab === 0) {
      getData(true, false);
    }

    setPermissionsOpened(false);
    return setSelectedTab(tab);
  }

  const handleOpenPermissions = (item: any) => {
    setSelectedItem(item);
    return setPermissionsOpened(true)
  }

  const handleInsertGroup = async () => {
    await userGroupsHandler.post(groupPayload);
    setInsertGroup(false);
    closeModal();
    getData(false, false);
  }

  const handleUpdateGroup = async () => {
    let payload = {...groupPayload, classe: null};

    if (removedUsersFromGroup && removedUsersFromGroup.length > 0) {
      payload = {
        ...payload,
        usuariosExcluidos: removedUsersFromGroup,
      }
    }

    await userGroupsHandler.put(selectedItem.id, payload);
    setUpdateGroup(false);
    closeModal();
    setSelectedItem(null);
    getData(false, false);
  }

  const openEditGroupModal = (item: any) => {
    setSelectedItem(item);
    openModal({
      type: 'submit',
      title: `Editar ${item.nome}`, 
      submitLabel: 'Salvar',
      children: () => (
        <GroupForm
          token={user?.token}
          onChange={setGroupPayload}
          onChangeRemoved={setRemovedUsersFromGroup}
          initialState={item}
        />
      ),
      onSubmit: () => setUpdateGroup(true),
      closeOnAction: false,
    });
  }

  const userRowActions: IToggleMenuOption[] = [
    {
      id: 1,
      name: 'Permissões',
      onClick: handleOpenPermissions,
    },
  ];

  const groupRowActions: IToggleMenuOption[] = [
    {
      id: 1,
      name: 'Editar',
      onClick: openEditGroupModal,
    },
    {
      id: 2,
      name: 'Permissões',
      onClick: handleOpenPermissions,
    },
  ];

  const handleChangePage = (type: string, page: number) => {
    if (type === 'users') {
      setCurrentUsersPage(page);
    } else if (type === 'groups') {
      setCurrentGroupsPage(page);
    }
  }

  return (
    <Container>
      <HeaderContent>
        <Title>Usuários e Grupos</Title>
        <ActionsContainer>
          {selectedTab === 1 && groupActions?.map(action => (
            <Button key={action.name} onClick={action.onClick}>
              {action.name}
            </Button>
          ))}
        </ActionsContainer>
      </HeaderContent>
      <ContentRow>
        <Content>
          <TabItems onChangeTab={handleChangeSelectedTab}>
            <TabContent title="Usuários">
              <ListTable 
                fields={cols} 
                data={usersList} 
                onChangePage={(page) => handleChangePage('users', page + 1)} 
                currentPage={currentUsersPage} 
                totalPages={totalUsersPages}
                rowMenuOptions={userRowActions}
                count={totalUsers}
              />
            </TabContent>
            <TabContent title="Grupos">
              <ListTable 
                fields={cols} 
                data={groupsList} 
                onChangePage={(page) => handleChangePage('groups', page + 1)} 
                currentPage={currentGroupsPage} 
                totalPages={totalGroupsPages}
                rowMenuOptions={groupRowActions}
                count={totalGroups}
              />
            </TabContent>
          </TabItems>
        </Content>
        {permissionsOpened && selectedItem && (
          <PermissionsForm
            actions={permissionsActions}
            selectedItem={selectedItem}
            onChange={setPermissionsPayload}
          />
        )}
      </ContentRow>
    </Container>
  );
}

export default Users;
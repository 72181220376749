import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
  height: 100%;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  padding: 16px;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--opacity-background);
  margin: 8px;
  width: 230px;
  height: 210px;
  padding: 10px;

  &:hover{
    box-shadow: 0 0 0.2em #848587;
    border: 1px solid transparent
  }

  h3{
    color: #848587;
    font-weight: 300;
  }

  .content{
    display: flex;
    align-items: center;
    color: var(--primary);
    margin-left: auto;
    margin-top: 40px;
  }

  .content>p{
    font-size: 3.2rem;
    margin-left: 6px;
    color: var(--primary);
  }

  .content>h3{
    font-size: 3.5rem;
  }

  .footer{
    display: flex;
    align-items: center;
  }

  .footer>p{
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
    margin-left: 6px;
  }
`

export const CardSkill = styled.div`
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--opacity-background);
  margin: 8px;
  width: 500px;
  height: 210px;
  padding: 10px;

  &:hover{
    box-shadow: 0 0 0.2em #848587;
    border: 1px solid transparent;
  }

  h3{
    color: #848587;
    font-weight: 300;
  }

  .container-left{
    width: 50%;
    margin-right: auto;
  }

  .container{
    width: 50%;
    margin-left: auto;
  }

  .container-left>h3{
    color: #9ea4ac;
    font-size: 3rem;
    text-align: right;
    margin-right: 20px;
  }

  .paragraph{
    display: flex;
    justify-content: space-between;
  }

  .item{
    width: 100%;
    background: #ddd;
  }

  .skills{
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
  }

  .first{
    width: 90%;
    background: red;
  }

  .second{
    width: 80%;
    background: orange;
  }

  .third{
    width: 65%;
    background: green;
  }
  
`

export const DivMain = styled.div`
  display: flex;
  align-items: center;
`

export const Link = styled.a`
  color: #848587;
  font-weight: 500;
  margin-left: 20px;
  text-decoration: none;
  
  &:hover{
    text-decoration: underline;
  }
`

export const LinkPrimary = styled.a`
  color: var(--primary);
  font-weight: 500;
  text-decoration: none;
  margin-top: 4px;
  
  &:hover{
    text-decoration: underline;
  }
`
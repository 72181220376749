import React from 'react';
import {useFormik, FormikHelpers} from 'formik';

import {Container, Form, Input, Button, Link, FormContainer, Logo, Title, Subtitle} from './styles';

import LogoImage from '../../assets/images/logo.png';
import {useAuth, ISignInPayload} from '../../contexts/auth';

interface ILoginValidation {
  email?: string;
  password?: string;
}

const Login: React.FC = () => {
  const {signIn} = useAuth();

  const initialValues: ISignInPayload = {
    email: '',
    password: '',
  };

  const handleSubmit = (values: ISignInPayload, helpers: FormikHelpers<ISignInPayload>) => {
    signIn(values);
    helpers.setSubmitting(false);
  }

  const validate = (values: ISignInPayload) => {
    const errors: ILoginValidation = {};
 
    if (!values.email) {
      errors.email = '* Obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Este endereço de e-mail é inválido';
    }

    if (!values.password) {
      errors.password = '* Obrigatório'
    } else if (values.password.length < 8) {
      errors.password = 'A senha precisa ter no mínimo 8 dígitos'
    }
 
    return errors;
  };

  const loginForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        <Title>Seja bem-vindo(a) ao Devari ERP</Title>
        <Subtitle>Por favor, insira suas credenciais de acesso</Subtitle>
        <Form onSubmit={loginForm.handleSubmit}>
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            value={loginForm.values.email}
            error={loginForm.errors.email}
            onChange={loginForm.handleChange}
          />
          <Input
            id="password"
            name="password"
            placeholder="Digite sua senha..."
            label="Senha"
            type="password"
            value={loginForm.values.password}
            error={loginForm.errors.password}
            onChange={loginForm.handleChange}
          />
          <Button disabled={loginForm.isSubmitting} variant="filled" type="submit">Entrar</Button>
          <Link variant="outlined" to={{}}>Esqueceu a senha?</Link>
        </Form>
      </FormContainer>
    </Container>
  );
}

export default Login;
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  .buttonPrint{
    color: var(--primary-complementary-text-color);
    background-color: var(--primary);
    border-radius: 30px;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 4px;
    margin-left: auto;
    padding: 10px;
  }
`

export const Container = styled.div`
  background: #fff;
  height: auto;
  padding: 10px 16px;
  width: 100%;
  font-size: 12px;
`

export const DivHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;

  img{
    height: auto;
    width: 100px;
  }
`

export const ContentHeader = styled.div`
  /* border: 1px solid gray; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  width: 70%;
`

export const BodyOrder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DivRow = styled.div`
  display: flex;
  width: 100%;
`

export const DivLeft = styled.div`
  border: 1px solid gray !important;
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  padding-left: 10px;
  width: 50%;

  h3{
    margin-bottom: 6px;
    text-decoration: underline;
  }

  div > p:nth-child(1){
    margin-top: 50px;
    margin-bottom: 15px;
  }
  
  div > p:nth-child(2){
    margin-bottom: 15px;
    
  }
`

export const DivRight = styled.div`
  border: 1px solid gray !important;
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-left: 4px;
  padding-left: 10px;
  width: 50%;

  h3{
    margin-bottom: 6px;
    text-decoration: underline;
  }

  div{
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  
`

export const DivItems = styled.div`
  /* border: 1px solid gray; */
  margin-top: 6px;
  padding: 4px;
  width: 100%;

  h3{
    margin-bottom: 6px;
    text-decoration: underline;
  }

  table{
    width: 100%;
  }

  table, th, td {
    border: 1px solid gray !important;
  }

  th{
    background-color: gray;
    color: #fff;
  }

  th, td{
    padding: 4px;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }
`

export const LogoFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  img{
    margin-top: 6px;
    width: 100px;
  }
`
import React, {InputHTMLAttributes} from 'react';
import {Wrapper, Container, Label, Select, Error} from './styles';

interface ISelectOption {
  id: number | string;
  name: string;
}

interface IInputProps extends InputHTMLAttributes<HTMLSelectElement>{
  id: string;
  label?: string;
  className?: string;
  error?: string;
  options?: Array<ISelectOption>;
}

const Input: React.FC<IInputProps> = ({id, className, label, error, options, value, onChange, ...props}) => {
  return (
    <Wrapper className={className}>
      <Container>
        {label && (<Label htmlFor={id}>{label}</Label>)}
        <Select id={id} value={value} onChange={onChange}>
          {options?.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </Select>
      </Container>
      {error && (<Error>{error}</Error>)}
    </Wrapper>
  );
}

export default Input;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../utils/format';

import { ContainerResume, Row, Title } from './styles';

interface IProps {
  title: string;
  filters: any;
  className?: string;
}

const ResumeFiltersReport: React.FC<IProps> = ({
  title,
  filters,
  className,
}) => {

  const [formatedFilters, setFormatedFilters] = useState<any[]>([]);

  useEffect(() => {
    handleFormat();
  }, [])

  const handleFormat = () => {
    if(Array.isArray(filters)) {
      let labelLen = 0;
      filters.map((it:any) => {
        if(it.label.length > labelLen) {
          labelLen = it.label.length;
        }
        return null;
      });
      const _newList = filters.map((it:any) => {
        const currentLabelLen = it.label.length;
        if(currentLabelLen < labelLen) {
          return {
            ...it,
            label: it.label.padEnd(labelLen, '.')
          }
        } else {
          return it;
        }
      });
      setFormatedFilters(_newList);
    }
  }

  return (
    <ContainerResume className={className}>
      <Title>{title}</Title>
      {formatedFilters.map((filter: any, index: any) => {
        let value = '';

        if (Array.isArray(filter.value)) {
          if (filter.value.length === 0) value = 'Nenhum';
          value = filter.value.map((it: any) => `${it} `);
        } else {
          if (filter.value instanceof Date) {
            value = formatDate(filter.value);
          } else {
            value = filter.value;
          }
        }
        return (
          <Row>
            <label>
              {filter.label}: <span className='span'>{value}</span>
            </label>
          </Row>
        )
      })}
    </ContainerResume>
  )
}

export default ResumeFiltersReport;
import provider from './config';

export interface IClasse {
    id: string;
    nome: string;
    idPai: string | null;
    inativo: boolean;
    excluivel: boolean;
    editavel: boolean;
    permiteSaldoNegativo: boolean;
    criadoEm: string;
    atualizadoEm: string;
    children: Array<IClasse>;
}

export const getClasses = async (filters: any = null): Promise<Array<IClasse>> => {
  try {
    const response = await provider.get('classes/');

    const classesResponse: IClasse[] = response.data;

    return classesResponse;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar listar as classes")
  }
}

export const getClasseAndChilds = async (params: any) => {
  try {
    const response = await provider.get('classes/', {
      params
    });

    return response.data;
  } catch (error: any) {
    throw Error("Ocorreu um erro ao tentar listar as classes")
  }
}
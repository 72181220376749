import {ListRoutes} from './routes';

interface ICRUDRoute {
	key: keyof typeof ListRoutes;
	labels: any;
	endpoint: string;
	filters: any;
	customFields?: any;
    editCustomFields?: any;
    startWithSearch?: boolean;
}

export const pages: ICRUDRoute[] = [
    {
        key:"entities",
        labels:{
            list: 'Entidades',
            insert: 'Inserir entidade',
            edit: 'Editar entidade',
        },
        endpoint:"entidades",
        filters:{},
        customFields:{
            userGroup: true,
        },
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"resources",
        labels: {
            list: 'Recursos',
            insert: 'Inserir recurso',
            edit: 'Editar recurso',
        },
        endpoint:"recursos",
        filters:{},
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"products",
        labels:{
            list: 'Produtos',
            insert: 'Inserir produto',
            edit: 'Editar produto',
        },
        endpoint:"recursos",
        filters:{
            classe: "Produtos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
            prices: {
                type: 'table',
                label: 'Preços',
                loadState: {
                    endpoint: 'tabela',
                    filter: {
                        classe: 'Regras de Precificação',
                        field: 'recurso',
                    }
                },
                cols: [
                    {
                      id: 'codigo',
                      name: 'Código',
                      type: 'text',
                      editable: false,
                    },
                    {
                      id: 'nome',
                      name: 'Nome',
                      type: 'text',
                      editable: false,
                    },
                    {
                      id: 'vigenciaInicial',
                      name: 'Vigência inicial',
                      type: 'date',
                      editable: false,
                    },
                    {
                      id: 'vigenciaFinal',
                      name: 'Vigência final',
                      type: 'date',
                      editable: false,
                    },
                    {
                      id: 'preco',
                      name: 'Preço',
                      type: 'money',
                      editable: false,
                    },
                ]
            }
        },
    },
    {
        key:"clients",
        labels:{
            list: 'Clientes',
            insert: 'Inserir cliente',
            edit: 'Editar cliente',
        },
        endpoint:"entidades",
        filters:{
            classe: "Clientes"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"deposits",
        labels:{
            list: 'Depósitos',
            insert: 'Inserir depósito',
            edit: 'Editar depósito',
        },
        endpoint:"entidades",
        filters:{
            classe: "Depósitos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"banks",
        labels:{
            list: 'Bancos',
            insert: 'Inserir banco',
            edit: 'Editar banco',
        },
        endpoint:"entidades",
        filters:{
            classe: "Bancos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"employees",
        labels:{
            list: 'Funcionários',
            insert: 'Inserir funcionário',
            edit: 'Editar funcionário',
        },
        endpoint:"entidades",
        filters:{
            classe: "Funcionários"
        },
        customFields:{
            insert: true,
            actions: [
                {
                    id: "fichaFuncionario",
                    name: "Ficha",
                    type: "specific",
                }
            ]
        },
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"cost_center",
        labels:{
            list: 'Centro de Custo',
            insert: 'Inserir centro de custo',
            edit: 'Editar centro de custo',
        },
        endpoint:"entidades",
        filters:{
            classe: "Centros de Custos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"bookkeeping",
        labels:{
            list: 'Locais de escrituração',
            insert: 'Inserir local de escrituração',
            edit: 'Editar local de escrituração',
        },
        endpoint:"entidades",
        filters:{
            classe: "Local de Escrituração"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"stablishment",
        labels:{
            list: 'Estabelecimentos',
            insert: 'Inserir estabelecimento',
            edit: 'Editar estabelecimento',
        },
        endpoint:"entidades",
        filters:{
            classe: "Estabelecimentos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"providers",
        labels:{
            list: 'Fornecedores',
            insert: 'Inserir fornecedor',
            edit: 'Editar fornecedor',
        },
        endpoint:"entidades",
        filters:{
            classe: "Fornecedores"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"seller",
        labels:{
            list: 'Vendedores',
            insert: 'Inserir vendedor',
            edit: 'Editar vendedor',
        },
        endpoint:"entidades",
        filters:{
            classe: "Vendedores"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"services",
        labels:{
            list: 'Serviços',
            insert: 'Inserir serviço',
            edit: 'Editar serviço',
        },
        endpoint:"recursos",
        filters:{
            classe: "Serviços"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"billing_portfolio",
        labels:{
            list: 'Carteiras de Cobrança',
            insert: 'Inserir carteira de cobrança',
            edit: 'Editar carteira de cobrança',
        },
        endpoint:"entidades",
        filters:{
            classe: "Carteiras de Cobrança"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"uf",
        labels:{
            list: 'Unidades da federação',
            insert: 'Inserir unidade da federação',
            edit: 'Editar unidade de federação',
        },
        endpoint:"tabela",
        filters:{
            classe: "Unidades da Federação"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"city",
        labels:{
            list: 'Municípios',
            insert: 'Inserir município',
            edit: 'Editar município',
        },
        endpoint:"tabela",
        filters:{
            classe: "Municípios"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"price_rule",
        labels:{
            list: 'Regras de precificação',
            insert: 'Inserir regra de precificação',
            edit: 'Editar regra de precificação',
        },
        endpoint:"tabela",
        filters:{
            classe: "Regras de Precificação"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"core_rule",
        labels:{
            list: 'Regras de núcleos',
            insert: 'Inserir regra de núcleo',
            edit: 'Editar regra de núcleo',
        },
        endpoint:"tabela",
        filters:{
            classe: "Regras de Núcleos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"class_rule",
        labels:{
            list: 'Regras de classe de pedidos',
            insert: 'Inserir regra de classe de pedidos',
            edit: 'Editar regra de classe de pedidos',
        },
        endpoint:"tabela",
        filters:{
            classe: "Regras de Classe"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"comission_rule",
        labels:{
            list: 'Regras de comissão',
            insert: 'Inserir regras de comissão',
            edit: 'Editar regras de comissão',
        },
        endpoint:"tabela",
        filters:{
            classe: "Regras de Comissão"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"tax_rule",
        labels:{
            list: 'Regras fiscais',
            insert: 'Inserir regra fiscal',
            edit: 'Editar regra fiscal',
        },
        endpoint:"fator",
        filters:{
            classe: "Regras Fiscais"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"document_types",
        labels:{
            list: 'Tipos de documentos',
            insert: 'Inserir tipo de documento',
            edit: 'Editar tipo de documento',
        },
        endpoint:"tabela",
        filters:{
            classe: "Tipos de Documentos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"shipping_types",
        labels:{
            list: 'Tipos de frete',
            insert: 'Inserir tipo de frete',
            edit: 'Editar tipo de frete',
        },
        endpoint:"tabela",
        filters:{
            classe: "Tipos de Frete"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"origin",
        labels:{
            list: 'Procedência',
            insert: 'Inserir procedência',
            edit: 'Editar procedência',
        },
        endpoint:"tabela",
        filters:{
            classe: "Procedência"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"cfop_rule",
        labels:{
            list: 'Regras CFOP',
            insert: 'Inserir regra CFOP',
            edit: 'Editar regra CFOP',
        },
        endpoint:"tabela",
        filters:{
            classe: "Regras CFOP"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"unit_measures",
        labels:{
            list: 'Unidades de Medidas',
            insert: 'Inserir unidade de medida',
            edit: 'Editar unidade de medida',
        },
        endpoint:"tabela",
        filters:{
            classe: "Unidades de Medidas"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"buyers",
        labels:{
            list: 'Compradores',
            insert: 'Inserir Compradores',
            edit: 'Editar Compradores',
        },
        endpoint:"entidades",
        filters:{
            classe: "Compradores"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"cfop",
        labels:{
            list: 'CFOP',
            insert: 'Inserir CFOP',
            edit: 'Editar CFOP',
        },
        endpoint:"tabela",
        filters:{
            classe: "Cadastros de CFOP"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"colors",
        labels:{
            list: 'Cores',
            insert: 'Inserir cor',
            edit: 'Editar cor',
        },
        endpoint:"tabela",
        filters:{
            classe: "Cores"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"sizes",
        labels:{
            list: 'Tamanhos',
            insert: 'Inserir tamanho',
            edit: 'Editar tamanho',
        },
        endpoint:"tabela",
        filters:{
            classe: "Tamanhos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"payment_conditions",
        labels:{
            list: 'Condições de pagamento',
            insert: 'Inserir condição de pagamento',
            edit: 'Editar condição de pagamento',
        },
        endpoint:"tabela",
        filters:{
            classe: "Condições de Pagamento"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"selling_order",
        labels:{
            list: 'Pedido de saída',
            insert: 'Inserir pedido de saída',
            edit: 'Editar pedido de saída',
        },
        endpoint:"pedidos",
        filters:{
            classe: "Rendas",
            search: true,
        },
        startWithSearch: true,
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"pull_order",
        labels:{
            list: 'Baixa de pedido',
            insert: 'Inserir baixa',
            edit: 'Editar baixa',
        },
        endpoint:"pedidos",
        filters:{
            classe: "Baixa",
            search: true,
        },
        startWithSearch: true,
        customFields: {
            actions: [
                {
                    id: "import",
                    name: "Importar XML",
                },
            ]
        },
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"buying_order",
        labels:{
            list: 'Pedido de entrada',
            insert: 'Inserir pedido de entrada',
            edit: 'Editar pedido de entrada',
        },
        endpoint:"pedidos",
        filters:{
            classe: "Compras",
            search: true,
        },
        startWithSearch: true,
        customFields: {
            actions: [
                {
                    id: "import",
                    name: "Importar XML",
                },
                {
                    id: "openBaixa",
                    name: "Abrir baixa",
                },
            ]
        },
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"purchase_service_order",
        labels:{
            list: 'Pedido de compra de serviço',
            insert: 'Inserir pedido de compra de serviço',
            edit: 'Editar pedido de compra de serviço',
        },
        endpoint:"pedidos",
        filters:{
            classe: "C Serviço",
            search: true,
        },
        startWithSearch: true,
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"selling_service_order",
        labels:{
            list: 'Pedido de venda de serviço',
            insert: 'Inserir pedido de venda de serviço',
            edit: 'Editar pedido de venda de serviço',
        },
        endpoint:"pedidos",
        filters:{
            classe: "V Serviço",
            search: true,
        },
        startWithSearch: true,
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"calc_base",
        labels:{
            list: 'Bases de cálculo',
            insert: 'Inserir base de cálculo',
            edit: 'Editar base de cálculo',
        },
        endpoint:"tabela",
        filters:{
            classe: "Bases de Cálculo dos Impostos",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"card_flag",
        labels:{
            list: 'Bandeiras de Cartão',
            insert: 'Inserir bandeira de cartão',
            edit: 'Editar bandeira de cartão',
        },
        endpoint:"tabela",
        filters:{
            classe: "Bandeiras de Cartão",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"tax_codes",
        labels:{
            list: 'Códigos de Tributação',
            insert: 'Inserir código de tributação',
            edit: 'Editar código de tributação',
        },
        endpoint:"tabela",
        filters:{
            classe: "Códigos de Tributação",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"expenses",
        labels:{
            list: 'Despesas',
            insert: 'Inserir despesa',
            edit: 'Editar despesa',
        },
        endpoint:"recursos",
        filters:{
            classe: "Despesas",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"recipes",
        labels:{
            list: 'Receitas',
            insert: 'Inserir receita',
            edit: 'Editar receita',
        },
        endpoint:"recursos",
        filters:{
            classe: "Receitas",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"availables",
        labels:{
            list: 'Disponíveis',
            insert: 'Inserir disponível',
            edit: 'Editar disponível',
        },
        endpoint:"entidades",
        filters:{
            classe: "Disponíveis",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"carriers",
        labels:{
            list: 'Transportadoras',
            insert: 'Inserir transportadora',
            edit: 'Editar transportadora',
        },
        endpoint:"entidades",
        filters:{
            classe: "Transportadoras",
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"ncm",
        labels:{
            list: 'NCM - Nomenclatura Comum do Mercosul',
            insert: 'Inserir NCM - Nomenclatura Comum do Mercosul',
            edit: 'Editar NCM - Nomenclatura Comum do Mercosul',
        },
        endpoint:"tabela",
        filters:{
            classe: "NCM - Nomenclatura Comum do Mercosul"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"batches",
        labels:{
            list: 'Lotes',
            insert: 'Inserir lote',
            edit: 'Editar lote',
        },
        endpoint:"tabela",
        filters:{
            classe: "Lotes"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"brand_vehicle",
        labels:{
            list: 'Marcas (Veículo)',
            insert: 'Inserir marca',
            edit: 'Editar marca',
        },
        endpoint:"tabela",
        filters:{
            classe: "Marca Veículo"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"model_vehicle",
        labels:{
            list: 'Modelos (Veículo)',
            insert: 'Inserir modelo',
            edit: 'Editar modelo',
        },
        endpoint:"tabela",
        filters:{
            classe: "Modelo Veículo"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"supply_vehicle",
        labels:{
            list: 'Abastecimentos (Veículo)',
            insert: 'Inserir abastecimento',
            edit: 'Editar abastecimento',
        },
        endpoint:"tabela",
        filters:{
            classe: "Abastecimento Veículo"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"conductor",
        labels:{
            list: 'Motoristas',
            insert: 'Inserir motorista',
            edit: 'Editar motorista',
        },
        endpoint:"entidades",
        filters:{
            classe: "Motoristas"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"vehicle",
        labels:{
            list: 'Veículos',
            insert: 'Inserir veículo',
            edit: 'Editar veículo',
        },
        endpoint:"recursos",
        filters:{
            classe: "Veículos"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"fuel_types",
        labels:{
            list: 'Tipos de Combustível',
            insert: 'Inserir tipo de combustível',
            edit: 'Editar tipo de combustível',
        },
        endpoint:"tabela",
        filters:{
            classe: "Tipo de Combustível"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
    {
        key:"holidays",
        labels:{
            list: 'Feriados',
            insert: 'Inserir feriado',
            edit: 'Editar feriado',
        },
        endpoint:"tabela",
        filters:{
            classe: "Feriados"
        },
        customFields:{},
        editCustomFields: {
            edit: true,
        },
    },
]
import React from 'react';
import { Cell } from './styles';
import LookupInput from '../../TableLookups';
import Select from '../../SelectInput';
import Input from '../../TextInput';
import ImageInput from '../../ImageInput';
import DateInput from '../../DateInput';

interface TableFormCellProps {
  field: any;
  form: any;
}

const TableFormCell: React.FC<TableFormCellProps> = ({field, form}) => {
  const renderField = () => {
    switch (field.type) {
      case 'select':
        return (
          <Select 
            key={field.id}
            label={field.label} 
            id={field.id} 
            options={field.options}
            inTable={true}
            value={form.values[field.id]}
            onChange={form.handleChange}
            disabled={field.disabled}
          />
        );
      case 'checkbox':
        return (
          <Input
              key={field.id}
              masked={field.mask !== null}
              mask={field.mask}
              id={field.id}
              name={field.id}
              placeholder={field.placeholder}
              label={field.label}
              inTable={true}
              checked={form.values[field.id]}
              type="checkbox"
              error={form.errors[field.id]}
              onChange={form.handleChange}
              disabled={field.disabled}
          />
        );
      case 'upload':
        return (
          <ImageInput
            key={field.id}
            id={field.id}
            name={field.id}
            disabled={field.disabled}
            inTable={true}
            initialState={field.initialState}
            onChange={field.onChange}
          />
        );
      case 'date':
        return (
          <DateInput
            key={field.id}
            id={field.id}
            name={field.id}
            label={field.label}
            value={form.values[field.id]}
            onChange={form.handleChange}
            withHour={field.withHour ? true : false}
            inTable={true}
            disabled={field.disabled}
          />
        );
      case 'lookup':
        const filters = field.filters;
        if (typeof filters === 'object' && field.classeBase) filters.id = field.classeBase;
        return (
          <LookupInput
              key={field.id}
              endpoint={field.endpoint || ''}
              field={field.field || ''}
              labelField={field.labelField || ''}
              filters={filters}
              multi={field.multi}
              disabled={field.disabled}
              menuPlacement="top"
              isRecursoTable={field.isRecursoTable}
              initialState={form.values[field.id] ? form.values[field.id] : null}
              onChange={(options: any) => form.handleChange({
                target: {
                    id: field.id,
                    name: field.id,
                    value: options,
                },
            })}
          />
        );
      case 'money':
        return (
            <Input
              key={field.id}
              money
              id={field.id}
              name={field.id}
              label={field.label}
              inTable={true}
              value={form.values[field.id]}
              error={form.errors[field.id]}
              onChange={form.handleChange}
              disabled={field.disabled}
              fixed={field.fixed}
          />
        );
      default:
        return (
          <Input
              key={field.id}
              masked={field.mask !== null}
              money={field.money}
              mask={field.mask}
              id={field.id}
              name={field.id}
              placeholder={field.placeholder}
              label={field.label}
              inTable={true}
              value={form.values[field.id]}
              error={form.errors[field.id]}
              onChange={form.handleChange}
              disabled={field.disabled}
              fixed={field.fixed}
          />
      );
    }
  }

  return (
    <Cell date={field.type === 'date'} disabled={field.disabled}>
      {field && renderField()}
    </Cell>
  );
}

export default TableFormCell;

import React from 'react';
import {ProgressContainer, BarContainer} from './style';

interface Props {
    width: number;
    height?: number;
    childWidth: number;
}

const ProgressBar: React.FC<Props> = ({
    width, childWidth,
    height=22,
}) => {

    return (
        <BarContainer width={width} height={height}>
            <ProgressContainer width={childWidth} height={height}>
                {childWidth.toFixed(2)}%
            </ProgressContainer>
        </BarContainer>
    );
}

export default ProgressBar;
import styled from 'styled-components';

interface IProfileSaveContainer {
  showing?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; 
  }
  
  &::-webkit-scrollbar-thumb {
    background: #FF0000;
  }

  @media(max-width: 1024px) {
    height: auto;
    overflow: hidden;
  }
`;

export const PaddingContainer = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 2fr;
  grid-gap: 8px;

  @media(max-width: 1024px) {
    grid-template-columns: 100%;
    margin-bottom: 64px;

    & > div {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 300;
  color: var(--primary-text-color);
`;

export const ProfilePhotoContainer = styled.div`
  width: 350px;
  height: 300px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--opacity-background);
  border-radius: 2px;
`; 

export const ProfilePhotoButton = styled.button`
  background-color: transparent;
  position: relative;
  transition: .2s;
  cursor: pointer;

  &:hover{
    & > div {
      opacity: 1;
    }
  }
`;

export const ProfilePhoto = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: contain;
`;

export const ProfilePhotoButtonOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 250px;
  height: 250px;
  border-radius: 125px;
  background-color: var(--white-opacity-background);
  justify-content: center;
  align-items: center;
  transition: .2s;

  font-weight: 600;
  font-size: 12px;
  color: var(--primary-text-color);

  input{
    display: none;
  }
`;

export const ProfileInfoContainer = styled.div`
  padding: 16px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--opacity-background);
  border-radius: 2px;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p {
      font-size: 18px;
      font-weight: 300;
    }

    & > button {
      font-weight: 300;
      background-color: transparent;
      cursor: pointer;
    }
  }

  & > hr {
    border-bottom: 1px solid var(--opacity-background);
    margin: 16px 0;
  }

  & > fieldset {
    margin-bottom: 8px;

    & > span {
      font-size: 14px;
      font-weight: 300;
      color: var(--muted);
    }

    & > p {
      color: var(--primary-text-color);
    }
  }
`;

export const ProfileSaveContainer = styled.div<IProfileSaveContainer>`
  background-color: var(--white);
  position: absolute;
  bottom: ${props => props.showing ? 0 : '-100vh'};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--opacity-background);
  transition: .2s;

  & > button {
    width: 100px;

    &:last-child{
      background-color: transparent;
      color: var(--primary-text-color);
      margin-left: 8px;
    }
  }
`;

export const Form = styled.form``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 32px;

  & button {
    background-color: transparent;
    transition: .2s;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }
`;

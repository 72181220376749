import provider from './config';

export const uploadFile = async (payload: FormData): Promise<string> => {
  try {
    const response = await provider.post(`/aws/s3-devari-erp/arquivos/devariupload`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.result[0].Location;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar fazer o upload do arquivo");
  }
}
import styled from "styled-components";

interface BarProps {
    width: number;
    height: number;
}

interface ProgressProps {
    width: number;
    height: number;
}

export const BarContainer = styled.div<BarProps>`
    margin-bottom: 12px;
    width: ${props => props.width}%;
    height: ${props => props.height}px;
    background-color: var(--primary);
`;

export const ProgressContainer = styled.div<ProgressProps>`
    width: ${props => props.width}%;
    height: ${props => props.height}px;
    background-color: var(--secondary);
    color: white;
    text-align: center;
`;
import React, {InputHTMLAttributes} from 'react';
import {Wrapper, Container, Label, TextInput, MaskInput, Error} from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement>{
  id: string;
  label: string;
  className?: string;
  error?: string;
  masked?: boolean;
  mask?: string | (string | RegExp)[];
}

const Input: React.FC<IInputProps> = ({id, className, label, error, masked, mask = '', ...props}) => {
  return (
    <Wrapper className={className}>
      <Container>
        <Label htmlFor={id}>{label}</Label>
        {masked ? (
          <MaskInput id={id} mask={mask} {...props} />
        ) : (
          <TextInput id={id} {...props} />
        )}
      </Container>
      {error && (<Error>{error}</Error>)}
    </Wrapper>
  );
}

export default Input;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
// import format from 'date-fns/format';
import { IAction } from '..';
import useFetch from '../../../hooks/useFetch';
import {
  PermissionsTable,
  HeaderContent,
  Title,
  ActionsContainer,
  Button,
  Content,
} from '../styles';

interface Props {
  actions?: IAction[];
  selectedItem?: any;
  onChange?(payload: any): void;
}

const PermissionsForm: React.FC<Props> = ({actions, selectedItem, onChange}) => {
    const classesHandler = useFetch({endpoint: '/classes/'});
    const [classesList, setClassesList] = useState([]);
    const [currentSelected, setCurrentSelected] = useState<any>({});

    useEffect(() => {
      getData();
    }, [selectedItem]);

    useEffect(() => {
      if (selectedItem && selectedItem.Permissoes) {
        const byClasse: any = {}

        for (const permissao of selectedItem.Permissoes) {
          if (byClasse.hasOwnProperty(permissao.classe.id)) {
            byClasse[permissao.classe.id].push(permissao.funcao);
          } else {
            byClasse[permissao.classe.id] = [
              permissao.funcao,
            ];
          }
        }
        setCurrentSelected(byClasse);
      }
    }, [selectedItem]);

    useEffect(() => {
      if (onChange) {
        onChange(currentSelected);
      }
    }, [currentSelected]);

    const getData = async () => {
        const classes = await classesHandler.get();
        setClassesList(classes);
    };
    
    const renderChildRow = (classes: any, level = 1) => {
        const result: any[] = [];
        for (const classe of classes) {
          const key = Object.keys(currentSelected).filter((key: string) => key === classe.id)[0];
          const selected = key ? currentSelected[key] : null;
          const Row = (
            <tr key={classe.id}>
              <td style={{paddingLeft: `${(level * 1) + 1}em`}}>{classe.nome}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Ver")}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Inserir")}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Atualizar")}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Excluir")}</td>
              <td>---</td>
            </tr>
          );
    
          result.push(Row);
    
          if (classe.children) {
            const childClasses = renderChildRow(classe.children, level + 1);
            result.push([...childClasses]);
          }
        }
        
        return result;
    }

    const renderClassesRow = () => {
      const result: any[] = [];
      const classes: any = classesList;

      for (const classe of classes) {
          const key = Object.keys(currentSelected).filter((key: string) => key === classe.id)[0];
          const selected = key ? currentSelected[key] : null;
          const Row = (
          <tr key={classe.id}>
              <td>{classe.nome}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Ver")}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Inserir")}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Atualizar")}</td>
              <td>{renderCheckbox(key ? key : classe.id, selected, "Excluir")}</td>
              <td>---</td>
          </tr>
          );

          result.push(Row);

          if (classe.children) {
          const childClasses = renderChildRow(classe.children);
          result.push([...childClasses]);
          }
      }
      return result;
    }

    const renderCheckbox = (key: string, selected: any, func: string) => {
      return (
        <input
          type="checkbox"
          checked={selected ? selected.includes(func) : false}
          onChange={() => handleToggleFunc(key, selected, func)}
        />
      )
    }

    const handleToggleFunc = (key: string | null, selected: any | null, func: string) => {
      if (selected && key) {
        let copySelected = selected;
        if (copySelected.includes(func)) {
          copySelected = copySelected.filter((funcName: string) => funcName !== func);
        } else {
          copySelected = [...copySelected, func];
        }

        const newCurrentSelected = {
          ...currentSelected,
          [key]: copySelected,
        };

        setCurrentSelected(newCurrentSelected);
      } else if (!selected && key) {
        const newCurrentSelected = {
          ...currentSelected,
          [key]: [func],
        };
        
        setCurrentSelected(newCurrentSelected);
      }
    } 
  
    return (
        <>
        <HeaderContent>
            <Title>Permissões {selectedItem.nome}</Title>
            <ActionsContainer>
            {actions?.map(action => (
                <Button key={action.name} onClick={action.onClick}>
                {action.name}
                </Button>
            ))}
            </ActionsContainer>
        </HeaderContent>
        <Content>
            <PermissionsTable>
                <thead>
                <tr>
                    <th>Classe</th>
                    <th>Ver</th>
                    <th>Inserir</th>
                    <th>Atualizar</th>
                    <th>Excluir</th>
                    <th>Data limite</th>
                </tr>
                </thead>
                <tbody>
                {renderClassesRow()}
                </tbody>
                <tfoot>
                </tfoot>
            </PermissionsTable>
        </Content>
      </>
    );
}

export default PermissionsForm;
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDate, formatMoney } from "../../utils/format";
import { Autenticate, Column, Container, Main, Photo, Row, Title } from './style';
import ReactToPrint from 'react-to-print'

interface IDataFile {
  nome?: string;
  foto?: string;
  RG?: string;
  cpfCnpj?: string;
  emissaoRG?: string;
  dataNascimento?: string;
  LocalNascimento?: ILocalNascimento;
  nacionalidade?: string;
  email?: string;
  telefone?: string;
  celular?: string;
  genero?: string;
  cor?: string;
  deficiencia?: string;
  dependenteSalarioFamilia?: string;
  endereco?: string;
  numeroEndereco?: string;
  complemento?: string;
  bairro?: string;
  Cidade?: ICidade;
  UF?: IEstado;
  codigo?: string;
  orgaoEmissorRG?: string;
  banco?: string;
  agencia?: string;
  conta?: string;
  codigoFebraban?: string;
  numeroCTPS?: string;
  serieCTPS?: string;
  expedicaoCTPS?: string;
  UfCTPS?: IUfCTPS;
  tituloEleitor?: string;
  zonaTitulo?: string;
  secaoTitulo?: string;
  certificadoReservista?: string;
  pisPASEP?: string;
  pisCadastro?: string;
  pisDomicilioBanco?: string;
  pisNumeroBanco?: string;
  pisAgenciaBanco?: string;
  pisEnderecoAgencia?: string;
  fgtsBanco?: string;
  fgtsDataRatificacao?: string;
  numeroCnh?: string;
  tipoCnh?: string;
  registroProfissional?: string;
  dataAdmissao?: string;
  DataDemissao?: string;
  dataOpcaoFGTS?: string;
  pai?: string;
  mae?: string;
  estadoCivil?: string;
  horasMes?: string;
  horasSemana?: string;
  salario?: string;
  LocEscrit?: IEmpresa;
}

interface IEmpresa {
  id?: string;
  nome?: string;
  endereco?: string;
  numeroEndereco?: string;
  complemento?: string;
  bairro?: string;
  Cidade?: ICidade;
  UF?: string;
  cpfCnpj?: string;
}

interface ICidade {
  nome?: string;
}

interface IEstado {
  nome?: string;
}

interface IUfCTPS {
  nome?: string;
}

interface ILocalNascimento{
  nome?: string;
}

const FichFuncionario: React.FC = () => {

  const location = useLocation<any>()
  const componentRef = useRef(null);
  const [dataFile, setDataFile] = useState<IDataFile>()

  useEffect(() => {
    if (location.state) {
      const details: any = location.state.item

      setDataFile(details)
    }
  }, [location.state])

  const pageStyle = `
    @media print{
      html, body{
        border-collapse: collapse;
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      table, th, td {
        border-collapse: collapse;
      }
      .page-break {
        margin-top: 20px !important;
        display: block;
        page-break-before: auto;
      }
    }
    @page{
      margin: 20mm;
      size: auto;
    }
  `

  return (
    <Main>

      <ReactToPrint
        trigger={() => {

          return <button type='button' className='buttonPrint'>IMPRIMIR</button>
        }}
        content={() => componentRef.current}
        documentTitle="new document"
        pageStyle={pageStyle}
      />
      <Container ref={componentRef}>
        <Row wide={"100%"}>
          <Title>Registro de Funcionário</Title>
        </Row>

        <Row wide={"100%"}>
          <Autenticate wide={"20%"}>
            <label>Autenticar</label>
          </Autenticate>
          <Column wide={"80%"}>
            <Row wide={"100%"}>
              <Column wide={"80%"} className="info-column">
                <span>Matrícula</span>
                <label>{dataFile?.codigo}</label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>Nº</span>
                <label>{dataFile?.codigo}</label>
              </Column>
            </Row>
            <Row>
              <Column wide={"70%"} className="info-column">
                <span>Empregador</span>
                <label>{dataFile?.LocEscrit?.nome}</label>
              </Column>
              <Column wide={"30%"} className="info-column">
                <span>CNPJ</span>
                <label>{dataFile?.LocEscrit?.cpfCnpj}</label>
              </Column>
            </Row>
            <Row>
              <Column wide={"100%"} className="info-column">
                <span>Endereço</span>
                <label>{dataFile?.LocEscrit?.endereco},  
                {dataFile?.LocEscrit?.numeroEndereco} 
                {dataFile?.LocEscrit?.complemento && `, ${dataFile.LocEscrit.complemento}`}, 
                {dataFile?.LocEscrit?.bairro}, {dataFile?.LocEscrit?.Cidade?.nome} </label>
              </Column>
            </Row>
          </Column>
        </Row>

        <Row wide={"100%"}>
          <Column wide={"50%"}>
            <Column wide={"100%"} className="info-column">
              <span>Empregado</span>
              <label>{dataFile?.nome}</label>
            </Column>
            <Column wide={"100%"} className="info-column">
              <span>Residência</span>
              <label>
                {dataFile?.endereco}, 
                {dataFile?.numeroEndereco} 
                {dataFile?.complemento && `, ${dataFile.complemento}`}
                {dataFile?.Cidade?.nome && `, ${dataFile.Cidade.nome}`}
                {dataFile?.UF?.nome && `, ${dataFile.UF.nome}`}
              </label>
            </Column>
          </Column>
          <Column wide={"50%"} className="info-column">
            <span>Beneficiários</span>
            <label>{dataFile?.dependenteSalarioFamilia}</label>
          </Column>
        </Row>

        <Row wide={"100%"}>
          <Photo wide={"15%"} className="info-column" src={dataFile?.foto} />
          <Column wide={"85%"} className="info-column">
            <Row wide={"100%"}>
              <Column wide={"20%"} className="info-column">
                <span>Data de Nascimento</span>
                <label>{dataFile?.dataNascimento ? formatDate(dataFile.dataNascimento) : ''}</label>
              </Column>
              <Column wide={"40%"} className="info-column">
                <span>Local de Nascimento</span>
                <label>{dataFile?.LocalNascimento?.nome}</label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>País de Nacionalidade</span>
                <label>{dataFile?.nacionalidade}</label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>Estado Civil</span>
                <label>{dataFile?.estadoCivil}</label>
              </Column>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"50%"} className="info-column">
                <span>Pai</span>
                <label>{dataFile?.pai}</label>
              </Column>
              <Column wide={"50%"} className="info-column">
                <span>Mãe</span>
                <label>{dataFile?.mae}</label>
              </Column>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"18%"} className="info-column">
                <span>Cédula de Identidade</span>
                <label>{dataFile?.RG}</label>
              </Column>
              <Column wide={"14%"} className="info-column">
                <span>Data de Emissão</span>
                <label>{dataFile?.emissaoRG ? formatDate(dataFile.emissaoRG) : ''}</label>
              </Column>
              <Column wide={"18%"} className="info-column">
                <span>Órgão/UF Emissor</span>
                <label>{dataFile?.orgaoEmissorRG}</label>
              </Column>
              <Column wide={"14%"} className="info-column">
                <span>Título Eleitoral</span>
                <label>{dataFile?.tituloEleitor}</label>
              </Column>
              <Column wide={"8%"} className="info-column">
                <span>Zona</span>
                <label>{dataFile?.zonaTitulo}</label>
              </Column>
              <Column wide={"8%"} className="info-column">
                <span>Seção</span>
                <label>{dataFile?.secaoTitulo}</label>
              </Column>
              <Column wide={"18%"} className="info-column">
                <span>Info. Órgão de Classe</span>
                <label></label>
              </Column>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"14%"} className="info-column">
                <span>CTPS</span>
                <label>{dataFile?.numeroCTPS}</label>
              </Column>
              <Column wide={"12%"} className="info-column">
                <span>Série</span>
                <label>{dataFile?.serieCTPS}</label>
              </Column>
              <Column wide={"16%"} className="info-column">
                <span>Data de Expedição</span>
                <label>{dataFile?.expedicaoCTPS}</label>
              </Column>
              <Column wide={"12%"} className="info-column">
                <span>UF CTPS</span>
                <label>{dataFile?.UfCTPS?.nome}</label>
              </Column>
              <Column wide={"16%"} className="info-column">
                <span>CPF</span>
                <label>{dataFile?.cpfCnpj}</label>
              </Column>
              <Column wide={"16%"} className="info-column">
                <span>CNH</span>
                <label>{dataFile?.numeroCnh}</label>
              </Column>
              <Column wide={"12%"} className="info-column">
                <span>Categoria</span>
                <label>{dataFile?.tipoCnh}</label>
              </Column>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"20%"} className="info-column">
                <span>Doc. Militar</span>
                <label>{dataFile?.certificadoReservista}</label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>Categoria</span>
                <label></label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>Cor</span>
                <label>{dataFile?.cor}</label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>Sexo</span>
                <label>{dataFile?.genero}</label>
              </Column>
              <Column wide={"20%"} className="info-column">
                <span>Grau de Instrução</span>
                <label>{dataFile?.RG}</label>
              </Column>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"40%"} className="info-column">
                <span>Deficiência</span>
                <label>{dataFile?.deficiencia}</label>
              </Column>
              <Column wide={"30%"} className="info-column">
                <span>Telefone Residencial</span>
                <label>{dataFile?.telefone}</label>
              </Column>
              <Column wide={"30%"} className="info-column">
                <span>Telefone Celular</span>
                <label>{dataFile?.celular}</label>
              </Column>
            </Row>
          </Column>
        </Row>

        <Row wide={"100%"}>
          <Row wide="15%">
            <Column wide={"100%"} className="info-column">
              <span>Data de Admissão</span>
              <label>{dataFile?.dataAdmissao ? formatDate(dataFile.dataAdmissao) : ''}</label>
            </Column>
          </Row>
          <Row wide={"85%"}>
            <Row wide={"100%"} style={{padding: 0}}>
              <Column wide={"30%"} className="info-column">
                <span>Salário</span>
                <label>{dataFile?.salario ? formatMoney(dataFile.salario) : ''}</label>
              </Column>
              <Column wide={"10%"} className="info-column">
                <span>Por</span>
                <label>Mês</label>
              </Column>
              <Column wide={"30%"} className="info-column">
                <span>Horário de Trabalho</span>
                <label></label>
              </Column>
              <Column wide={"30%"} className="info-column">
                <span>Horário de Intervalo</span>
                <label></label>
              </Column>
            </Row>
          </Row>
        </Row>

        <Row wide={"100%"} className="info-column">
          <Column wide={"10%"} className="info-column">
            <label>FGTS</label>
          </Column>
          <Column wide={"20%"} className={"info-column"}>
            <span>Opção em</span>
            <label>{dataFile?.dataOpcaoFGTS ? formatDate(dataFile.dataOpcaoFGTS) : ''}</label>
          </Column>
          <Column wide={"50%"} className="info-column">
            <span>Conta Vinculada no Banco</span>
            <label>{dataFile?.fgtsBanco}</label>
          </Column>
          <Column wide={"20%"} className="info-column">
            <span>Data de Ratificação</span>
            <label>{dataFile?.fgtsDataRatificacao ? formatDate(dataFile.fgtsDataRatificacao) : ''}</label>
          </Column>
        </Row>

        <Column wide={"100%"} className="info-column">
          <Row wide={"100%"} className="text-center">
            <label>PROGRAMA DE INTEGRAÇÃO SOCIAL - PIS</label>
          </Row>
          <Row wide={"100%"}>
            <Column wide={"20%"} className="info-column">
              <span>Cadastrado em</span>
              <label>{dataFile?.pisCadastro ? formatDate(dataFile.pisCadastro) : ''}</label>
            </Column>
            <Column wide={"20%"} className="info-column">
              <span>Sob nº</span>
              <label>{dataFile?.pisPASEP}</label>
            </Column>
            <Column wide={"60%"} className="info-column">
              <span>Domicílio Bancário</span>
              <label>{dataFile?.pisDomicilioBanco}</label>
            </Column>
          </Row>
          <Row wide={"100%"}>
            <Column wide={"15%"} className="info-column">
              <span>Nº Banco</span>
              <label>{dataFile?.pisNumeroBanco}</label>
            </Column>
            <Column wide={"15%"} className="info-column">
              <span>Agência Código</span>
              <label>{dataFile?.pisAgenciaBanco}</label>
            </Column>
            <Column wide={"70%"} className="info-column">
              <span>Endereço da Agência</span>
              <label>{dataFile?.pisEnderecoAgencia}</label>
            </Column>
          </Row>
        </Column>

        <Column wide={"100%"} className="info-column">
          <Row wide={"100%"}>
            <label>ALTERAÇÕES DE SALÁRIO, CARGO E/OU FUNÇÃO</label>
          </Row>
          <Row wide={"100%"}>
            <Column wide="50%" toll={"70px"} className="info-column"></Column>
            <Column wide="50%" toll={"70px"} className="info-column"></Column>
          </Row>
        </Column>
        <Row wide={"100%"}>
          <Row wide={"55%"}>
            <Column wide={"30%"} className="info-column">
              <label>FÉRIAS - PERÍODO AQUISITIVO</label>
              <hr />
              <Column toll={"50px"}></Column>
            </Column>
            <Column wide={"30%"} className="info-column">
              <label>FÉRIAS - PERÍODO DE GOZO</label>
              <hr />
              <Column toll={"50px"}></Column>
            </Column>
            <Column wide={"40%"} className="info-column">
              <label>FÉRIAS - PERÍODO ABONO PECUNIÁRIO</label>
              <hr />
              <Column toll={"50px"}></Column>
            </Column>
          </Row>
          <Row wide={"45%"}>
            <Column wide={"100%"} className="info-column">
              <label>Obd.: (Anotar advertências, suspensões, transferências, etc.)</label>
              <hr />

              <Column wide={"90%"} style={{margin: "auto", marginRight: "auto", marginTop: "15px", marginBottom: "2px"}}>
                <hr style={{marginBottom: "15px"}}/>
                <hr style={{marginBottom: "15px"}}/>
                <hr/>
              </Column>
            </Column>
          </Row>
        </Row>
        <Row wide={"100%"}>
          <Column wide={"55%"} className="info-column">
            <Row wide={"100%"}>
              <label>ACIDENTES DE TRABALHO, DOENÇAS OU DOENÇAS PROFISSIONAIS</label>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"50%"} toll={"80px"} className="info-column"></Column>
              <Column wide={"50%"} toll={"80px"} className="info-column"></Column>
            </Row>
          </Column>
          <Column wide={"45%"} className="info-column">
            <Row wide={"100%"}>
              <label>RESCISÃO DE CONTRATO DE TRABALHO</label>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"50%"} className="info-column">
                <div>
                  <label>Data de saída: {dataFile?.DataDemissao ? formatDate(dataFile.DataDemissao) : ''}</label>
                </div>
                <div>
                  <label>Data aviso ind.: </label>
                </div>
              </Column>
              <Column wide={"50%"} className="info-column">
                <div>
                  <label>Data projeção: </label>
                </div>
              </Column>
            </Row>
            <Column wide={"100%"} className="info-column flex">
              <label>Tipo de desligamento:</label>
            </Column>
          </Column>
        </Row>

        <Row wide={"100%"}>
          <Column wide={"55%"} className="info-column">
            <Row wide={"100%"}>
              <label>CONTRIBUIÇÃO SINDICAL</label>
            </Row>
            <Row wide={"100%"}>
              <Column wide={"50%"} toll={"50px"} className="info-column"></Column>
              <Column wide={"50%"} toll={"50px"} className="info-column"></Column>
            </Row>
          </Column>
          <Column wide={"45%"} className="info-column content-end">
            <p>&times;</p>
            <div className="align-center">
              <hr />
              <label>{dataFile?.nome}</label>
            </div>
          </Column>
        </Row>
        <Row wide={"100%"}>
          <Column wide={"100%"} toll={"60px"} className="info-column">
            <div className="align-center">
              <label>OBSERVAÇÕES</label>
            </div>
          </Column>
        </Row>
      </Container>
    </Main>
  )
}

export default FichFuncionario;
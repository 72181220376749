import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background-color: var(--white);
  width: auto;
  height: auto;
  min-height: 400px;
  padding: 16px;

  border-radius: 2px;
  border: 1px solid var(--opacity-background);

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
`;
import { Column } from "../../components/Table/types";

export const itemCols = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'Recurso',
          nome: 'Produto',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
        },
        {
          campo: 'Classe',
          nome: 'Classe',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'classes',
          classe: 'Movimentação de Depósitos',
          classeBase: '61cd2739ad47e0a02eab67ff',
          fixo: true,
        },
        {
          campo: 'EntidadeEntrada',
          nome: 'Depósito de Origem',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Depósitos',
          fixo: true,
        },
        {
          campo: 'EntidadeSaida',
          nome: 'Depósito de Destino',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Depósitos',
          fixo: true,
        },
        {
          campo: 'quantidade',
          nome: 'Qtde',
          editavel: true,
          tipo: 'texto',
          mascara: 'inteiro',
        },
      ];
}

import styled from 'styled-components';

interface IMenuContainerProps {
  showing: boolean;
}

export const Wrapper = styled.div`
  position: relative;
  padding: 0;
  margin: 0;

  & .react-contextify {
    background-color: red !important;
  }
`;

export const Container = styled.button`
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuContainer = styled.div<IMenuContainerProps>`
  background-color: var(--white);
  box-shadow: 5px 5px 4px 0px rgba(0,0,0,0.19);
  position: absolute;
  border: 1px solid var(--muted);
  display: ${props => props.showing ? 'flex' : 'none'};
  flex-direction: column;
  border-radius: 4px;
  min-width: 120px;
  padding: 4px 0px;
  z-index: 4;
  top: 160%;

  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 11px 13px;
    border-color: transparent transparent var(--muted) transparent;
    left: 14%;
    top: -22.3px;
  }

  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 10px 12px;
    border-color: transparent transparent var(--white) transparent;
    left: 15%;
    top: -20px;
  }
`;

export const MenuOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  font-weight: 300;
  transition: .2s;
  padding: 6px 8px;
  text-align: left;

  cursor: pointer;

  &:hover {
    background-color: var(--opacity-background);
  }
`;

export const Separator = styled.hr`
  border-bottom: 1px solid var(--opacity-background);
`;
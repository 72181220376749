import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import { validateCNPJ } from "validations-br";

export const formatMoney = (value: number | string) => 
  Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

export const formatDate = (date: string | Date): string => {
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });
  }
  return date.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
  });
};

export const formatDateHors = (date: string | Date): string => {
  if (typeof date === 'string') {
    return new Date(date).toLocaleString('pt-BR');
  }
  return date.toLocaleString('pt-BR');
};

export const formatDateHorsComDiaSemana = (date: string | Date): string => {
  if (typeof date === 'string') {
    return format(new Date(date), 'dd/MM/yy eeeeee HH:mm', {
      locale: ptBR
    });
  }
  return format(date, 'dd/MM/yy eeeeee HH:mm', {
    locale: ptBR
  });
};

export const formatPhoneNumber = (phone: string) => {
  let cleaned = `${phone}`.replace(/\D/g, '');
  let match = cleaned.match(/(\d{0,2})(\d{1})(\d{0,4})(\d{0,4})/);
  if (match && match[3].length === 4 && match[4].length === 4) {
    return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
  } else {
    cleaned = `${phone}`.replace(/\D/g, '');
    match = cleaned.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    if (match && match[2].length === 4 && match[3].length === 4) return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const formatCPF = (cpf: string) => {
  const cleaned = `${cpf}`.replace(/\D/g, '');
  const match = cleaned.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
  }
  return null;
};

export const formatCNPJ = (cnpj: string) => {
  if (validateCNPJ(cnpj) === false) return null;
  const cleaned = `${cnpj}`.replace(/\D/g, '');
  const match = cleaned.match(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
  }
  return null;
};

export const treeify = (nodes: any[], field: string) => {
  const indexed_nodes: any = {};
  const tree_roots = [];
  for (let i = 0; i < nodes.length; i += 1) {
    indexed_nodes[nodes[i].id] = nodes[i];
  }
  for (let i = 0; i < nodes.length; i += 1) {
    const parent_id = nodes[i][field];
    if (tree_roots.length <= 0 || parent_id === 0 || parent_id === null) {
      tree_roots.push(nodes[i]);
    } else {
      if (!indexed_nodes[parent_id]) {
        tree_roots.push(nodes[i]);
        continue;
      };
      if (indexed_nodes[parent_id].hasOwnProperty('children')) {
        indexed_nodes[parent_id].children.push(nodes[i]);
      } else {
        indexed_nodes[parent_id].children = [nodes[i]];
      }
    }
  }
  return tree_roots;
};

export function formatGroupBy(data: any) {
	let treatedData: any = {};
	const groupFields = Object.keys(data[0]._id);

	data.forEach(function (instance: any) {
		groupFields.reduce(function (acumulador, valorAtual, index) {
			acumulador[instance._id[valorAtual]] = acumulador[instance._id[valorAtual]] || (index + 1 === groupFields.length ? [] : {});
			return acumulador[instance._id[valorAtual]];
		}, treatedData).push(instance.agrupados);
	});

	return treatedData;
}

export function onlyNumberStr(value: string) {
  if (!value) return "";
  return value.replace(/[^0-9]/g, '');
}

export function maskPhone(value: string) {
  if(value) {
    var result = value.replace(/\D/g, "");
    
    if (result.length > 10) {
      result = result.replace(/^(\d{2})(\d{1})(\d{4})(\d{4}).*/, "($1) $2. $3-$4");
    } else if (result.length > 5) {
      result = result.replace(/^(\d{2})(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (result.length > 2) {
      result = result.replace(/^(\d{3})(\d{0,5})/, "($1) $2");
    } else {
      result = result.replace(/^(\d*)/, "($1");
    }
    return result;
  }
  return '';
}

export function nfeMask(numero:string) {
  let rawValue = onlyNumberStr(numero);
  if(rawValue) {
    const completedString = ("000000000"+rawValue).slice(-9);
    return completedString.replace(/^(\d{3})(\d{3})(\d{3})*/, "$1.$2.$3");
  }
  return '';
}
import styled from 'styled-components';
import ButtonLayout from '../../components/Button';

export const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background-color: var(--white);
  width: auto;
  height: auto;
  padding: 16px;

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow-x: auto;

  h1 {
    width: 100%;
    font-size: 20px;
    color: var(--primary-text-color);
    font-weight: 200;
    margin-bottom: 10px;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const ContentColumnLeft = styled.div`
  width: 60%;
`;

export const ContentColumnRight = styled.div`
  width: 40%;
  height: auto;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentResults = styled.div`
  :nth-child(2n + 1) {
    background-color: var(--background);
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    color: var(--primary);
    font-size: 16px;
  }

  span {
    font-size: 16px;
  }
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  float: right;
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;


  & > p {
    margin-bottom: 16px;
  }
`;

export const FormContent = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow: auto;
  padding: 16px;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const Form = styled.form`
  & > button {
    margin-top: 32px;
    font-size: 16px;
    padding: 0px;
  }
`;

export const Button = styled(ButtonLayout)`
  font-size: 12px;
  padding: 10px;
`;

export const ActionsContainer = styled.div`
  width: auto;
  display: flex;
  gap: 16px;

  & button {
    width: auto;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;
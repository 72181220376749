import React from 'react';
import { Container, Title, Content, Card, CardSkill, DivMain, Link, LinkPrimary } from './styles';
import { FcSalesPerformance } from 'react-icons/fc';
import { VscNewFile } from 'react-icons/vsc'
import { AiOutlineReload } from 'react-icons/ai'
import { RiTruckLine } from 'react-icons/ri'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { FcMoneyTransfer } from 'react-icons/fc'
import { MdOutlineRequestQuote } from 'react-icons/md'

const Home: React.FC = () => {
  return (
    <Container>
      <Title>Home</Title>
      <Content>
        <Card>
          <h3>Último Pedido</h3>
          <div className="content">
            <FcSalesPerformance style={{ fontSize: "3rem", float: "right" }} />
            <p>273</p>
          </div>
          <div className="footer">
            <LinkPrimary>
              <AiOutlineReload />
            </LinkPrimary>
            <p>5 min. atrás</p>
            <Link href="#" >Abrir</Link>
          </div>
        </Card>
        <Card>
          <h3>Criar Nova Venda</h3>
          <div className="content">
            <Link href="#">
              <VscNewFile style={{ fontSize: "3rem" }} />
            </Link>
          </div>
        </Card>
        <CardSkill>
          <h3>Análise de Vendas Este Mês</h3>
          <DivMain>
            <div className="container-left">
              <h3>26.6k</h3>
            </div>
            <div className="container">
              <div className="paragraph">
                <p>Primeiro Item</p>
                <p>12.5k</p>
              </div>
              <div className="item">
                <div className="skills first"></div>
              </div>
              <div className="paragraph">
                <p>Segundo Item</p>
                <p>8.3k</p>
              </div>
              <div className="item">
                <div className="skills second"></div>
              </div>
              <div className="paragraph">
                <p>Terceiro Item</p>
                <p>5.8k</p>
              </div>
              <div className="item">
                <div className="skills third"></div>
              </div>
            </div>
          </DivMain>
        </CardSkill>
        <Card>
          <h3>Entregas</h3>
          <div className="content">
            <Link>
              <RiTruckLine style={{ fontSize: "2.5rem" }} />
            </Link>
            <h3>140</h3>
          </div>
          <div className="footer">
            <LinkPrimary>
              <AiOutlineReload />
            </LinkPrimary>
            <p>5 min. atrás</p>
            <Link href="#" >Abrir</Link>
          </div>
        </Card>
        <Card>
          <h3>Faturamento</h3>
          <div className="content">
            <Link>
              <FaFileInvoiceDollar style={{ fontSize: "2.5rem" }} />
            </Link>
            <h3>783</h3>
          </div>
          <div className="footer">
            <LinkPrimary>
              <AiOutlineReload />
            </LinkPrimary>
            <p>5 min. atrás</p>
            <Link href="#" >Abrir</Link>
          </div>
        </Card>
        <Card>
          <h3>Cotações de Vendas</h3>
          <div className="content">
            <Link>
              <FcMoneyTransfer style={{ fontSize: "2.5rem" }} />
            </Link>
            <h3>26</h3>
          </div>
          <div className="footer">
            <LinkPrimary>
              <AiOutlineReload />
            </LinkPrimary>
            <p>5 min. atrás</p>
            <Link href="#" >Abrir</Link>
          </div>
        </Card>
        <Card>
          <h3>Ordem de Pedido</h3>
          <div className="content">
            <Link>
              <MdOutlineRequestQuote style={{ fontSize: "2.5rem" }} />
            </Link>
            <h3>153</h3>
          </div>
          <div className="footer">
            <LinkPrimary>
              <AiOutlineReload />
            </LinkPrimary>
            <p>5 min. atrás</p>
            <Link href="#" >Abrir</Link>
          </div>
        </Card>
      </Content>
    </Container >
  );
}

export default Home;
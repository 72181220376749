/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { ISelectItem } from '../../../components/Lookup';
import {
  Input,
  Lookup,
} from '../styles';

interface Props {
  token?: string;
  onChange(payload: any): void;
  onChangeRemoved?(payload: any): void;
  initialState?: any;
}

interface IPayload {
  nome: string;
  classe: string;
  Entidade?: string[];
}

interface IUsuario {
  nome: string;
  id: string;
}

const GroupForm: React.FC<Props> = ({onChange, onChangeRemoved, token, initialState}) => {
  const [usersToAddOnGroup, setUsersToAddOnGroup] = useState<ISelectItem[] | null>(null);
  const [initialUsersOnGroup, setInitialUsersOnGroup] = useState<ISelectItem[] | null>(null);
  const [groupNameToAdd, setGroupNameToAdd] = useState('');

  useEffect(() => {
    if (initialState) {
      setGroupNameToAdd(initialState.nome);

      if (initialState.DUserGroup) {
        const selected = initialState.DUserGroup.map((item: IUsuario) => {
          return {
            label: item.nome,
            value: item.id,
          }
        });
        
        setInitialUsersOnGroup(selected);
      }
    }
  }, [initialState]);

  useEffect(() => {
    if (initialState && initialState.DUserGroup) {
      const initialIds = initialState.DUserGroup.map((item: IUsuario) => item.id);
      const currentIds = usersToAddOnGroup?.map(user => user.value);

      if (initialIds && currentIds && onChangeRemoved) {
        const removed = initialIds.filter((id: string) => !currentIds.includes(id));
        onChangeRemoved(removed);
      }
    }
  }, [usersToAddOnGroup]);

  useEffect(() => {
    if (onChange) {
        let payload: IPayload = {
            nome: groupNameToAdd,
            classe: 'Grupos',
        };

        if (usersToAddOnGroup && usersToAddOnGroup.length > 0) {
          payload = {
            ...payload,
            Entidade: usersToAddOnGroup.map(item => item.value),
          };
        }

        onChange(payload);
    }
  }, [groupNameToAdd, usersToAddOnGroup, onChange]);

  return (
    <>
        <Input
            id="nome"
            label="Nome"
            value={groupNameToAdd}
            onChange={({currentTarget: {value}}) => setGroupNameToAdd(value)}
        />
        <Lookup
            label="Usuários"
            endpoint="/userGroups/"
            field="nome"
            filters={{
              classe: 'Usuários',
            }}
            token={token}
            initialState={initialUsersOnGroup}
            onChange={options => setUsersToAddOnGroup(options)}
        />
    </>
  );
}

export default GroupForm;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Title,
  Content,
  HeaderContent,
  ActionsContainer,
  Button
} from './styles';
import {useHistory, useLocation} from 'react-router-dom';
import Table from '../../components/Table';
import Toast from '../../components/Toast';
import { criarPedido, getChavePedido, updatePedido} from '../../services/pedidos';
import { itemCols } from './fixedData';

import { useAuth } from '../../contexts/auth';
import { transferirDepositos } from '../../services/movdepos';

const InterDepositTransfer: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const itemEdicao = location.state && location.state.item ? location.state.item : null;
  const {user} = useAuth();
  const novoPedido = location.state && location.state.item ? false : true;
  const [loadings, setLoadings] = useState({
    calcular: false,
    salvar: false,
  });
  const [chaveCriacao, setChaveCriacao] = useState(0);
  const [initialItems, setInitialItems] = useState<any>(null);
  const [items, setItems] = useState<any>(null);
  const [colunasItens, setColunasItens] = useState<any>(itemCols());

  useEffect(() => {
    if (itemEdicao) {
      loadInitialState(itemEdicao);
    }
  }, [itemEdicao]);

  useEffect(() => {
    if (novoPedido) {
      getChaveCriacao();
    }
  }, [novoPedido]);

  function loadInitialState(pedido: any, baixa: boolean = false) {
    const initialItemsData = [];
    for (const item of pedido.items) {
      let itemInitialState: any = {
        Classe: item.Classe ? item.Classe : null,
        Recurso: item.Recurso ? item.Recurso : null,
        EntidadeEntrada: item.Entidade ? item.Entidade : null,
        quantidade: item.quantidade ? parseInt(item.quantidade) : null,
        chave: item.chave ? item.chave : null,
      };
      initialItemsData.push(itemInitialState);
    }
    setInitialItems(initialItemsData);
  }

  async function getChaveCriacao() {
    try {
      const result = await getChavePedido(user?.id);
      setChaveCriacao(result.chave);
    } catch (_err) {
      return Toast.show('Ocorreu um erro ao tentar buscar a chave de criação', 'error');
    }
  }

  function validatePayload(): boolean {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.idRecurso) {
        Toast.show(`Insira o recurso do item ${ i + 1 }`);
        return false;
      } else if (!item.idClasse) {
        Toast.show(`Insira a classe do item ${ i + 1 }`);
        return false;
      } else if (!item.idEntidadeEntrada) {
        Toast.show(`Insira o depósito de origem do item ${ i + 1 }`);
        return false;
      } else if (!item.idEntidadeSaida) {
        Toast.show(`Insira o depósito de destino do item ${ i + 1 }`);
        return false;
      } else if (!item.quantidade) {
        Toast.show(`Insira a quantidade do item ${ i + 1 }`);
        return false;
      }
    }

    return true;
  }

  function handleChangeLoading(field: string, value: boolean) {
    return setLoadings(oldValue => {
      return {...oldValue, [field]: value};
    });
  }

  async function handleSalvar() {
    handleChangeLoading('salvar', true);
    try {
      const itemsToSend = items.map((_item: any) => {
        const item = { ..._item, chcriacao: chaveCriacao };
        return item;
      })

      console.log(itemsToSend);

      // await transferirDepositos(itemsToSend);
      // handleChangeLoading('salvar', false);
      // Toast.show(`Transferência realizada com sucesso.`, 'success');
      // history.push('/movimentacao-depositos');
    } catch (error: any) {
      handleChangeLoading('salvar', false);
      Toast.show(error.message);
    }
  }

  async function handleSalvarUpdate(){
    try {
      // let toSendPayload: any = {
      //   tipo: 'baixa de venda',
      //   baixaAutomatica: false,
      //   baixa: true,
      //   baixaId: user?.id,
      //   idAprovador: null,
      //   aprovacao: null,
      //   idLocEscritu: lookups.LocEscrituracao.value,
      //   idEstabelecimento: lookups.Estabelecimento.value,
      //   idPessoa: lookups.Pessoa.value,
      //   idRepresentante: lookups.Representante.value,
      //   observacao: payload.observacao,
      //   emissao: convertToDate(payload.emissao),
      //   frete: payload.frete ? payload.frete : 0.00,
      //   seguro: payload.seguro ? payload.seguro : 0.00,
      //   acrescimo: payload.acrescimo ? payload.acrescimo : 0.00,
      //   desconto: payload.desconto ? payload.desconto : 0.00,
      //   idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : '',
      //   ItensDoPedido: items.map((_item: any, index: number) => {
      //     let item = { ..._item, chcriacao: chaveCriacao };
      //     if (baixaAutomatica) {
      //       item = {...item, chpedbaixa: item.chave};
      //     }
      //     if (totais.items && totais.items.length > 0) {
      //       const {vrItem = null, valorTotal = null, ...itemPedido} = item;
      //       return {
      //         ...itemPedido,
      //         total: totais.items[index].valorTotal,
      //         valor: totais.items[index].vrItem,
      //       }
      //     } else {
      //       return item;
      //     }
      //   }),
      //   Titulos: titulos.map((_titulo: any, index: number) => {
      //     const titulo = { ..._titulo, chpedido: chaveCriacao };
      //     return titulo;
      //   }),
      // };

      // if (comAprovador) {
      //   toSendPayload = {
      //     ...toSendPayload,
      //     idAprovador: user?.id,
      //     aprovacao: new Date(),
      //   };
      // }

      // await updatePedido(chaveCriacao, toSendPayload);
      // handleChangeLoading('salvar', false);
      // Toast.show(`O pedido de chave ${chaveCriacao} foi atualizado com sucesso.`, 'success');
      // history.push('/pedidos-de-saida');
    } catch (error: any) {
      handleChangeLoading('salvar', false);
      Toast.show(error.message);
    }
  }

  async function handleSubmit(e: any) {
    let tipo;
    if (e) {
      e.preventDefault();
      tipo = e.nativeEvent.submitter.name;
    }
    
    switch(tipo) {
      case 'salvar':
        const payloadValido = validatePayload();

        if (payloadValido) {
          await handleSalvar();
        }
        break;
      default:
        break;
    }
  }
  
  return (
    <Wrapper>
      <HeaderContent>
        <Title>Transferência Entre Depósitos - Ch. de Criação N° {chaveCriacao}</Title>
      </HeaderContent>
      <Content id="custom-form" onSubmit={handleSubmit}>
        <Table 
          actions={['insert', 'delete']}
          title="Itens"
          onChange={(result: any) => setItems(result)}
          fromForm
          withTotal
          isRecurso
          initialValue={initialItems}
          chaveCriacao={chaveCriacao}
          cols={colunasItens}
        />
        <Button form="custom-form" type="submit" id="salvar" name="salvar" disabled={loadings.salvar}>
          {loadings.salvar ? "Salvando..." : "Salvar"}
        </Button>
      </Content>
    </Wrapper>
  );
}

export default InterDepositTransfer;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import { formatDate, formatGroupBy, formatMoney, onlyNumberStr, nfeMask, formatDateHorsComDiaSemana } from '../../utils/format';
import {
  Button,
  Container,
  DivItems,
  FiltersContainer,
  LogoFooter,
  Main,
  RightButtons,
  Row,
} from './styles';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import { DivRow } from './styles';
import { useFormModal } from '../../contexts/formModal';
import Toast from '../../components/Toast';
import { getLicenseInfo } from '../../services/license';
import { getSellingReport } from '../../services/pedidos';
import { format, isValid } from 'date-fns';
import ResumeFiltersReport from '../../components/ResumeFiltersReport';
import { convertToDate } from '../../utils/date';
import ptBR from 'date-fns/locale/pt-BR';
import { VERSION } from '../../environment/stores';
interface IReport {
  Cidade?: ICidade;
  Classe?: IClasse;
  UF?: IUf;
  atualizadoEm?: string;
  bairro?: string;
  cep?: string;
  codigo?: string;
  criadoEm?: string;
  endereco?: string;
  id?: string;
  inscricaoEstadual?: string;
  nome?: string;
  nomeFantasia?: string;
  numeroEndereco?: string;
  telefone?: string;
  celular?: string;
}

interface ICidade {
  id?: string;
  nome?: string;
}

interface IClasse {
  id?: string;
  nome?: string;
}

interface IUf {
  id?: string;
  nome?: string;
  codigo?: string;
}

interface IField {
  label: string;
  field: string;
  orderName?: string;
  right?: boolean;
}

interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  filters: any;
  extraction?: any;
}

const ORDER_FIELD_PROVIDERS: IField[] = [
  { label: 'Ch. Pedido', field: 'chcriacao' },
  { label: 'Ch. Pd Item', field: 'chave' },
  { label: 'Ch. Baixa', field: 'chbaixa' },
  { label: 'Ch. Bx Item', field: 'chpedbaixa' },
  { label: 'LE', field: 'LE' },
  { label: 'DOC', field: 'numero' },
  { label: 'Emissão', field: 'emissao' },
  { label: 'CFOP', field: 'cfop'},
  { label: 'Classe', field: 'Classe' },
  { label: 'Recurso', field: 'Recurso'},
  { label: 'UM', field: 'UnidadeMedida'},
  // { label: 'Pessoa', field: 'Pessoa' },
  { label: 'Núcleo', field: 'Nucleo' },
  { label: 'Qtd', field: 'qtd', right: true},
  { label: 'Unit (R$)', field: 'unitario', right: true},
  { label: 'Total (R$)', field: 'total', right: true},
]

const PurchaseReport: React.FC<Props> = ({ title, endpoint, filters }) => {

  const location = useLocation<any>();
  const history = useHistory<any>();
  const { openModal } = useFormModal();
  const componentRef = useRef(null);
  const [reports, setReports] = useState<Array<IReport> | any>(null);
  const [marcaTexto, setMarcaTexto] = useState(false);
  const [gerarCabecalho, setGerarCabecalho] = useState(false);
  const [openList, setOpenList] = useState<Array<string>>([]);
  const [hasGroup, setHasGroup] = useState(false);
  const [fields, setFields] = useState<IField[]>(ORDER_FIELD_PROVIDERS)
  const [license, setLicense] = useState<any>(null);
  const [filterLabel, setFilterLabel] = useState<any>([])
  const [nivelArvore, setNivelArvore] = useState<number | null>(null);

  const date = new Date();

  useEffect(() => {
    getLicense();
  }, []);

  useEffect(() => {
    if (location.state) {
      const details = location.state.filtersToGet
      const _filterLabels = location.state.filtersLabels
      
      if (_filterLabels) {
        setFilterLabel(_filterLabels)
        if(!_filterLabels.find((it:any) => (it.label === "Exibe CFOP" && it.value === 'SIM'))) {
          setFields(oldValue => oldValue.filter(it => it.field !== "cfop"))
        }
      }

      if (details.marcaTexto) {
        setMarcaTexto(true)
        delete details.marcaTexto
      }
      if (details.gerarCabecalho) {
        setGerarCabecalho(true)
        delete details.gerarCabecalho
      }
      if (details.Agrupamento && details.Agrupamento.length) {
        setHasGroup(true);
        getReports(details, true);
        removeFields(details.Agrupamento);
      } else {
        getReports(details);
      }
      if(details.nivelArvore) {
        setNivelArvore(details.nivelArvore);
      }

    }
  }, [])

  function removeFields(agrupamento:string[]) {
    let newFields = fields;
    
    agrupamento.map((ag) => {
      if(ag === "Recurso (Nome)" || ag === "Recurso (Código+Nome)" || ag === "Recurso" || ag === 'Recurso (Código)') {
        newFields = newFields.filter(field => field.label !== "Recurso");
      } else if(ag === 'Número do Documento') {
        newFields = newFields.filter(field => field.label !== "DOC");
      } else if(ag === 'Local de Escrituração') {
        newFields = newFields.filter(field => field.label !== "LE");
      } else {
        newFields = newFields.filter(field => field.label !== ag);
      }
      return null;
    });

    setFields(newFields);
  }

  async function getLicense() {
    try {
      const result = await getLicenseInfo();
      setLicense(result);
    } catch (error: any) {
      Toast.show(error, 'error');
    }
  }

  async function getReports(details: any, grouped:boolean = false) {
    let result;

    try {
      result = await getSellingReport(details);
      if(grouped) {
        const formated = formatGroupBy(result);
        setReports(formated);
      } else {
        setReports(result);
      }

    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  const pageStyle = `
  @media print{
    html, body{
      border-collapse: collapse;
      height: initial !important;
      overflow: initial !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    table, th, td {
      border-collapse: collapse;
      white-space: normal;
    }
    .page-break {
      margin-top: 20px !important;
      display: block;
      page-break-before: auto;
    }

    div:nth-child(2) {
      height: auto;
    }
  }
  @page{
    margin: 20mm;
    size: auto;
  }
  `

  function checkIsOpen(item: any) {
    const _find = openList.find((op: any) => {
      if (op === item) {
        return true;
      }
      return false;
    });

    if (_find === null) {
      return true
    }

    return _find;
  }

  function handleOpen(item: any) {
    const _nome = item;
    const indexOf = openList.findIndex((it: any) => it === item);

    if (indexOf > -1) {
      const newList = [...openList];
      newList.splice(indexOf, 1);
      setOpenList(newList);
    } else {
      const newList = [...openList, _nome];
      setOpenList(newList);
    }
  }
  
  function _renderClassTr(item: any, identifier: number = 0, margin: number = 0): any {

    if (Array.isArray(item)) {
      return renderItemTable(item);
    } else {
      const _trs = Object.keys(item);
      if (_trs && _trs.length <= 0) {
        return (
          <tr>
            <td className="red-label" colSpan={fields.length}><b>Nenhum resultado para estas variáveis</b></td>
          </tr>
        )
      }
      return _trs.map((tr, index) => {
        const isOpen = nivelArvore && nivelArvore <= margin ? true : checkIsOpen(`${tr}${identifier}`) ;
        return <>
          <tr className='button'>
            <td>
              <div onClick={() => handleOpen(`${tr}${identifier}`)} className='titleContainer'>
                <div className='plusBox' style={{ marginLeft: (margin) * 10 }}>
                  {isOpen ? <FaMinusSquare color='white' size={14} /> : <FaPlusSquare color='white' size={14} />}
                </div>
                <h4>{tr === 'null' ? ` ` : tr}</h4>
              </div>
            </td>
            {fields.map((_item, index) => index !== 0 && (
              <td key={index}></td>
            ))}
          </tr>
          {isOpen ? _renderClassTr(item[tr], index, 1 + margin) : null}
        </>
      })
    }
  }

  function renderItemTable (item: any) {
    const _listReport = hasGroup ? item[0] : item;
    if (_listReport && _listReport.length <= 0) {
      return (
        <tr>
          <td className="red-label" colSpan={fields.length}><b>Nenhum resultado para estas variáveis</b></td>
        </tr>
      )
    }
    return _listReport.map((it:any, idx:number) => {
      return <tr 
      key={idx}
      className={marcaTexto ? 'yellow' : ''}>
        {fields.map(field => {
          let value = it[field.field];
          if((field.field === 'qtd' || field.field === "unitario" || field.field === "total") && value) {
            value = formatMoney(value).replace("R$", "");
          }

          
          if(field.field === 'numero') {
            if(value && (it["TipoDocumento"] === "NFC-e" || it["TipoDocumento"] === "NF-e")) {
              value = onlyNumberStr(value);
              value = nfeMask(value);
              return <td>
               {it["TipoDocumento"]} {value}
              </td>
            }
          }
          
          if(field.field === 'emissao') {
            if(it[field.field]){
              const emissao = it[field.field]
              const data = new Date(emissao)
              
              if(isValid(data)){
                value = data.toLocaleDateString("pt-br", {timeZone: "UTC"})
              } else{
                value = ''
              }
            }
          }

          return <td style={{textAlign: field.right ? 'right' : 'left'}}>
            {value}
          </td>
        }
        )}
      </tr>
    })
  }

  function renderTable() {
    if(reports) {
      if (hasGroup) {
        return _renderClassTr(reports);
      }
      return renderItemTable(reports);
    } 
    return null
  }

  function handleOpenResumo() {
    let dtEmissao = convertToDate(date);
    let emissao = dtEmissao ? format(dtEmissao, 'dd/MM/yy eeeeee HH:mm', {
      locale: ptBR,
    }) : date;
    const copyFilters = [
      {
        label: 'Data',
        value: emissao,
      },
      {
        label: 'Versão do Sistema',
        value: VERSION,
      },
      {
        label: 'Caminho',
        value: 'Vendas \\ Relatórios \\ Pedidos de Saída',
      },
      ...filterLabel,
    ];
    openModal({
      title: `Resumo Variáveis`,
      children: () => <ResumeFiltersReport title={title} filters={copyFilters} />
    })
  }

  return (
    <Main>
      <Row>
        <Button onClick={() => history.goBack()}>Voltar</Button>
        <RightButtons>
          <Button onClick={handleOpenResumo}>Resumo</Button>
          <ReactToPrint
            trigger={() => {
              return <Button type='button' className='buttonPrint'>Imprimir</Button>
            }}
            content={() => componentRef.current}
            documentTitle="new document"
            pageStyle={pageStyle}
          />
        </RightButtons>
      </Row>

      <Container ref={componentRef}>
        <DivRow>
          {license && (
            <Row>
              <p>{license.nomeFantasia}</p>
              <label>Versão do Sistema: {VERSION}</label>
            </Row>
          )}
          <Row>
            <p>{title}</p>
            <label>{formatDateHorsComDiaSemana(date)}</label>
          </Row>
          <Row>
            <p>Vendas \ Relatórios \ Pedidos de Saída</p>
          </Row>
          {gerarCabecalho && (<FiltersContainer>
            <p className='title'>Filtros: </p>
            <p>
              {filterLabel.map((filter: any, index: any) => {
                let value = '';

                if (Array.isArray(filter.value)) {
                  if (filter.value.length === 0) value = 'Nenhum';
                  value = filter.value.map((it: any) => `${it} `);
                } else {
                  if (filter.value instanceof Date) {
                    value = formatDate(filter.value);
                  } else {
                    value = filter.value;
                  }
                }
                return (
                  <label className='label'>
                    {filter.label} - <span className='span'>{value}</span>
                  </label>
                )
              })}
            </p>
          </FiltersContainer>)}
        </DivRow>

        <DivItems>
          <table>
            <tr style={{ textAlign: 'left' }}>
              {fields.map((field) => <th
              key={field.field}>
                {field.label}
              </th>
              )}
            </tr>
            {renderTable()}
          </table>
        </DivItems>

        <LogoFooter>
          <img src={LogoDevari} alt="Logo Devari" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default PurchaseReport;
import { AxiosRequestHeaders } from 'axios';
import React, {useState, useEffect} from 'react';
import { ActionMeta } from 'react-select';
import {Container, Label, AsyncSelect} from './styles';

import useFetch from '../../hooks/useFetch';

export interface ISelectItem {
  label: string;
  value: string;
}

interface Props {
    label: string;
    endpoint: string;
    field: string;
    filters?: any;
    token?: string;
    onChange?(options: ISelectItem[]): void;
    initialState?: any;
}

const Lookup: React.FC<Props> = ({label, endpoint, field, filters, token, onChange, initialState}) => {
  const lookupHandler = useFetch({endpoint});
  const [input, setInput] = useState('');
  const [value, setValue] = useState<ISelectItem[]>([]);

  useEffect(() => {
    setValue(initialState);
  }, [initialState]);

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    setInput(inputValue);
    return inputValue;
  };

  const loadOptions = async (
    inputValue: string,
  ) => {
    if (!inputValue) return;
    const headers: AxiosRequestHeaders = {};

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    
    let params = {
        [field]: inputValue,
    };

    if (filters) {
        params = {...params, ...filters};
    }

    const data = await lookupHandler.get(params, headers);

    const formatted = data.map((item: any) => {
        return {
            value: item.id,
            label: item.codigo,
        };
    });

    return formatted;
  };

  const handleChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    if (onChange && newValue) {
      const options = newValue as ISelectItem[];
      onChange(options);
      setValue(options);
    }
  }

  return (
    <Container>
        <Label>{label}</Label>
        <AsyncSelect
            isMulti
            maxMenuHeight={150}
            value={value}
            loadOptions={loadOptions}
            inputValue={input}
            onInputChange={handleInputChange}
            onChange={handleChange}
            noOptionsMessage={() => "Nenhum item"}
            placeholder=""
            loadingMessage={() => "Carregando..."}
        />
    </Container>
  );
}

export default Lookup;
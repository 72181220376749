import React from 'react';
import { ModalProvider } from './modal';
import { AuthProvider } from './auth';
import { MenuProvider } from './menu';
import { FormModalProvider } from './formModal';

const ContextProvider: React.FC = ({children}) => {
  return (
    <MenuProvider>
      <ModalProvider>
          <AuthProvider>
            <FormModalProvider>
            {children}
            </FormModalProvider>
          </AuthProvider>
      </ModalProvider>
    </MenuProvider>
  );
}

export default ContextProvider;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import { useModal } from '../../contexts/modal';
import { IoClose } from 'react-icons/io5';
import { Container, Wrapper, Children, Content, Header, Actions, Button } from './styles';

type OnSubmitType = () => void;
type OnConfirmType = () => void;

export interface IModalProps {
  type: string;
  title: string;
  submitLabel: string;
  children(): React.ReactElement;
  onSubmit: OnSubmitType | null;
  onConfirm: OnConfirmType | null;
  onCancel?(): void;
  closeOnAction: boolean;
  props: any;
}

const Modal: React.FC<IModalProps> = ({
  type,
  title,
  children,
  submitLabel,
  onConfirm,
  onSubmit,
  closeOnAction = true,
}) => {

  const {closeModal} = useModal();

  useEffect(() => {
    const close = (event: KeyboardEvent) => {
      if(event.code === 'Escape') {
        closeModal();
      }
    }

    document.addEventListener('keydown', close);

    return () => document.removeEventListener('keydown', close);
  }, [])

  const handleConfirm = () => {
    if (onConfirm !== null) {
        onConfirm();
    }

    if (closeOnAction) {
      closeModal();
    }
  }

  const handleSubmit = () => {
    if (onSubmit !== null) {
      onSubmit();
    }

    if (closeOnAction) {
      closeModal();
    }
  }

  return (
    <Container>
        <Wrapper onClick={closeModal} />
        <Content>
          <Header>
            <p>{title}</p>
            <IoClose onClick={closeModal} size={18} color="var(--white)"/> 
          </Header>
          <Children>
            {children()}
          </Children>
          {type && <Actions>
            {type === 'confirm' && (
              <>
                <Button onClick={() => handleConfirm()}>Confirmar</Button>
                <Button variant="outlined" onClick={() => closeModal()}>Cancelar</Button>
              </>
            )}
            {type === 'submit' && (
              <>
                <Button onClick={() => handleSubmit()}>{submitLabel}</Button>
              </>
            )}
          </Actions> }
        </Content>
    </Container>
  );
}

export default Modal;
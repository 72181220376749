import styled from 'styled-components';
import {IVariantStyleProps} from '.';

export const Container = styled.button<IVariantStyleProps>`
  background: ${props => props.background};
  color: ${props => props.color};
  width: 100%;
  height: 35px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  transition: .2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${props => props.background === 'transparent' ? 'transparent' : 'var(--muted)'};
    color: ${props => props.background === 'transparent' ? 'var(--muted)' : props.color};
    cursor: no-drop;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background-color: var(--white);
  width: auto;
  height: auto;
  padding: 16px;

  border-radius: 2px;
  border: 1px solid var(--opacity-background);

  h1 {
    width: 100%;
    font-size: 20px;
    color: var(--primary-text-color);
    font-weight: 200;
    margin-bottom: 10px;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const ContentColumnLeft = styled.div`
  width: 60%;
`;

export const ContentColumnRight = styled.div`
  width: 40%;
  height: auto;
  margin-left: 20px;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: flex;
  margin: 5px 0;
  margin-bottom: 45px;
`;

export const Card = styled.div`
  background-color: #eee8aa;
  width: auto;
  padding: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

  h1 {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
  }
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
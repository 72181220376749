import styled from 'styled-components';
import AsyncSelectLayout from 'react-select/async';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--input-border-blurred);
  transition: .2s;
  padding: 2px 0 2px 0;

  &:focus-within {
    border: 1px solid var(--input-border-focused);
  }
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: var(--muted);
  padding: 0px 8px 0px 8px;
`;

export const AsyncSelect = styled(AsyncSelectLayout)`
  & > div {
    border: 1px solid var(--opacity-background);
    box-shadow: none;
  }

  & > div:nth-child(3) {
    border: none;
  }
`;
import React from 'react';
import {
  Container,
  Title,
  Content,
  ButtonContainer,
} from './styles';
import Table from '../../components/Table';
import Button from '../../components/Button';

const Inventory: React.FC = () => {
  return (
    <Container>
      <Title>Inventário</Title>
      <Content>
        <Table 
          title="Inventário / Ajuste de Estoque"
          cols={[
            {
              id: "1",
              name: "",
              size: "small",
              align: "center",
              type: "checkbox",
              editable: true,
            },
            {
              id: "2",
              name: "Chave",
              size: "small",
              align: "center",
              type: "input",
              editable: false,
            },
            {
              id: "3",
              name: "Classe do Inventário",
              size: "large",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "4",
              name: "Usuário",
              size: "medium",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "5",
              name: "Produto",
              size: "large",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "6",
              name: "Unid.",
              size: "small",
              align: "center",
              type: "text",
              editable: false,
            },
            {
              id: "7",
              name: "Lote",
              size: "medium",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "8",
              name: "Quantidade",
              size: "large",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "9",
              name: "Custo Unitário",
              size: "large",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "10",
              name: "Saldo",
              size: "medium",
              align: "center",
              type: "text",
              editable: false,
            },
            {
              id: "11",
              name: "Data do Ajuste",
              size: "medium",
              align: "center",
              type: "text",
              editable: true,
            },
            {
              id: "12",
              name: "Código de Barras-EAN",
              size: "large",
              align: "center",
              type: "text",
              editable: true,
            },
          ]}
        />
        <ButtonContainer>
          <Button>Excluir</Button>
          <Button>Imprimir</Button>
          <Button>Gravar</Button>
          <Button>Fechar</Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
}

export default Inventory;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, RefObject} from 'react';

const useDetectClickOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (!ref?.current?.contains(event.target as Node)) {
                return callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default useDetectClickOutside;
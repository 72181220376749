import styled from 'styled-components';

interface Button {
  isSelected: boolean;
}

export const Item = styled.li`
  display: inline;
  width: 100%;
`;

export const Botao = styled.button<Button>`
  background: ${({isSelected}) => isSelected ? 'var(--primary)' : 'var(--white)'};
  width: 100%;
  color: ${({isSelected}) => isSelected ? 'var(--white)' : 'var(--primary-text-color)'};
  border: none;
  border-bottom: 1px solid var(--opacity-background);
  border-left: 1px solid var(--opacity-background);
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
`;
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useFormik, FormikHelpers} from 'formik';
import parseDate from 'date-fns/parse';
import ptBR from 'date-fns/locale/pt-BR';
import * as Validate from 'validations-br';
import useFetch from '../../../hooks/useFetch';
import { IField } from '../../../components/ListTable';
import { FormWrapper, Title, FormContent, Form, Button, HeaderContent, ActionsContainer } from '../styles';
import {
  orderCols, adjustByRow, treatColField, 
  formatCnpj, formatCpf, saveReportFormValues,
  pinClass, getReportFormValues
} from '../../../utils/form';
import {isValidDate} from '../../../utils/date';

import FormInputs from '../../../components/FormInputs';
import Toast from '../../../components/Toast';
interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  customFields?: any;
  editCustomFields?: any;
  filters: any;
  actions?: any;
  path?: any;
}

const FormPurchaseReportTemplate: React.FC<Props> = ({
  title,
  endpoint,
  path,
  history,
  actions,
  location,
  filters,
}) => {
  const colsHandler = useFetch({
    endpoint: `${endpoint}/campos/`,
  });
  const [pinedClass, setPinedClass] = useState(false);
  const [loadedFilters, setLoadedFilters] = useState(false);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [fields, setFields] = useState<any>({});
  const [actionsButtons, setActionsButtons] = useState<any[]>([]);
  const [lookups, setLookups] = useState<any>({});
  const [initialState, setInitialState] = useState<any>({
    marcaTexto: null,
    gerarCabecalho: null,
  });

  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getData(true);
  }, [endpoint]);

  useEffect(() => {
    if (location && !location.state && filters && Object.keys(fields).length > 0 && !pinedClass) {
      pinClass(fields, setFields, filters.classe, false);
      setPinedClass(true);
    }
  }, [location, cols, filters, fields, pinedClass]);

  useEffect(() => {
    if (cols) {
      adjustStartCols();
    }
  }, [cols]);

  useEffect(() => {
    if(Object.keys(fields).length > 0 && !loadedFilters) {
      setLoadedFilters(true);
      loadPreviusFilters();
    }
  }, [fields, loadedFilters])

  useEffect(() => {
    if (actions) {
      const globalActions = actions.filter((action: any) => action.type === "global");

      if (globalActions.length > 0) {
        const global = globalActions.map((action: any) => {
          const option: any = {
            ...action,
            onClick: 
                action.onClick 
                ? typeof action.onClick === 'string' 
                ? () => history.push({
                  pathname: action.onClick,
                  state: action.params ? {
                    ...action.params
                  } : {},
                })
                : () => action.onClick()
                : () => {},
          };
          return option;
        });
  
        setActionsButtons(global);
      }
    }
  }, []);

  function loadPreviusFilters() {
    const _prev = getReportFormValues(title);
    if(_prev) {
      const colsInitialValue:any = {};
      const lookupInitialState: any = {};
      for(const c of cols) {
        if (
          c.tipo === "texto" ||
          c.tipo === "select" ||
          c.tipo === "tabela" ||
          c.tipo === "checkbox"
        ) {
          colsInitialValue[c.campo] = _prev[c.campo];
        } else if (c.tipo === "lookup") {
          if (_prev[c.campo] && _prev[c.campo].value) {
            lookupInitialState[c.campo] = _prev[c.campo].value;
          } else if(_prev[c.campo] && Array.isArray(c.data)) {
            lookupInitialState[c.campo] = _prev[c.campo];
          }
        }
      }
      setInitialState(colsInitialValue);
      setLookups(lookupInitialState);
      pinClass(fields, setFields, "", false, lookupInitialState)
    }
  }

  function adjustStartCols() {
    const colsField: any = {};
    const orderedCols: any = cols.sort(orderCols);
    
    for (const col of orderedCols) {
      const field = treatColField(col, handleChangeLookup, null);

      if (field) {
        colsField[col.campo] = field;
      }
    }

    let byRow: any = adjustByRow(colsField);
    setFields(byRow);
  }

  async function getData(_firstRender = false) {
    const result = await colsHandler.get(filters);
    const editableCols = result.filter((col: any) => col.editavel === true);
    setCols(editableCols);
  }

  function validate(values: any) {
    const requireFields: any = cols.filter((col) => col.required);
    const errors: any = {};

    for (const _field of requireFields) {
      const { campo, nome, tipo } = _field;
      if (values.hasOwnProperty(campo)) {
        if (!values[campo]) {
          errors[campo] = `é obrigatório`;
        } else {
          let isValid;
          switch (campo) {
            case "cpfCnpj":
              if (values[campo].replace(/\D/g, "").length < 12) {
                isValid = Validate.validateCPF(values[campo]);
                if (!isValid) {
                  errors[campo] = "CPF inválido";
                }
              } else {
                isValid = Validate.validateCNPJ(values[campo]);
                if (!isValid) {
                  errors[campo] = "CNPJ inválido";
                }
              }

              break;
            case "cep":
              isValid = Validate.validateCep(values[campo]);
              if (!isValid) {
                errors[campo] = "CEP inválido";
              }
              break;
            case "email":
              isValid = Validate.validateEmail(values[campo]);
              if (!isValid) {
                errors[campo] = "E-mail inválido";
              }
              break;
            case "telefone":
            case "celular":
              isValid = Validate.validatePhone(values[campo]);
              if (!isValid) {
                errors[campo] = "Número inválido";
              }
              break;
            default:
              break;
          }
        }
      } else if (!lookups.hasOwnProperty(campo) && tipo === "lookup") {
        errors[campo] = `é obrigatório`;
        Toast.show(`Insira ${nome}`);
      } else {
        if (_field.tipo !== "lookup") {
          errors[campo] = `é obrigatório`;
        }
      }
    }

    return errors;
  }
  
  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return {...prevState, [field]: options}
    });
  }

  function generateFilterLabels(filters: any) {
    let labels:any[] = [];

    cols.map((c:any) => {
      if(c.tipo === "lookup" && c.multi) {
        labels.push({
          label: c.nome,
          value: filters[c.campo] ?? []
        })
      } else if(c.tipo === 'lookup') {
        labels.push({
          label: c.nome,
          value: lookups[c.campo]?.label
        })
      } else if(c.tipo === 'checkbox') {
        const _value = typeof(filters[c.campo]) === 'boolean' ? filters[c.campo] ? 'SIM' : 'NÃO' : filters[c.campo];
        labels.push({
          label: c.nome,
          value: _value ?? 'NÃO'
        })
      } else {
        labels.push({
          label: c.nome,
          value: filters[c.campo]
        })
      }
    })

    return labels;
  }

  async function handleSubmit(values: any, helpers: FormikHelpers<any>) {
    let payload = {
      ...values,
    };
    
    if (lookups) {
      
      const keys = Object.keys(lookups);

      for (const key of keys) {
        if(Array.isArray(lookups[key])){
          payload = {
            ...payload,
            [key]: lookups[key].map((item: any) => item.value)
          }
        } else{
          const idKey = `id${key}`;
          if(lookups[key]) {
            payload = {
            ...payload,
            [idKey]: lookups[key].value,
            }
          }
        }
      }
    }
    
    let adjustedPayload: any = {};
  
    for (const key of Object.keys(payload)) {
      const value = payload[key];
      const isDate = isValidDate(value);
      if (isDate) {
        adjustedPayload = {
          ...adjustedPayload,
          [key]: parseDate(value, isDate === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm', new Date(), { locale: ptBR }),
        }
      } else {
        if (key === 'cpfCnpj') {
          if (value === null || value === "" || value === undefined) {
            adjustedPayload[key] = null;
          } else {
            let adjustedValue = value.replace(/\D/g, "");
            if (adjustedValue.length < 12) {
              adjustedValue = formatCpf(adjustedValue);
            } else {
              adjustedValue = formatCnpj(adjustedValue);
            }
            adjustedPayload[key] = adjustedValue;
          }
        } else {
          adjustedPayload[key] = value === "" ? null : value;
        }
      }
    }
    saveReportFormValues({...values, ...lookups}, title);
    
    try {
      history.push({
        pathname: `${path}/resultado`,
        state: {
          filtersToGet: adjustedPayload,
          filtersLabels: generateFilterLabels(adjustedPayload)
        }
      })
    } catch (err: any) {
      helpers.setSubmitting(false);
    }
  }

  return (
    <FormWrapper>
    <HeaderContent>
      <Title>{title}</Title>
      <ActionsContainer>
        {actionsButtons?.map(action => (
            <Button
              key={action.name}
              onClick={action.onClick ? () => action.onClick() : () => {}}
            >
              {action.name}
            </Button>
          ))}
      </ActionsContainer>
    </HeaderContent>
      <FormContent>
        <Form onSubmit={form.handleSubmit}>
          <FormInputs fields={fields} form={form} disableFormModal />
          <Button disabled={form.isSubmitting} variant="filled" type="submit">Buscar</Button>
        </Form>
      </FormContent>
    </FormWrapper>
  );
}

export default FormPurchaseReportTemplate;
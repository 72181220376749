import styled from 'styled-components';
import InputMask from 'react-input-mask';
import CurrencyInputLayout from 'react-currency-input';

interface InputProps {
  width?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
  error?: string;
}

interface IWrapperProps {
  row: boolean;
}

interface ILabel {
  bold?: boolean;
  error?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};
  align-items: ${props => props.row ? 'center' : 'flex-start'};
  margin: 5px 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: none;
  min-height: 50px;
`;

export const Label = styled.label<ILabel>`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 4px;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-overflow: none;
  color: ${props => props.error ? 'red' : 'auto'};
  font-weight: ${props => props.bold ? '500': '300'};

  & span {
    color: red;
  }
`
;
export const Input = styled.input<InputProps>`
  width: 100%;
  min-height: 35px;
  border: ${({ error }) => 
    (error || error?.length) ? '1px solid var(--warning)' : '1px solid var(--opacity-background)'};
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  white-space: nowrap;
  background-color: ${props => props.disabled ? 'var(--opacity-background)' : 'var(--white)'};

  &:focus {
    background-color: var(--white);
    border: 1px solid var(--primary);
  }
`;
export const Check = styled.input.attrs({
  type: 'checkbox',
})<InputProps>`
  border: ${({ error }) => 
    (error || error?.length) ? '1px solid var(--warning)' : '1px solid var(--opacity-background)'};
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  white-space: nowrap;
  margin-right: 8px;

  &:focus {
    background-color: var(--white);
    border: 1px solid var(--primary);
  }
`;

export const MaskInput = styled(InputMask)<InputProps>`
  width: 100%;
  min-height: 35px;
  border: ${({ error }) => 
    (error || error?.length) ? '1px solid var(--warning)' : '1px solid var(--opacity-background)'};
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  white-space: nowrap;

  &:focus {
    background-color: var(--white);
    border: 1px solid var(--primary);
  }
`;

export const Error = styled.p`
  margin-top: 4px;
  color: red;
`;

export const CurrencyInput = styled(CurrencyInputLayout)<any>`
  width: 100%;
  min-height: 35px;
  min-width: 100px;
  text-align: right;
  border: ${({ error }) => 
    (error || error?.length) ? '1px solid var(--warning)' : '1px solid var(--opacity-background)'};
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  white-space: nowrap;
  background-color: ${props => props.disabled ? 'var(--opacity-background)' : 'var(--white)'};

  &:focus {
    background-color: var(--white);
    border: 1px solid var(--primary);
  }
`;
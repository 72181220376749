import styled from 'styled-components';

import InputLayout from '../../components/TextInput';
import SelectLayout from '../../components/SelectInput';

interface IRowProps {
  amount: number;
}

interface IUploadRowProps {
  amount?: string;
  direction?: string;
  gap?: string;
}

export const Input = styled(InputLayout)`
  margin-bottom: 8px;
`;

export const Select = styled(SelectLayout)`
  margin-bottom: 8px;
`;

export const Row = styled.div<IRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & > div, & > select {
    width: ${props => props.amount}%;
  }

  @media(max-width: 1024px) {
    flex-direction: column;
    & > div, & > select {
      width: 100%;
    }
  }

  @media(max-width: 559px) {
    flex-direction: column;
  }
`;

export const UploadRow = styled.div<IUploadRowProps>`
  display: flex;
  width: ${props => props.amount}%;
  flex-direction: ${props => props.direction ? props.direction : 'row'};
  gap: ${props => props.gap ? props.gap : 0}px;

  @media (max-width: 559px) {
    flex-direction: column;
    width: 100%;
  }
`;

import isDateValid from 'date-fns/isValid';
import parseDate from 'date-fns/parse';
import ptBR from 'date-fns/locale/pt-BR';

export const isValidDate = (date: any) => {
    const parsedDate = parseDate(date, 'dd/MM/yyyy', new Date(), { locale: ptBR });
    const parsedDateWithHour = parseDate(date, 'dd/MM/yyyy HH:mm', new Date(), { locale: ptBR });
    if (isDateValid(parsedDate)) {
      return 'date';
    } else if (isDateValid(parsedDateWithHour)) {
      return 'datetime';
    } else {
      return false;
    }
}

export const isValidISODate = (date: any) => {
    const parsedDate = new Date(date);
    if (isDateValid(parsedDate)) {
      return true;
    } else {
      return false;
    }
}

export const convertToDate = (date: any) => {
    const parsedDate = parseDate(date, 'dd/MM/yyyy', new Date(), { locale: ptBR });
    const parsedDateWithHour = parseDate(date, 'dd/MM/yyyy HH:mm', new Date(), { locale: ptBR });
    if (isDateValid(parsedDate)) {
      return parsedDate;
    } else if (isDateValid(parsedDateWithHour)) {
      return parsedDateWithHour;
    } else {
      return new Date();
    }
}
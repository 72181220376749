import React, { ReactElement, useState } from "react";
import TabTitle from "../TabTitle";
import {
  Container,
  Lista
} from './styles';
;

interface Props {
  children: ReactElement[];
  onChangeTab?(tab: number): void;
}

const Tabs: React.FC<Props> = ({ children, onChangeTab }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeSelectedTab = ( tab: number ) => {
    setSelectedTab(tab);

    if (onChangeTab) {
      onChangeTab(tab);
    }
  }

  return (
    <Container>
      <Lista>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={handleChangeSelectedTab}
            isSelected={(index === selectedTab)}
          />
        ))}
      </Lista>
      {children[selectedTab]}
    </Container>
  )
};

export default Tabs;
import styled from "styled-components";

interface IWidthLarge{
  toll?: string; 
  wide?: string;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  .buttonPrint{
    color: var(--primary-complementary-text-color);
    background-color: var(--primary);
    border-radius: 30px;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 4px;
    margin-left: auto;
    padding: 10px;
  }
`

export const Container = styled.div<IWidthLarge>`
  background-color: var(--white);
  height: auto;
  padding-top: 6px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  .info-column{
    border: 1px solid var(--muted);
    border-radius: 10px;  
    padding: 2px;
    margin-bottom: 2px;
  }

  hr{
    background-color: var(--muted);
    height: 1px;
    width: 100%;
  }

  label{
    font-size: 10px;
  }

  .content-end{
    justify-content: flex-end;
  }

  .align-center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .height{
    height: 100px;
  }
`

export const Row = styled.div<IWidthLarge>`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  width: ${p => p.wide };
  
    .text-center{
      display: flex;
      justify-content: center;
    }

`

export const Title = styled.h2`
  font-size: 18px;
  text-align: center;
`

export const Autenticate = styled.div<IWidthLarge>`
  border: 1px solid var(--muted);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex: 1;
  width: ${p => p.wide };

  label{
    text-decoration: underline;
  }
`

export const Column = styled.div<IWidthLarge>`
  display: flex;
  flex-direction: column;
  margin: 0 2px 2px 0;
  height: ${p => p.toll ? p.toll : ''};
  width: ${p => p.wide };

  span{
    font-size: 8px;
  }

  .flex{
    flex: 1;
  }
`

export const Photo = styled.img<IWidthLarge>`
  margin-right: 2px;
  height: 150px;
  width: ${p => p.wide};
`
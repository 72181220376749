import styled from "styled-components";

export const ContainerResume = styled.div`
    padding: 8px 0px;
`;

export const Title = styled.div`
    margin-bottom: 8px;
`;

export const Row = styled.div`
    label {
        font-family: monospace;
    }
`;
import styled from 'styled-components';

export const TableHeadGroup = styled.thead`
`;

export const TableHeadElement = styled.tr`
`;

export const TableHeadCell = styled.th<any>`

  width: ${({isLookup}) => isLookup ? 300 : 100}px;
  white-space: nowrap;
  color: var(--primary-text-color);
  letter-spacing: 1.5px;
  font-weight: 200;
  font-size: 12px;
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid var(--opacity-background);
  transition: .2s;

  &:hover {
    background-color: var(--opacity-background);
    cursor: grab;
  }
  
`;
import styled from 'styled-components';
import {ILeftSidebarProps, IListLinkProps} from '.';

import ButtonLayout from '../Button';

export const Container = styled.div<ILeftSidebarProps>`
  min-width: ${props => props.isExpanded ? '17.5%' : '50px'};
  height: 100%;
  padding-top: 24px;
  margin-left: ${props => props.isExpanded ? '-4px' : '8px'};

  transition: .2s;

  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isExpanded ? 'flex-start' : 'center'};
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    ${props => props.isExpanded ? '& {position: absolute; z-index: 99999999; background-color: var(--primary); color: var(--white);}' : ''}
  }

  @media (max-width: 559px) {
    ${props => props.isExpanded ? '& {display: block}' : '& {display: none}'}
  }
`;

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
`;

export const ListLink = styled.button<IListLinkProps>`
  display: inline-flex;
  align-items: center;
  height: ${props => props.expanded === "false" ? '40px' : '28px'};
  ${props => props.expanded === "false" ? 'width: 40px;' : ''}
  ${props => props.expanded === "false" ? 'justify-content: center;' : ''}
  ${props => props.expanded !== "false" ? 'padding: 0px 16px 0px 12px;' : ''}
  white-space: nowrap;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  transition: .2s;
  margin-bottom: 12px;
  border-radius: 24px;
  cursor: pointer;
  margin-left: ${props => props.expanded === "false" ? '0' : props.level ? props.level * 20 : '8'}px;
  margin-right: ${props => props.expanded === "false" ? '0' : props.level ? props.level * 40 : '48'}px;
  background-color: ${props => props.selected ? 'var(--primary)' : 'transparent'};
  color: ${props => props.selected ? 'var(--white)' : 'var(--primary-text-color)'};

  & svg {
    margin-right: ${props => props.expanded === "false" ? '0px' : '8px'};
    width: ${props => props.expanded === "false" ? '24px' : '18px'};
    height: ${props => props.expanded === "false" ? '24px' : '18px'};
  }

  & > .arrow-down {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }

  @media (max-width: 1024px) {
    ${props => props.expanded === "false"
    ? props.selected
    ? '&{background-color: var(--primary); color: var(--white);}'
    : '&{background-color: transparent; color: var(--primary);}' 
    : '&{background-color: transparent; color: var(--white);}'}
    ${props => 
      props.expanded === "false" 
      ?  props.selected 
      ? '&{color: var(--white);}' 
      : '&{color: var(--primary-text-color)}'
      : '&:hover{background-color: var(--white); color: var(--primary);}'}
  }
`;

export const Button = styled(ButtonLayout)`
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 64px;

  @media (max-width: 1024px) {
    color: var(--white);
  }
`;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getReport } from "../../services/pedidos";
import { formatDate, formatMoney } from "../../utils/format";
import { PaginateContainer } from "../ListTable/styles";
import { Container, DivItems, DivRow, Row, Title } from "./style";

interface IReport {
  acrescimo?: string;
  aprovacao?: string;
  baixaAutom?: boolean;
  chcriacao?: number;
  serie?: string;
  desconto?: string;
  emissao?: string;
  frete?: string;
  ipi?: string;
  criadoEm?: string;
  observacao?: string;
  seguro?: string;
  quantidade?: number;
  total?: string;
  valor?: string;
  unitário?: string;
  chpedbaixa?: number;
  Aprovador?: IAprovador;
  Estabelecimento?: IEstab;
  LocEscritu?: ILocalEsc;
  Pessoa?: IPessoa;
  Representante?: IRepresent;
  TipoDoc?: ITipoDoc;
}

interface IAprovador {
  nome?: string;
}

interface IEstab {
  nome?: string;
}

interface ILocalEsc {
  nome?: string;
}

interface IPessoa {
  nome?: string;
  email?: string;
  celular?: string;
  endereco?: string;
  numeroEndereco?: string;
  bairro?: string;
  Cidade?: ICidade;
  UF?: IUF;
  cpfCnpj?: string;
  Classe?: IClass;
}

interface ICidade {
  nome?: string;
  codigo?: string;
}

interface IUF {
  nome?: string;
}

interface IRepresent {
  nome?: string;
}

interface IClass {
  nome?: string;
}

interface ITipoDoc {
  nome?: string;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

const OrderReport: React.FC = () => {

  const date = new Date()
  const [report, setReport] = useState<Array<IReport>>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [getResponse, setGetResponse] = useState<IResponse | null>(null);

  useEffect(() => {
    getOrderReport()
  }, [currentPage])

  async function getOrderReport(){
    try {
      const result = await getReport(currentPage)
      if(result){
        setReport(result.docs)
        setGetResponse(result)
      }
    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  }

  return(
    <Container>
      <DivRow>
        <Row>
          <Title>Relatório pedidos de saída</Title>
          <label>{formatDate(date)}</label>
        </Row>
      </DivRow>
      <DivItems>
        <table>
          <tr>
            <th>Chave de Criação</th>
            <th>Documento</th>
            <th>Série</th>
            <th>Movimentação</th>
            <th>Hora</th>
            {/* <th>Aprovação</th> */}
            {/* <th>Aprovador</th> */}
            <th>Estabelecimento</th>
            <th>Local Escrituração</th>
            <th>Pessoa</th>
            <th>UF</th>
            <th>Representante</th>
            <th>Quantidade</th>
            <th>IPI</th>
            <th>Seguro</th>
            <th>Frete</th>
            <th>Acréscimo</th>
            <th>Desconto</th>
            <th>Unitário</th>
            <th>Valor Total</th>
          </tr>
          {report.length ? report.map((item: any) => (
            <tr>
              <td>{item?.chcriacao}</td>
              <td>{item?.TipoDoc?.nome} {item?.chpedbaixa}</td>
              <td>{item?.serie}</td>
              <td>{item.criadoEm ? formatDate(item.criadoEm) : ''}</td>
              <td>{item?.criadoEm.slice('11', '19')}</td>
              {/* <td>{item.aprovacao ? formatDate(item.aprovacao) : ''}</td> */}
              {/* <td>{item?.Aprovador?.nome}</td> */}
              <td>{item?.Estabelecimento?.nome}</td>
              <td>{item?.LocEscritu?.nome}</td>
              <td>{item?.Pessoa?.nome}</td>
              <td>{item?.Pessoa?.UF?.codigo}</td>
              <td>{item?.Representante?.nome}</td>
              <td>{item?.quantidade}</td>
              <td className="right">{item.ipi ? formatMoney(item.ipi) : ''}</td>
              <td className="right">{item.seguro ? formatMoney(item.seguro) : ''}</td>
              <td className="right">{item.frete ? formatMoney(item.frete) : ''}</td>
              <td className="right">{item.acrescimo ? formatMoney(item.acrescimo) : ''}</td>
              <td className="right">{item.desconto ? formatMoney(item.desconto) : ''}</td>
              <td className="right">{item?.unitario ? formatMoney(item.unitario) : ''}</td>
              <td className="right">{item?.total ? formatMoney(item.total) : ''}</td>
            </tr>
          )) : null}
        </table>
      </DivItems>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={getResponse ? getResponse.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName='active-page-item'
          />
        </PaginateContainer>
    </Container>
  )
}

export default OrderReport;
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import providers from '../services/config';
import { useAuth } from '../contexts/auth';
import Toast from '../components/Toast';
import { AxiosRequestHeaders } from 'axios';

interface IRequest {
  endpoint: string;
}

export default function useFetch({
  endpoint,
}: IRequest) {
  const {user} = useAuth();
  const [loading,setLoading] = useState(false)

  async function get(params?: any, headers?: AxiosRequestHeaders) {
    try {
      setLoading(true);
      const response = await providers.get(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${user?.token}`,
          ...headers,
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        Toast.show(err.response.data.message, 'error');
      } else {
        Toast.show("Ocorreu um erro ao tentar listar este item", "error");
      }
      console.log(err);
      return {error: true}
    } finally {
      setLoading(false);
    }
  };

  async function post(body?: any, headers?: AxiosRequestHeaders) {
    try {
      setLoading(true);
      const response = await providers.post(endpoint, body, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          ...headers,
        }
      });
      Toast.show('Inserido com sucesso', 'success');
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        Toast.show(err.response.data.message, 'error');
      } else {
        Toast.show("Ocorreu um erro ao tentar criar este item", "error");
      }
      console.log(err);
      return {error: true}
    } finally {
      setLoading(false);
    }
  };

  async function put(id: string, body?: any, headers?: AxiosRequestHeaders) {
    try {
      setLoading(true);
      const response = await providers.put(`${endpoint}${id}/`, body, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          ...headers,
        }
      });
      Toast.show('Atualizado com sucesso', 'success');
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        Toast.show(err.response.data.message, 'error');
      } else {
        Toast.show("Ocorreu um erro ao tentar atualizar este item", "error");
      }
      console.log(err);
      return {error: true}
    } finally {
      setLoading(false);
    }
  };

  async function remove(id: string, headers?: AxiosRequestHeaders) {
    try {
      setLoading(true);
      const response = await providers.delete(`${endpoint}${id}/`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          ...headers,
        }
      });
      Toast.show('Excluído com sucesso', 'success');
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        Toast.show(err.response.data.message, 'error');
      } else {
        Toast.show("Ocorreu um erro ao tentar remover este item", "error");
      }
      console.log(err);
      return {error: true}
    } finally {
      setLoading(false);
    }
  };

  return { loading, get, post, put, remove }
}
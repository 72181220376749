import React from 'react';
import { Conteudo } from './styles';
type Props = {
  title: string;
}

const Tab: React.FC<Props> = ({ children }) => {
  return <Conteudo>{children}</Conteudo>;
}

export default Tab;
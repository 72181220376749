/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Wrapper,
  Title,
  Content,
  HeaderContent,
  ActionsContainer,
  Button,
} from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import addDays from "date-fns/addDays";
import TextInput from "../../components/TextInput";
import LookupInput from "../../components/LookupInput";
import DateInput from "../../components/DateInput";
import Table from "../../components/Table";
import Toast from "../../components/Toast";
import CardTable from "../../components/CardTable";
import {
  calcularPedido,
  criarPedido,
  getChavePedido,
  checkChavesParaBaixa,
  updatePedido,
  checkIfPedidoHasBaixa,
  getTitulosCondicao,
  checkCHacessoNF,
  deleteOrders,
  regraClasse,
  regraNucleo,
  regraCfop,
} from "../../services/pedidos";
import { convertToDate } from "../../utils/date";
import { readXMLText, XMLFileToString } from "../../utils/xml";
import { detalhesData, itemCols, tituloCols } from "./fixedData";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { data as importStructureData } from "../../navigation/importStructure";

import negativeClasses from "../../utils/negativeClass";
import { useAuth } from "../../contexts/auth";
import { createProvider, getProviderByCNPJ } from "../../services/providers";
import { createResource, getResourcesByFornecedorAndFirstPageOnly, getResourcesByNameAndFirstPageOnly } from "../../services/resource";
import {
  getMunicipio,
  getUF,
  searchUFCitiesByCode,
  getUnidadeMedida,
  getNCM,
  getProcedencia,
  getTipoDeFrete,
  getTipoDeDocumento,
} from "../../services/tabela";
import { useModal } from "../../contexts/modal";
import TextProcess from "../../components/TextProcess";
import { MaskInput } from "../../components/TextInput/styles";
import toast from "react-hot-toast";
import { formatMoney } from "../../utils/format";
import { differenceInDays, isWeekend, nextMonday } from "date-fns/esm";
import { getCarteirasCobranca } from "../../services/entidades";
import { checarEstoque } from "../../services/movdepos";

interface iSalesOrder {
  baixaAutom?: boolean;
}

type TPag = "01" | "02" | "03" | "04" | "05" | "10" | "11" | "12" | "13" | "14" | "15" | "90" | "99";

const TIPOPAGAMENTO = {
  "01": "Dinheiro", 
  "02": "Cheque", 
  "03": "Cartão de Crédito", 
  "04": "Cartão de Débito", 
  "05": "Crédito Loja", 
  "10": "Vale Alimentação", 
  "11": "Vale Refeição", 
  "12": "Vale Presente", 
  "13": "Vale Combustível", 
  "14": "Duplicata Mercantil", 
  "15": "Boleto", 
  "90": "Sem Pagamento", 
  "99": "Outros",
};

const SalesOrder: React.FC<iSalesOrder> = ({ baixaAutom }) => {
  const history = useHistory();
  const location: any = useLocation();
  const { openModal, closeModal } = useModal();
  const xml = location.state.xml ? true : false;
  const baixaAutomatica = baixaAutom
    ? baixaAutom
    : location.state &&
      location.state.baixaAutomatica &&
      location.state.baixaAutomatica === true;
  const baixaNaoAutomatica =
    location.state && location.state.baixa && location.state.baixa !== null;
  const baixaPedido =
    location.state && location.state.baixa ? location.state.baixa : null;
  const itemEdicao =
    location.state && location.state.item ? location.state.item : null;
  const { user } = useAuth();
  const novoPedido = location.state && location.state.item ? false : true;
  const [aprovador, setAprovador] = useState<any>(
    user ? (user.nome ? user.nome : user.email ? user.email : user.id) : null
  );
  const [payload, setPayload] = useState<any>({
    emissao: location.state?.xml || itemEdicao ? "" : new Date().toISOString(),
    observacao: null,
    frete: null,
    seguro: null,
    acrescimo: null,
    desconto: null,
    idAcessorio1: null,
  });
  const [chacessonf, setChacessonf] = useState("");
  const [subtitulo, setSubtitulo] = useState(xml ? 'Pedido por Importação do XML' : baixaNaoAutomatica ? 'Baixa de Pedido' : baixaAutomatica ? 'Pedido NCBA' : 'Pedido');
  const [xmlinf, setXmlinf] = useState("");
  const [faturamento, setFaturamento] = useState({
    movimentacao: new Date().toISOString(),
    observacaoFiscal: "",
    numero: "",
    serie: "",
  });
  const [loadings, setLoadings] = useState({
    calcular: false,
    salvar: false,
  });
  const [titulosFixedValue, setTitulosFixedValue] = useState<any>({});
  const [titulosFixedValues, setTitulosFixedValues] = useState<any>({});
  const [comAprovador, setComAprovador] = useState(false);
  const [chaveCriacao, setChaveCriacao] = useState(0);
  const [observacaoToShow, setObservacaoToShow] = useState("");
  const [lookups, setLookups] = useState<any>({});
  const [initialLookups, setInitialLookups] = useState<any>({
    Representante: null,
    LocEscrituracao: null,
    Estabelecimento: null,
    Pessoa: null,
  });
  const [changedCondicaoPagamento, setChangedCondicaoPagamento] =
    useState(false);
  const [initialItems, setInitialItems] = useState<any>(null);
  const [initialTitulos, setInitialTitulos] = useState<any>(null);
  const [items, setItems] = useState<any>(null);
  const [titulos, setTitulos] = useState<any>(null);
  const [colunasTitulos, setColunasTitulos] = useState<any>(tituloCols());
  const [colunasItens, setColunasItens] = useState<any>(itemCols(xml));
  const [totais, setTotais] = useState<any>({
    acrescimoItemTotal: 0,
    descontoItemTotal: 0,
    ipiTotal: 0,
    valorTotal: 0,
    valorCorrigidoTotal: 0,
    vrItemTotal: 0,
    items: [],
    titulos: [],
    condicoesPagamento: [],
  });

  useEffect(() => {
    if (lookups.CondicaoPagamento && lookups.CondicaoPagamento.value) {
      if (!changedCondicaoPagamento) {
        setChangedCondicaoPagamento(true);
      }
    }
  }, [lookups]);

  useEffect(() => {
    if (!itemEdicao && user && !location.state?.xml) {
      if (user.Estab) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            Estabelecimento: { byId: user.Estab.id },
          };
        });
      }
      if (user.LocEscrit) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            LocEscrituracao: { byId: user.LocEscrit.id },
          };
        });
      }
    }
  }, [user, baixaNaoAutomatica, baixaNaoAutomatica, itemEdicao]);

  useEffect(() => {
    if (baixaNaoAutomatica || baixaAutomatica) return;
    const timeOutId = setTimeout(
      () => setObservacaoToShow(payload.observacao),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [payload.observacao]);

  useEffect(() => {
    if (baixaPedido && typeof baixaPedido !== "boolean") {
      loadInitialState(baixaPedido, true);
    }
  }, [baixaPedido]);

  useEffect(() => {
    if (itemEdicao) {
      loadInitialState(itemEdicao);
    }
  }, [itemEdicao]);

  useEffect(() => {
    if (novoPedido) {
      getChaveCriacao();
      //chama função que verifica se precisa criar pedido do XML
      handleReadXML();
    }
  }, [novoPedido]);

  useEffect(() => {
    handleAprovacao();
  }, [comAprovador]);

  useEffect(() => {
    if (totais.condicoesPagamento.length > 0 && changedCondicaoPagamento) {
      loadCondicoesCalc(totais.condicoesPagamento);
    }
  }, [totais]);

  function loadCondicoesCalc(condicoes: any[]) {
    const initialTitulosData: any[] = [];
    for (const titulo of condicoes) {
      let tituloInitialState: any = {
        Carteira: titulo.Carteira ? titulo.Carteira : null,
        TipoDocumento: titulo.TipoDocumento ? titulo.TipoDocumento : null,
        juros: titulo.juros ? parseFloat(titulo.juros) : null,
        juroPercm: titulo.juroPercm ? titulo.juroPercm : null,
        multa: titulo.multa ? parseFloat(titulo.multa) : null,
        multaPerc: titulo.multaPerc ? titulo.multaPerc : null,
        acrescimo: titulo.acrescimo ? parseFloat(titulo.acrescimo) : null,
        desconto: titulo.desconto ? parseFloat(titulo.desconto) : null,
        prazo: titulo.hasOwnProperty("prazo") ? parseInt(titulo.prazo) : 0,
      };
      initialTitulosData.push(tituloInitialState);
    }
    setInitialTitulos((oldData: any) => {
      return initialTitulosData;
    });
    setChangedCondicaoPagamento(false);
  }

  function loadInitialState(pedido: any, baixa: boolean = false) {
    if (!baixa) {
      setChaveCriacao(pedido.chcriacao);
    }
    const obs = pedido.items && pedido.items.length > 0 && pedido.items[0].observacao ?
    pedido.items[0].observacao : '';
    const idAprov = pedido.items && pedido.items.length > 0 && pedido.items[0].idAprovador ?
    pedido.items[0].idAprovador : '';
    let tipoPed = pedido.items[0] && pedido.items[0].xmlinf ? 'Pedido por Importação do XML' : pedido.items[0] && pedido.items[0].baixaAutom ? 'Pedido NCBA' : pedido.items[0] && pedido.items[0].chpedbaixa !== null ? 'Baixa de Pedido' : 'Pedido';
    if (pedido.items[0] && pedido.items[0].xmlinf) {
      setXmlinf(pedido.items[0].xmlinf);
    }
    setSubtitulo(tipoPed);
    const chacessonf = pedido.items && pedido.items.length > 0 && pedido.items[0].chacessonf ?
    pedido.items[0].chacessonf : '';
    setPayload({
      emissao: new Date(pedido.emissao).toISOString(),
      observacao: obs,
      frete: pedido.frete ? pedido.frete : null,
      seguro: pedido.seguro ? pedido.seguro : null,
      acrescimo: pedido.acrescimo ? pedido.acrescimo : null,
      desconto: pedido.desconto ? pedido.desconto : null,
      idAcessorio1: pedido.idAcessorio1 ? pedido.idAcessorio1 : null,
    });
    setChacessonf(chacessonf);
    setInitialLookups({
      Representante: pedido.Representante
        ? { byId: pedido.Representante.id }
        : null,
      LocEscrituracao: pedido.LocEscritu
        ? { byId: pedido.LocEscritu.id }
        : null,
      Estabelecimento: pedido.Estabelecimento
        ? { byId: pedido.Estabelecimento.id }
        : null,
      Pessoa: pedido.Pessoa ? { byId: pedido.Pessoa.id } : null,
    });
    const tempInitialLookups: any = {
      Representante: pedido.Representante
        ? { byId: pedido.Representante.id }
        : null,
      LocEscrituracao: pedido.LocEscritu
        ? { byId: pedido.LocEscritu.id }
        : null,
      Estabelecimento: pedido.Estabelecimento
        ? { byId: pedido.Estabelecimento.id }
        : null,
      Pessoa: pedido.Pessoa ? { byId: pedido.Pessoa.id } : null,
    };

    const tempFaturamento: any = {};
    const tempTotais: any = {
      acrescimoItemTotal: 0,
      descontoItemTotal: 0,
      ipiTotal: 0,
      valorTotal: 0,
      valorCorrigidoTotal: 0,
      vrItemTotal: 0,
      items: [],
      titulos: [],
      condicoesPagamento: [],
    };
    const initialItemsData = [];
    const initialTitulosData = [];
    for (const item of pedido.items) {
      tempTotais.vrItemTotal += item.valor;
      tempTotais.valorTotal += item.total;
      tempTotais.valorCorrigido += item.valorCorrigido;
      tempTotais.items.push({
        vrItem: item.valor,
        valorTotal: item.total,
      });
      tempInitialLookups.TipoDoc = item.TipoDoc
        ? { byId: item.TipoDoc.id }
        : null;
      tempInitialLookups.TipoDeFrete = item.TipoDeFrete
        ? { byId: item.TipoDeFrete.id }
        : null;
      tempInitialLookups.Transportadora = item.Transportadora
        ? { byId: item.Transportadora.id }
        : null;
      tempInitialLookups.CondicaoPagamento = item.CondicaoPag
        ? { byId: item.CondicaoPag.id }
        : null;
      tempFaturamento.movimentacao = new Date(item.movimentacao).toISOString();
      tempFaturamento.serie = item.serie;
      tempFaturamento.observacaoFiscal = item.observacaoFiscal;
      tempFaturamento.numero = item.numero;
      let itemInitialState: any = {
        Classe: item.Classe ? item.Classe : null,
        Recurso: item.Recurso ? item.Recurso : null,
        Nucleo: item.Nucleo ? item.Nucleo : null,
        CFOP: item.CFOP ? item.CFOP : null,
        Lote: item.Lote ? item.Lote : null,
        quantidade: item.quantidade ? parseInt(item.quantidade) : null,
        unitario: item.unitario ? parseFloat(item.unitario) : null,
        acrescimoItem: item.acrescimoItem
          ? parseFloat(item.acrescimoItem)
          : null,
        descontoItem: item.descontoItem ? parseFloat(item.descontoItem) : null,
        ipi: item.ipi ? parseFloat(item.ipi) : null,
      };
      if (baixa) {
        itemInitialState = {
          ...itemInitialState,
          chpedbaixa: item.chpedbaixa ? item.chpedbaixa : null,
        };
      } else {
        itemInitialState = {
          ...itemInitialState,
          chave: item.chave ? item.chave : null,
        };
      }
      initialItemsData.push(itemInitialState);
    }

    setInitialLookups(tempInitialLookups);
    setFaturamento(tempFaturamento);

    for (const titulo of pedido.titulos) {
      let prazo = 0;
      if (titulo.correcao) {
        prazo = differenceInCalendarDays(
          new Date(titulo.correcao),
          new Date(pedido.emissao)
        );
      } else {
        prazo = differenceInCalendarDays(
          new Date(titulo.vencimento),
          new Date(pedido.emissao)
        );
      }
      tempTotais.valorCorrigidoTotal += titulo.valorCorrigido;
      let tituloInitialState: any = {
        Carteira: titulo.Carteira ? titulo.Carteira : null,
        TipoDocumento: titulo.TipoDocumento ? titulo.TipoDocumento : null,
        juros: titulo.juros ? parseFloat(titulo.juros) : null,
        juroPercm: titulo.juroPercm ? titulo.juroPercm : null,
        multa: titulo.multa ? parseFloat(titulo.multa) : null,
        multaPerc: titulo.multaPerc ? titulo.multaPerc : null,
        acrescimo: titulo.acrescimo ? parseFloat(titulo.acrescimo) : null,
        desconto: titulo.desconto ? parseFloat(titulo.desconto) : null,
        correcao: titulo.correcao
          ? new Date(titulo.correcao).toISOString()
          : null,
        vencimento: titulo.vencimento
          ? new Date(titulo.vencimento).toISOString()
          : null,
        valorPrincipal: titulo.valorPrincipal,
        valorCorrigido: titulo.valorCorrigido,
        prazo,
      };

      if (!baixa) {
        tituloInitialState = {
          ...tituloInitialState,
          chave: titulo.chave ? titulo.chave : null,
        };
      }
      initialTitulosData.push(tituloInitialState);
    }
    setTotais(tempTotais);
    setInitialItems(initialItemsData);
    setInitialTitulos(initialTitulosData);

    if (idAprov && !baixa) {
      setComAprovador(true);
      setAprovador(
        idAprov
          ? idAprov.nome
            ? idAprov.nome
            : idAprov.codigo
            ? idAprov.codigo
            : "Pedido aprovado"
          : "Pedido aprovado"
      );
    }
  }

  function handleAprovacao() {
    if (comAprovador) {
      if (subtitulo !== 'Pedido por Importação do XML' && subtitulo !== 'Baixa de Pedido') {
        if (xmlinf) {
          setSubtitulo('Pedido por Importação do XML');
        } else {
          let base = subtitulo;
          if (base.includes('Não Aprovado' || base.includes('Aprovado'))) {
            base = base.replace('Não Aprovado', '').replace('Aprovado', '');
          }
          setSubtitulo(`${base} Aprovado`);
        }
      }
      setColunasItens((oldValue: any) =>
        oldValue.map((item: any) => {
          return { ...item, editavel: false };
        })
      );
      setColunasTitulos((oldValue: any) =>
        oldValue.map((item: any) => {
          return { ...item, editavel: false };
        })
      );
    } else {
      if (subtitulo !== 'Pedido por Importação do XML' && subtitulo !== 'Baixa de Pedido') {
        if (xmlinf) {
          setSubtitulo('Pedido por Importação do XML');
        } else {
          let base = subtitulo;
          if (base.includes('Não Aprovado' || base.includes('Aprovado'))) {
            base = base.replace('Não Aprovado', '').replace('Aprovado', '');
          }
          setSubtitulo(`${base} Não Aprovado`);
        }
      }
      setColunasItens(itemCols(xml));
      setColunasTitulos(tituloCols());
    }
  }

  function handleOpenModal(message: string) {
    openModal({
      title: "Processando XML...",
      children: () => <TextProcess message={message} />,
      onSubmit: () => {},
      onCancel: () => {},
      closeOnAction: false,
    });
  }

  async function handleCreateNewProvider(providerNode: any) {
    const forPayload: any = {
      idClasse: negativeClasses.PROVIDER,
    };
    const extractionKeys = Object.keys(importStructureData.fornecedores.keys);
    for (let j = 0; j < extractionKeys.length; j++) {
      const key: string = extractionKeys[j];
      const keyData = providerNode.querySelector(key);

      if (keyData) {
        let value = keyData.innerHTML;
        if (key === "CNPJ") {
          value = keyData.innerHTML;
          forPayload.codigo = value;
          forPayload.cpfCnpj = value;
        } else if (key === "CEP") {
          value = keyData.innerHTML;
          forPayload.cep = value;
        } else if (key === "UF") {
          value = await getUF(keyData.innerHTML);
          forPayload.idUF = value;
        } else if (key === "xMun") {
          value = await getMunicipio(keyData.innerHTML, forPayload?.idUF);

          if (value === null) {
            value = await searchUFCitiesByCode(
              providerNode.querySelector("cMun").innerHTML,
              forPayload?.idUF
            );
          }
          forPayload.idCidade = value;
        }

        switch (key) {
          case "IE":
            forPayload.inscricaoEstadual = keyData.innerHTML;
            break;
          case "xNome":
            forPayload.nome = keyData.innerHTML;
            break;
          case "xFant":
            forPayload.nomeFantasia = keyData.innerHTML;
            break;
          case "xBairro":
            forPayload.bairro = keyData.innerHTML;
            break;
          case "xLgr":
            forPayload.endereco = keyData.innerHTML;
            break;
          case "nro":
            forPayload.numeroEndereco = keyData.innerHTML;
            break;
          case "fone":
            forPayload.telefone = keyData.innerHTML;
            break;
          default:
            break;
        }
      }
    }

    try {
      const resFor = await createProvider(forPayload);
      return resFor;
    } catch (error: any) {
      Toast.show(error, "error");
    }
  }

  async function handleCreateNewTransport(transNode: any) {
    const transportData: any = {
      idClasse: negativeClasses.TRANSPORTADORA,
    };
    const extFields: any = {
      CNPJ: {
        field: "cpfCnpj",
        label: "CPF/CNPJ.....",
      },
      xNome: {
        field: "nome",
        label: "Nome.........",
      },
      IE: {
        field: "inscricaoEstadual",
        label: "Insc Estadual",
      },
      xEnder: {
        field: "endereco",
        label: "Endereço.....",
      },
      UF: {
        field: "idUF",
        label: "UF...........",
      },
      xMun: {
        field: "idCidade",
        label: "Município....",
      },
    };
    const extractionKeys = Object.keys(extFields);
    for (let j = 0; j < extractionKeys.length; j++) {
      const key = extractionKeys[j];
      const keyData = transNode.querySelector(key);

      if (keyData) {
        let value = keyData.innerHTML;
        if (key === "CNPJ") {
          transportData.codigo = keyData.innerHTML;
        } else if (key === "UF") {
          value = await getUF(keyData.innerHTML);
        } else if (key === "xMun") {
          value = await getMunicipio(keyData.innerHTML, transportData?.idUF);

          if (value === null) {
            value = await searchUFCitiesByCode(
              transNode.querySelector("cMun").innerHTML,
              transportData?.idUF
            );
          }
        }
        transportData[extFields[key].field] = value;
      }
    }
    try {
      const resTrans = await createProvider(transportData);
      return resTrans;
    } catch (error: any) {
      Toast.show(error, "error");
    }
  }

  async function handleCreateNewProduct(prodNode: any, prodProvider: any) {
    const prodPayload: any = {
      idClasse: negativeClasses.PRODUTO,
    };
    const extractionKeys = Object.keys(importStructureData.produtos.keys);
    const prod = prodNode.querySelector("prod");
    const origData =
      prodNode.querySelector("orig") ?? prodNode.querySelector("Orig");

    for (let j = 0; j < extractionKeys.length; j++) {
      const key = extractionKeys[j];
      const keyData = prod.querySelector(key);

      if (keyData) {
        let value = keyData.innerHTML;
        if (key === "uTrib") {
          value = await getUnidadeMedida(keyData.innerHTML);
          prodPayload.idUnidadeMedida = value;
        } else if (key === "NCM") {
          value = await getNCM(keyData.innerHTML);
          prodPayload.idNCM = value;
        }

        switch (key) {
          case "cProd":
            prodPayload.codigoFornecedor = keyData.innerHTML;
            break;
          case "cEAN":
            prodPayload.ean = keyData.innerHTML;
            break;
          case "xProd":
            prodPayload.nome = keyData.innerHTML;
            break;
          default:
            break;
        }
      } else if (key === "uTrib") {
        const keyData = prod.querySelector("uCom");
        let value = keyData.innerHTML;
        if (keyData) {
          value = await getUnidadeMedida(keyData.innerHTML);
          prodPayload.idUnidadeMedida = value;
        }
      }
    }

    if (origData) {
      let value = origData.innerHTML;
      value = await getProcedencia(value);
      if (value) {
        prodPayload.idProcedencia = value;
      }
    }

    if (Object.keys(prodPayload).length > 0) {
      prodPayload.idFornecedor = prodProvider.value;
    }

    try {
      const resProd = await createResource(prodPayload);
      return resProd;
    } catch (error: any) {
      Toast.show(error, "error");
    }
  }

  async function handleReadXML() {
    if (location.state?.xml) {
      const { xml } = location.state;
      const data: any = await readXMLText(xml);
      const xmlStr = await XMLFileToString(xml);

      const providerNode = data.querySelector("emit");
      const destNode = data.querySelector("dest");
      const transportaNode = data.querySelector("transporta");
      const serieNode = data.querySelector("serie");
      const nNFNode = data.querySelector("nNF");
      const detNodes = data.querySelectorAll("det");
      const totalNode = data.querySelector("total");
      const vFrete = data.querySelector("vFrete");
      const chNFeNode = data.querySelector("chNFe");
      const modFreteNode = data.querySelector("modFrete");
      const dhEmiNode = data.querySelector("dhEmi");
      const modNode = data.querySelector("mod");
      const cobrNode = data.querySelector("cobr");
      let prods: any[] = [];
      let _payloadFor = {};
      let baseInfo: any = {};
      const tempInitialLookups: any = {
        TipoDoc: { byId: '61e1d5056bdbde5e687a64cf' },
      };
      const tempFaturamento: any = {};
      let emiss = null;

      //adicionar numero da chave de acesso.
      if (chNFeNode) {
        const nfValue = chNFeNode.innerHTML;
        try {
          const resNF = await checkCHacessoNF(nfValue);
          //verifica se NF já foi importada
          if (resNF.chacessonfInserido) {
            Toast.show("Esse Documento XML já foi importado", "error");
            return;
          }
        } catch (error: any) {
          Toast.show(error, "error");
        }
        setChacessonf(nfValue);
      }

      //adicionar a data da emição no campo emissão
      if (dhEmiNode) {
        emiss = new Date(dhEmiNode.innerHTML);
        const value = emiss.toISOString();
        baseInfo.emissao = value;
        tempFaturamento.movimentacao = value;
      }

      if (destNode) {
        const cpfCnpjNode =
          destNode.querySelector("CPF") ?? destNode.querySelector("CNPJ");
        if (cpfCnpjNode) {
          const resDest = await getProviderByCNPJ(cpfCnpjNode.innerHTML);
          if (resDest && resDest.length) {
            resDest.map((item: any) => {
              if (item.Classe.id === negativeClasses.ESTABELECIMENTOS) {
                tempInitialLookups.Estabelecimento = { byId: item.id };
              } else if (
                item.Classe.id === negativeClasses.LOCAL_ESCRITURACAO
              ) {
                tempInitialLookups.LocEscrituracao = { byId: item.id };
              }
              return null;
            });
          } else {
            if (user && user.Estab) {
              tempInitialLookups.Estabelecimento = { byId: user.Estab.id };
            }
            if (user && user.LocEscrit) {
              tempInitialLookups.LocEscrituracao = { byId: user.LocEscrit.id };
            }
          }
        }
      }
      //processo de pega ou criar um novo fornecedor
      if (providerNode) {
        const cnpj = providerNode.querySelector("CNPJ");
        if (cnpj) {
          handleOpenModal("Importando Fornecedor...");
          let _providerList = await getProviderByCNPJ(cnpj.innerHTML);

          if (_providerList && _providerList.length) {
            const _provider = _providerList[0];
            _payloadFor = {
              item: { ..._provider },
              label: `${_provider.cpfCnpj} - ${_provider.nome}`,
              value: _provider.id,
            };
            tempInitialLookups.Pessoa = { byId: _provider.id };
          } else if (_providerList && _providerList.length === 0) {
            handleOpenModal("Criando Fornecedor...");
            const _provider = await handleCreateNewProvider(providerNode);
            _payloadFor = {
              item: { ..._provider },
              label: `${_provider.cpfCnpj} - ${_provider.nome}`,
              value: _provider.id,
            };
            tempInitialLookups.Pessoa = { byId: _provider.id };
          }
        }
      } else {
        return toast.error('A nota precisa ter um fornecedor');
      }
      const treatedNNFNode = `${'0'.repeat(9 - nNFNode.innerHTML.length)}${nNFNode.innerHTML}`;
      tempFaturamento.numero = treatedNNFNode;
      tempFaturamento.serie = serieNode.innerHTML;
      setFaturamento(tempFaturamento);

      if (totalNode) {
        const vDescNode = totalNode.querySelector("vDesc");
        if (vDescNode) {
          const _desc = parseFloat(vDescNode.innerHTML);
          baseInfo.desconto = _desc;
        }
      }
      if (vFrete) {
        const _frete = parseFloat(vFrete.innerHTML);
        baseInfo.frete = _frete;
      }
      setPayload((oldData: any) => {
        return {
          ...oldData,
          ...baseInfo,
        };
      });

      //processo de pega ou criar uma transportadora

      if (modFreteNode) {
        handleOpenModal("Importando Tipo de Frete...");
        const resTipoFrete = await getTipoDeFrete(modFreteNode.innerHTML);

        if (resTipoFrete && resTipoFrete.length) {
          const { id } = resTipoFrete[0];
          tempInitialLookups.TipoDeFrete = { byId: id };
          // handleChangeLookup("TipoDeFrete", _payloadTipoFrete);
        }
      }

      if (transportaNode) {
        const transCNPJNode = transportaNode.querySelector("CNPJ");
        if (transCNPJNode && transCNPJNode.innerHTML) {
          const transCNPJ = transCNPJNode.innerHTML;
          handleOpenModal("Importando Transportadora...");
          const resTrans = await getProviderByCNPJ(transCNPJ);
          if (resTrans && resTrans.length) {
            const _transport = resTrans[0];
            tempInitialLookups.Transportadora = { byId: _transport.id };
            // handleChangeLookup("Transportadora", _payloadTrans);
          } else if (resTrans && resTrans.length === 0) {
            handleOpenModal("Criando Transportadora...");
            const _transport = await handleCreateNewTransport(transportaNode);
            tempInitialLookups.Transportadora = { byId: _transport.id };
            // handleChangeLookup("Transportadora", _payloadTrans);
          }
        }
      }

      //processo de pega ou criar produtos
      for (let d = 0; d < detNodes.length; d++) {
        const currentDet = detNodes[d];
        const cProd = currentDet.querySelector("cProd")?.innerHTML;
        const xProd = currentDet.querySelector("xProd")?.innerHTML;
        const qCom = currentDet.querySelector("qCom")?.innerHTML;
        const vUnCom = currentDet.querySelector("vUnCom")?.innerHTML;
        const vProd = currentDet.querySelector("vProd")?.innerHTML;

        if (cProd) {
          handleOpenModal(`Importando Produto(${d + 1}/${detNodes.length})...`);
          let resProd = await getResourcesByFornecedorAndFirstPageOnly(tempInitialLookups.Pessoa.byId, cProd);
          if (resProd[0]) {
            const regras: any = {
              idRecurso: resProd[0].id,
            };
            const toAdd: any = {};
            if (tempInitialLookups.Pessoa) {
              regras.idPessoa = tempInitialLookups.Pessoa.byId;
            }
            if (tempInitialLookups.Estabelecimento) {
              regras.idEstabelecimento = tempInitialLookups.Estabelecimento.byId;
            }
            const classe = await regraClasse(regras);

            if (classe) {
              regras.idClasseRecurso = classe.idSugestaoClasse;
              toAdd.Classe = classe.SugestaoClasse;
            }

            const nucleo = await regraNucleo(regras);

            if (nucleo) {
              toAdd.Nucleo = nucleo.Nucleo;
            }

            const cfop = await regraCfop(regras);

            if (cfop) {
              toAdd.CFOP = cfop.CFOP;
            }
            prods.push({
              Recurso: resProd[0],
              ...toAdd,
              quantidade: parseInt(qCom),
              unitario: parseFloat(vUnCom),
              vrItem: parseFloat(vProd),
              item: d + 1,
            });
          } else {
            resProd = await getResourcesByNameAndFirstPageOnly(xProd);
            if (resProd[0]) {
              const regras: any = {
                idRecurso: resProd[0].id,
              };
              const toAdd: any = {};
              if (tempInitialLookups.Pessoa) {
                regras.idPessoa = tempInitialLookups.Pessoa.byId;
              }
              if (tempInitialLookups.Estabelecimento) {
                regras.idEstabelecimento = tempInitialLookups.Estabelecimento.byId;
              }
              const classe = await regraClasse(regras);

              if (classe) {
                regras.idClasseRecurso = classe.idSugestaoClasse;
                toAdd.Classe = classe.SugestaoClasse;
              }

              const nucleo = await regraNucleo(regras);

              if (nucleo) {
                toAdd.Nucleo = nucleo.Nucleo;
              }

              const cfop = await regraCfop(regras);

              if (cfop) {
                toAdd.CFOP = cfop.CFOP;
              }
              prods.push({
                Recurso: resProd[0],
                ...toAdd,
                quantidade: parseInt(qCom),
                unitario: parseFloat(vUnCom),
                vrItem: parseFloat(vProd),
                item: d + 1,
              });
            } else {
              handleOpenModal(`Criando Produto(${d + 1}/${detNodes.length})...`);
              const resCreatedProd = await handleCreateNewProduct(
                currentDet,
                _payloadFor
              );
              const regras: any = {
                idRecurso: resCreatedProd.id,
              };
              const toAdd: any = {};
              if (tempInitialLookups.Pessoa) {
                regras.idPessoa = tempInitialLookups.Pessoa.byId;
              }
              if (tempInitialLookups.Estabelecimento) {
                regras.idEstabelecimento = tempInitialLookups.Estabelecimento.byId;
              }
              const classe = await regraClasse(regras);

              if (classe) {
                regras.idClasseRecurso = classe.idSugestaoClasse;
                toAdd.Classe = classe.SugestaoClasse;
              }

              const nucleo = await regraNucleo(regras);

              if (nucleo) {
                toAdd.Nucleo = nucleo.Nucleo;
              }

              const cfop = await regraCfop(regras);

              if (cfop) {
                toAdd.CFOP = cfop.CFOP;
              }

              prods.push({
                Recurso: resCreatedProd,
                ...toAdd,
                quantidade: parseInt(qCom),
                unitario: parseFloat(vUnCom),
                vrItem: parseFloat(vProd),
                item: d + 1,
              });
            }
          }
        }
      }

      setInitialLookups(tempInitialLookups);

      if (cobrNode) {
        const dupNodes = cobrNode.querySelectorAll("dup");
        const tPagNodes = data.querySelectorAll("tPag");
        if (dupNodes) {
          const tempTitulos: any[] = [];
          //processo de pega ou criar produtos
          for (let d = 0; d < dupNodes.length; d++) {
            const currentDup = dupNodes[d];
            const tPag: TPag = tPagNodes ? tPagNodes[d] ? tPagNodes[d].innerHTML : '99' : '99';
            const currentTipoPagamento = TIPOPAGAMENTO[tPag];

            const tipoDocumentos = await getTipoDeDocumento(currentTipoPagamento);
            const tipoDocumento = Array.isArray(tipoDocumentos) && tipoDocumentos.length > 0 ? tipoDocumentos[0] : null;
            const carteirasCobranca = await getCarteirasCobranca(currentTipoPagamento);
            const carteiraCobranca = Array.isArray(carteirasCobranca) && carteirasCobranca.length > 0 ? carteirasCobranca[0] : null;

            const dVenc = currentDup.querySelector("dVenc")?.innerHTML;
            const vDup = currentDup.querySelector("vDup")?.innerHTML;

            if (dVenc) {
              const vencimento = addDays(new Date(dVenc), 1);
              handleOpenModal(`Importando Título(${d + 1}/${dupNodes.length})...`);
              const baseTit: any = {
                prazo: differenceInDays(vencimento, emiss ? emiss : new Date()),
                vencimento: vencimento.toISOString(),
                correcao: isWeekend(vencimento) ? nextMonday(vencimento).toISOString() : vencimento.toISOString(),
                valorPrincipal: parseFloat(vDup),
                valorCorrigido: parseFloat(vDup),
              };

              if (tipoDocumento) {
                baseTit.TipoDocumento = tipoDocumento;
              }
              if (carteiraCobranca) {
                baseTit.Carteira = carteiraCobranca;
              }

              tempTitulos.push(baseTit);
            }
          }
          setInitialTitulos(tempTitulos);
        }
      }

      setXmlinf(xmlStr);
      setInitialItems(prods);
      closeModal();
    }
  }

  async function getChaveCriacao() {
    try {
      const result = await getChavePedido(user?.id);
      setChaveCriacao(result.chave);
    } catch (_err) {
      return Toast.show(
        "Ocorreu um erro ao tentar buscar a chave de criação",
        "error"
      );
    }
  }

  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return { ...prevState, [field]: options };
    });
  }

  function handleChangePayload(field: string, value: any) {
    return setPayload((prevState: any) => {
      return { ...prevState, [field]: value };
    });
  }

  function handleChangeFaturamentoPayload(field: string, value: any) {
    return setFaturamento((prevState: any) => {
      return { ...prevState, [field]: value };
    });
  }

  function validatePayload(): boolean {
    if (!payload.emissao) {
      Toast.show("Insira a data de emissão");
      return false;
    }

    if (baixaAutomatica || baixaNaoAutomatica) {
      if (!lookups.TipoDoc || !lookups.TipoDoc.value) {
        Toast.show("Insira o tipo de documento do faturamento");
        return false;
      }
      if (!faturamento.movimentacao) {
        Toast.show("Insira a data de movimentação do faturamento");
        return false;
      } else if (!faturamento.serie && lookups.TipoDoc.item && lookups.TipoDoc.item.codigo && lookups.TipoDoc.item.codigo.includes('NF')) {
        Toast.show('Insira a série do faturamento');
        return false;
      } else if (!faturamento.numero) {
        Toast.show("Insira o número do faturamento");
        return false;
      }
    }

    if (!lookups.LocEscrituracao || !lookups.LocEscrituracao.value) {
      Toast.show("Insira o local de escrituração");
      return false;
    } else if (!lookups.Estabelecimento || !lookups.Estabelecimento.value) {
      Toast.show("Insira o estabelecimento");
      return false;
    } else if (!lookups.Pessoa || !lookups.Pessoa.value) {
      Toast.show("Insira a pessoa");
      return false;
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.idRecurso) {
        Toast.show(`Insira o recurso do item ${i + 1}`);
        return false;
      } else if (!item.idClasse) {
        Toast.show(`Insira a classe do item ${i + 1}`);
        return false;
      } else if (!item.idNucleo) {
        Toast.show(`Insira o núcleo do item ${i + 1}`);
        return false;
      } else if (!item.quantidade) {
        Toast.show(`Insira a quantidade do item ${i + 1}`);
        return false;
      } else if (!item.unitario) {
        Toast.show(`Insira o preço do item ${i + 1}`);
        return false;
      }
    }

    return true;
  }

  function validarTitulos(): boolean {
    for (let i = 0; i < titulos.length; i++) {
      const titulo = titulos[i];

      if (!titulo.hasOwnProperty("idTipoDocumento")) {
        Toast.show(`Insira o tipo de documento do título ${i + 1}`);
        return false;
      } else if (!titulo.hasOwnProperty("vencimento")) {
        Toast.show(`Insira o vencimento do título ${i + 1}`);
        return false;
      }
    }

    return true;
  }

  function handleChangeLoading(field: string, value: boolean) {
    return setLoadings((oldValue) => {
      return { ...oldValue, [field]: value };
    });
  }

  async function handleGetCondicao() {
    try {
      const result = await getTitulosCondicao({
        condicaoPagamento: lookups.CondicaoPagamento.value,
      });
      setTotais((oldValue: any) => {
        return { ...oldValue, condicoesPagamento: result.condicoesPagamento };
      });
      return result.condicoesPagamento;
    } catch (err: any) {
      return false;
    }
  }

  async function handleCalcular(): Promise<boolean> {
    handleChangeLoading("calcular", true);

    if (validatePayload()) {
      let titles = titulos;
      if (lookups.CondicaoPagamento && lookups.CondicaoPagamento.value) {
        titles = await handleGetCondicao();
        titles = titles.map((tit: any) => {
          if (tit.hasOwnProperty('vencimento')) {
            const {vencimento, ...titData} = tit;
            return titData;
          }
          return tit;
        });
      }
      const toCalcPayload = {
        ...payload,
        items,
        titulos: titles,
      };
      try {
        const result = await calcularPedido(toCalcPayload);
        Object.keys(result).map((key: string) => {
          if (key !== "items" && key !== "titulos") {
            result[key] = parseFloat(result[key]);
          }
          return key;
        });
        result.items = result.items.map((item: any) => {
          return {
            ...item,
            valorTotal: parseFloat(item.valorTotal),
            vrItem: parseFloat(item.vrItem),
          }
        })
        result.titulos = result.titulos.map((item: any) => {
          return {
            ...item,
            valorCorrigido: parseFloat(item.valorCorrigido),
            valorPrincipal: parseFloat(item.valorPrincipal),
          }
        })
        setTotais((oldValue: any) => {
          const toUpdateTotais = { ...oldValue, ...result };
          calcTitulosFixedValues(titles, toUpdateTotais);
          calcTitulosFixedValue(titles, toUpdateTotais);
          return toUpdateTotais;
        });

        handleChangeLoading("calcular", false);
      } catch (err: any) {
        Toast.show(err.message);
        handleChangeLoading("calcular", false);
        return false;
      }
    } else {
      handleChangeLoading("calcular", false);
      return false;
    }

    handleChangeLoading("calcular", false);
    return true;
  }

  async function handleSalvar() {
    handleChangeLoading("salvar", true);
    try {
      let toSendPayload: any = {
        chacessonf: chacessonf,
        xmlinf: xmlinf,
        tipo: "pedido de venda",
        baixaAutomatica,
        baixaId: user?.id,
        idLocEscritu: lookups.LocEscrituracao.value,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idCondicaoPag: lookups.CondicaoPagamento ? lookups.CondicaoPagamento.value : null,
        idRepresentante: lookups.Representante
          ? lookups.Representante.value
          : null,
        idTipoDeFrete: lookups.TipoDeFrete ? lookups.TipoDeFrete.value : null,
        idAprovador: null,
        aprovacao: null,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        frete: payload.frete ? payload.frete : 0.0,
        seguro: payload.seguro ? payload.seguro : 0.0,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.0,
        desconto: payload.desconto ? payload.desconto : 0.0,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : "",
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (baixaAutomatica) {
            item = { ...item, chpedbaixa: item.chave };
          }
          if (totais.items && totais.items.length > 0) {
            const { vrItem = null, valorTotal = null, ...itemPedido } = item;
            const _total = totais.items[index].valorTotal;
            const _valor = totais.items[index].vrItem;
            return {
              ...itemPedido,
              total: parseFloat(_total),
              valor: parseFloat(_valor),
            };
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao, idClasse: '61cd2739ad47e0a02eab67fe', };
          return titulo;
        }),
      };

      if (comAprovador) {
        toSendPayload = {
          ...toSendPayload,
          idAprovador: user?.id,
          aprovacao: new Date(),
        };
      }

      if (baixaAutomatica) {
        const { movimentacao, ...faturamentoData } = faturamento;
        toSendPayload = {
          ...toSendPayload,
          idTipoDoc: lookups.TipoDoc.value,
          idTransportadora: lookups.Transportadora
            ? lookups.Transportadora.value
            : null,
          movimentacao: typeof movimentacao === 'string' ? convertToDate(movimentacao) : new Date(),
          ...faturamentoData,
        };

        const plCheck = items.map((it: any) => {
          return {
            chave: it.chave ? it.chave : null,
            idRecurso: it.idRecurso ? it.idRecurso : null,
            idNucleo: it.idNucleo ? it.idNucleo : null,
            idLote: it.idLote ? it.idLote : null,
            quantidade: it.quantidade ? it.quantidade : 0,
          }
        });
        const podeVender = await checarEstoque({
          items: plCheck,
          tipo: 'VENDA',
        });

        if (podeVender && Array.isArray(podeVender)) {
          for (const it of podeVender) {
            if (it.ok === false) {
              return Toast.show(
                `O item de chave ${it.chave} não pode ser vendido pois gerará estoque negativo`,
                "error"
              );
            }
          }
          await criarPedido(toSendPayload);
          handleChangeLoading("salvar", false);
          Toast.show(
            `A baixa de chave ${chaveCriacao} foi criado com sucesso.`,
            "success"
          );
          return history.push("/pedidos-de-saida");
        }
      }

      await criarPedido(toSendPayload);
      handleChangeLoading("salvar", false);
      Toast.show(
        `O pedido de chave ${chaveCriacao} foi criado com sucesso.`,
        "success"
      );
      return history.push("/pedidos-de-saida");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  }

  async function handleSalvarBaixaNaoAutomatica() {
    handleChangeLoading("salvar", true);
    try {
      const { movimentacao, ...faturamentoData } = faturamento;
      let toSendPayload: any = {
        tipo: "baixa de venda",
        baixaAutomatica: false,
        baixa: true,
        baixaId: user?.id,
        idLocEscritu: lookups.LocEscrituracao.value,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idRepresentante: lookups.Representante ? lookups.Representante.value : null,
        idTipoDoc: lookups.TipoDoc.value,
        idCondicaoPag: lookups.CondicaoPagamento ? lookups.CondicaoPagamento.value : null,
        idTransportadora: lookups.Transportadora
          ? lookups.Transportadora.value
          : null,
        ...faturamentoData,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        movimentacao: convertToDate(movimentacao),
        frete: payload.frete ? payload.frete : 0.0,
        seguro: payload.seguro ? payload.seguro : 0.0,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.0,
        desconto: payload.desconto ? payload.desconto : 0.0,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : "",
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (totais.items && totais.items.length > 0) {
            const { vrItem = null, valorTotal = null, ...itemPedido } = item;
            return {
              ...itemPedido,
              total: totais.items[index].valorTotal,
              valor: totais.items[index].vrItem,
            };
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao, idClasse: '61cd2739ad47e0a02eab67fe', };
          return titulo;
        }),
      };

      const plCheck = items.map((it: any) => {
        return {
          chave: it.chave ? it.chave : null,
          idRecurso: it.idRecurso ? it.idRecurso : null,
          idNucleo: it.idNucleo ? it.idNucleo : null,
          idLote: it.idLote ? it.idLote : null,
          quantidade: it.quantidade ? it.quantidade : 0,
        }
      });
      const podeVender = await checarEstoque({
        items: plCheck,
        tipo: 'VENDA',
      });

      if (podeVender && Array.isArray(podeVender)) {
        for (const it of podeVender) {
          if (it.ok === false) {
            return Toast.show(
              `O item de chave ${it.chave} não pode ser vendido pois gerará estoque negativo`,
              "error"
            );
          }
        }
      }

      await criarPedido(toSendPayload);
      handleChangeLoading("salvar", false);
      Toast.show(
        `A baixa de chave ${chaveCriacao} foi criado com sucesso.`,
        "success"
      );
      history.push("/pedidos-de-saida");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  }

  async function handleSalvarUpdate() {
    try {
      let toSendPayload: any = {
        tipo: "baixa de venda",
        baixaAutomatica,
        baixa: baixaAutomatica || baixaNaoAutomatica,
        baixaId: user?.id,
        idAprovador: null,
        aprovacao: null,
        idLocEscritu: lookups.LocEscrituracao.value,
        idCondicaoPag: lookups.CondicaoPagamento ? lookups.CondicaoPagamento.value : null,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idRepresentante: lookups.Representante
        ? lookups.Representante.value
        : null,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        frete: payload.frete ? payload.frete : 0.0,
        seguro: payload.seguro ? payload.seguro : 0.0,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.0,
        desconto: payload.desconto ? payload.desconto : 0.0,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : "",
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (baixaAutomatica) {
            item = { ...item, chpedbaixa: item.chave };
          }
          if (totais.items && totais.items.length > 0) {
            const { vrItem = null, valorTotal = null, ...itemPedido } = item;
            return {
              ...itemPedido,
              total: totais.items[index].valorTotal,
              valor: totais.items[index].vrItem,
            };
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao, idClasse: '61cd2739ad47e0a02eab67fe', };
          return titulo;
        }),
      };

      if (comAprovador) {
        toSendPayload = {
          ...toSendPayload,
          idAprovador: user?.id,
          aprovacao: new Date(),
        };
      }

      const plCheck = items.map((it: any) => {
        return {
          chave: it.chave ? it.chave : null,
          idRecurso: it.idRecurso ? it.idRecurso : null,
          idNucleo: it.idNucleo ? it.idNucleo : null,
          idLote: it.idLote ? it.idLote : null,
          quantidade: it.quantidade ? it.quantidade : 0,
        }
      });
      const podeVender = await checarEstoque({
        items: plCheck,
        tipo: 'VENDA',
      });

      if (podeVender && Array.isArray(podeVender)) {
        for (const it of podeVender) {
          if (it.ok === false) {
            return Toast.show(
              `O item de chave ${it.chave} não pode ser vendido pois gerará estoque negativo`,
              "error"
            );
          }
        }
      }

      await updatePedido(chaveCriacao, toSendPayload);
      handleChangeLoading("salvar", false);
      Toast.show(
        `O pedido de chave ${chaveCriacao} foi atualizado com sucesso.`,
        "success"
      );
      history.push("/pedidos-de-saida");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  }

  async function checarQuantidadeItems(): Promise<boolean> {
    try {
      const chaves = items.map((item: any) => item.chpedbaixa);
      const result = await checkChavesParaBaixa(chaves);
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const baixas = result[i];

        if (baixas.faltaBaixar === 0) {
          Toast.show(`O item de chave ${item.chave} já foi baixado`, "error");
          return false;
        }

        if (item.quantidade > baixas.faltaBaixar) {
          Toast.show(
            `A baixa do item de chave ${item.chave} só pode ter a quantidade menor ou igual a ${baixas.faltaBaixar}`,
            "error"
          );
          return false;
        }
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function checarBaixasItens(): Promise<boolean> {
    try {
      const chaves = items.map((item: any) => item.chave);
      const result = await checkIfPedidoHasBaixa(chaves);
      if (result.hasBaixa === false) {
        return true;
      }
      Toast.show("Este pedido tem baixas e não pode ser desaprovado");
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function handleSubmit(e: any) {
    let tipo, calcular, titulos, baixaOK, podeDesaprovar;
    if (e) {
      e.preventDefault();
      tipo = e.nativeEvent.submitter.name;
    }

    switch (tipo) {
      case "salvar":
        calcular = await handleCalcular();
        titulos = validarTitulos();
        if (calcular && titulos) {
          if (baixaNaoAutomatica) {
            baixaOK = await checarQuantidadeItems();
            if (baixaOK) {
              await handleSalvarBaixaNaoAutomatica();
            }
          } else {
            if (itemEdicao) {
              await handleSalvarUpdate();
            } else {
              await handleSalvar();
            }
          }
        }
        break;
      case "aprovar":
        if (comAprovador) {
          if (itemEdicao && !(baixaAutomatica || baixaNaoAutomatica) && !xmlinf) {
            podeDesaprovar = await checarBaixasItens();
            if (podeDesaprovar) {
              setComAprovador(false);
            }
          } else {
            setComAprovador(false);
          }
        } else {
          calcular = await handleCalcular();
          // titulos = validarTitulos();

          if (calcular) {
            setComAprovador(true);
          }
        }
        break;
      case "calcular":
        await handleCalcular();
        break;
      default:
        break;
    }
  }

  function handleChangeTitulos(result: any) {
    setTitulos(result);
  }

  async function deleteOrder() {
    try {
      await deleteOrders(user?.id, chaveCriacao);
      toast.success("Excluído com sucesso.");
      closeModal();
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  }

  const handleModalDeleteOrder = () => {
    if (comAprovador) return toast.error("O pedido precisa ser desaprovado.");
    else
      openModal({
        type: "confirm",
        title: "Excluir",
        children: renderDeleteContent,
        onConfirm: () => deleteOrder(),
        onCancel: closeModal(),
        closeOnAction: false,
      });
  };

  const renderDeleteContent = () => {
    return (
      <>
        <h3 style={{ marginTop: 2, marginBottom: 10, fontWeight: 400 }}>
          Tem certeza que deseja excluir?
        </h3>
        <p>Esta ação é irreversível.</p>
      </>
    );
  };

  function calcTitulosFixedValues(titles: any = null, total: any = null) {
    let _titles = titles || titulos;
    if (!_titles || !total) return [];
    const values: any[] = [];
    let lastDate = null;
    for (let index = 0; index < _titles.length; index++) {
      const titulo: any = _titles[index];
      let data = {
        vencimento: "",
        correcao: "",
        valorCorrigido:
          total.titulos && total.titulos.length > 0 && total.titulos[index]
            ? total.titulos[index].valorCorrigido
            : 0.0,
      };
      if (titulo.prazo) {
        const emissao = lastDate ? lastDate : convertToDate(payload.emissao);
        if (emissao) {
          const vencimentoDate = addDays(emissao, titulo.prazo);
          const vencimento = vencimentoDate.toISOString();
          const correcao = isWeekend(vencimentoDate) ? nextMonday(vencimentoDate).toISOString() : vencimento;
          lastDate = vencimentoDate;
          data = { ...data, vencimento, correcao };
        }
      } else if (titulo.vencimento && titulo.correcao) {
        data = { ...data, vencimento: titulo.vencimento, correcao: titulo.correcao };
      }
      values.push(data);
    }
    return setTitulosFixedValues(values);
  }

  function calcTitulosFixedValue(titles: any = null, total: any = null) {
    let _titles = titles || titulos;
    if (total.valorTotal && _titles && _titles.length > 0) {
      const porTitulo = parseFloat(
        String(total.valorTotal / _titles.length)
      );
      setTitulosFixedValue({
        valorPrincipal: porTitulo,
      });
    } else {
      setTitulosFixedValue({
        valorPrincipal: 0.0,
      });
    }
  }

  function moneyFormat(value: any) {
    const money = formatMoney(value).replace('R$', '');
    return money;
  }

  return (
    <Wrapper>
      <HeaderContent>
        <Title>
          {baixaNaoAutomatica
            ? "Baixa do Pedido"
            : baixaAutomatica
            ? "Baixa Automática"
            : "Pedido de Saída"}{" "}
          - Ch. de Criação N° {chaveCriacao} <span>({subtitulo})</span>
        </Title>
        <ActionsContainer>
          <Button onClick={() => history.goBack()}>Voltar</Button>
          <Button
            form="custom-form"
            type="submit"
            id="calcular"
            name="calcular"
            disabled={loadings.calcular}
          >
            {loadings.calcular ? "Calculando..." : "Calcular"}
          </Button>
          {
            (!baixaPedido && (
              <Button
                form="custom-form"
                type="submit"
                id="aprovar"
                name="aprovar"
                disabled={loadings.calcular}
              >
                {comAprovador && "Desaprovar"}
                {!comAprovador
                  ? loadings.calcular
                    ? "Aprovando..."
                    : "Aprovar"
                  : ""}
              </Button>
            ))
          }
          {((baixaPedido && itemEdicao) || (itemEdicao && !comAprovador)) && (
            <Button
              form="custom-form"
              type="submit"
              id="apagar"
              name="apagar"
              onClick={() => handleModalDeleteOrder()}
            >
              Apagar
            </Button>
          )}
          {/* <Button type="button" onClick={() => {}}>Imprimir</Button> */}
          <Button
            form="custom-form"
            type="submit"
            id="salvar"
            name="salvar"
            disabled={loadings.salvar}
          >
            {loadings.salvar ? "Salvando..." : "Salvar"}
          </Button>
          {/* <Button type="button" onClick={() => {}}>Faturar</Button> */}
        </ActionsContainer>
      </HeaderContent>
      <Content id="custom-form" onSubmit={handleSubmit}>
        <Container fluid style={{ width: "100%" }}>
          <Row>
            <Col md={7}>
              <Row>
                <h1>Cabeçalho</h1>
              </Row>
              <Row>
                <Col md={baixaNaoAutomatica ? 6 : 4}>
                  <DateInput
                    disabled={comAprovador || xml}
                    label="Emissão"
                    id="emissao"
                    name="Emissão"
                    value={payload.emissao}
                    onChange={({ target: { value } }) =>
                      handleChangePayload("emissao", value)
                    }
                    withHour={true}
                  />
                </Col>
                <Col md={baixaNaoAutomatica ? 6 : 4}>
                  <LookupInput
                    disabled={comAprovador}
                    initialState={initialLookups.Representante}
                    label="Vendedor"
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: "Vendedores",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Representante", options)
                    }
                  />
                </Col>
                {!baixaNaoAutomatica && (
                  <Col md={4}>
                    <TextInput
                      label="Aprovador"
                      id="aprovador"
                      disabled
                      fixed
                      value={
                        comAprovador
                          ? aprovador
                            ? aprovador
                            : "Pedido não aprovado"
                          : "Pedido não aprovado"
                      }
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={6}>
                  <LookupInput
                    disabled={comAprovador || xml}
                    label="Local de Escrituração"
                    initialState={initialLookups.LocEscrituracao}
                    endpoint="entidades"
                    field="nome"
                    labelField="nomeFantasia"
                    filters={{
                      classe: "Local de Escrituração",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("LocEscrituracao", options)
                    }
                  />
                </Col>
                <Col md={6}>
                  <LookupInput
                    disabled={comAprovador || xml}
                    label="Estabelecimento"
                    initialState={initialLookups.Estabelecimento}
                    endpoint="entidades"
                    field="nome"
                    labelField="nomeFantasia"
                    filters={{
                      classe: "Estabelecimentos",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Estabelecimento", options)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LookupInput
                    disabled={comAprovador || xml}
                    label="Pessoa"
                    endpoint="entidades"
                    initialState={initialLookups.Pessoa}
                    field="nome"
                    filters={{
                      classe: "Pessoas",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Pessoa", options)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LookupInput
                    disabled={comAprovador}
                    initialState={initialLookups.CondicaoPagamento}
                    label="Condição de Pagamento"
                    endpoint="tabela"
                    field="nome"
                    filters={{
                      classe: "Condições de Pagamento",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("CondicaoPagamento", options)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    disabled={comAprovador}
                    label="Observação"
                    id="observacao"
                    value={payload.observacao}
                    onChange={({ target: { value } }) =>
                      handleChangePayload("observacao", value)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={5}>
              {baixaNaoAutomatica || baixaAutomatica ? (
                <>
                  <Row>
                    <Col>
                      <h1>Faturamento</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <DateInput
                        disabled={comAprovador}
                        label="Data de Movimentação"
                        id="movimentacao"
                        name="Data de Movimentação"
                        value={faturamento.movimentacao}
                        onChange={({ target: { value } }) =>
                          handleChangeFaturamentoPayload("movimentacao", value)
                        }
                        withHour={true}
                      />
                    </Col>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    <Col md={2}>
                      <TextInput
                        disabled={comAprovador || xml}
                        label="Série"
                        id="serie"
                        value={faturamento.serie}
                        onChange={({ target: { value } }) =>
                          handleChangeFaturamentoPayload("serie", value)
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <TextInput
                        disabled={comAprovador}
                        label="Número"
                        id="numero"
                        value={parseInt(faturamento.numero) || 0}
                        onChange={({ target: { value } }) =>
                          handleChangeFaturamentoPayload("numero", String(value))
                        }
                        masked
                        money
                        precision={0}
                      />
                    </Col>
                    <Col md={6}>
                      <LookupInput
                        disabled={comAprovador}
                        label="Tipo de Documento"
                        endpoint="tabela"
                        initialState={initialLookups.TipoDoc}
                        field="nome"
                        filters={{
                          classe: "Tipos de Documento",
                        }}
                        multi={false}
                        onChange={(options) =>
                          handleChangeLookup("TipoDoc", options)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <LookupInput
                        disabled={comAprovador}
                        initialState={initialLookups.TipoDeFrete}
                        label="Tipo de Frete"
                        endpoint="tabela"
                        field="nome"
                        filters={{
                          classe: "Tipos de Frete",
                        }}
                        multi={false}
                        onChange={(options) =>
                          handleChangeLookup("TipoDeFrete", options)
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <LookupInput
                        disabled={comAprovador}
                        initialState={initialLookups.Transportadora}
                        label="Transportadora"
                        endpoint="entidades"
                        field="nome"
                        filters={{
                          classe: "Pessoas",
                        }}
                        multi={false}
                        onChange={(options) =>
                          handleChangeLookup("Transportadora", options)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        disabled={true}
                        label="Chave de acesso NFe"
                        value={chacessonf}
                        id="chacessonf"
                        // onChange={({ target: { value } }) => handleChangeFaturamentoPayload('observacaoFiscal', value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        disabled={comAprovador}
                        label="Observação p/ Nota Fiscal"
                        id="observacaoFiscal"
                        onChange={({ target: { value } }) =>
                          handleChangeFaturamentoPayload(
                            "observacaoFiscal",
                            value
                          )
                        }
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <CardTable
                  title="Detalhes"
                  data={detalhesData(payload, lookups, observacaoToShow)}
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 32 }}>
            <Col md={2.5}>
              <TextInput
                label="Frete"
                id="frete"
                masked
                money
                disabled={comAprovador}
                onChange={({ target: { value } }) =>
                  handleChangePayload("frete", value)
                }
              />
            </Col>
            <Col md={2.5}>
              <TextInput
                label="Seguro"
                id="seguro"
                masked
                money
                disabled={comAprovador}
                onChange={({ target: { value } }) =>
                  handleChangePayload("seguro", value)
                }
              />
            </Col>
            <Col md={2.5}>
              <TextInput
                label="Acréscimo"
                id="acrescimo"
                masked
                money
                disabled={comAprovador}
                onChange={({ target: { value } }) =>
                  handleChangePayload("acrescimo", value)
                }
              />
            </Col>
            <Col md={2.5}>
              <TextInput
                label="Desconto"
                id="desconto"
                masked
                money
                disabled={comAprovador}
                value={payload.desconto}
                onChange={({ target: { value } }) =>
                  handleChangePayload("desconto", value)
                }
              />
            </Col>
            <Col md={2}>
              <TextInput
                label="Acessório"
                id="acessorio"
                disabled={comAprovador}
                onChange={({ target: { value } }) =>
                  handleChangePayload("idAcessorio1", value)
                }
              />
            </Col>
          </Row>
          <Table
            actions={["insert", "delete"]}
            title="Itens"
            onChange={(result: any) => setItems(result)}
            fromForm
            withTotal
            isRecurso
            initialValue={initialItems}
            disabled={comAprovador}
            chaveCriacao={chaveCriacao}
            lookupsRegras={lookups}
            fixedValues={totais.items}
            importXML={location.state?.xml}
            total={[
              {
                field: "vrItem",
                value: moneyFormat(totais.vrItemTotal),
              },
              {
                field: "acrescimoItem",
                value: moneyFormat(totais.acrescimoItemTotal),
              },
              {
                field: "descontoItem",
                value: moneyFormat(totais.descontoItemTotal),
              },
              {
                field: "ipi",
                value: moneyFormat(totais.ipiTotal),
              },
              {
                field: "valorTotal",
                value: moneyFormat(totais.valorTotal),
              },
            ]}
            cols={colunasItens}
          />
          <Table
            title="Títulos"
            actions={["insert", "delete"]}
            fromForm
            withTotal
            isRecurso
            isTitulo
            initialValue={initialTitulos}
            disabled={comAprovador}
            fixedValues={titulosFixedValues}
            fixedValue={titulosFixedValue}
            total={[
              {
                field: "valorPrincipal",
                value: moneyFormat(totais.valorTotal),
              },
              {
                field: "valorCorrigido",
                value: moneyFormat(totais.valorCorrigidoTotal),
              },
            ]}
            cols={colunasTitulos}
            onChange={handleChangeTitulos}
          />
        </Container>
      </Content>
    </Wrapper>
  );
};

export default SalesOrder;

import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  height: 54px;
  position: absolute;
  width: 100%;
  bottom: 0;
  /* padding-top: 20px; */

  img{
    height: 32px;
    object-fit: contain;
    margin-right: 16px;
  }

  p{
    color: #9ea4ac;
    font-weight: 300;
    font-size: 14px;
  }
`

export const LinhaVertical = styled.hr`
  height: 18px;
  border-left: 1px solid #9ea4ac;
  margin: 0px 6px;
`
export const Link = styled.a`
  text-decoration: none;
  color: #9ea4ac;
  font-weight: 300;
  font-size: 14px;

  &:hover{
    text-decoration: underline;
  }
`
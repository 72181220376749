import React from 'react';
import {Toaster} from 'react-hot-toast';
import ContextProvider from './contexts';
import GlobalStyles from './constants/GlobalStyles';
import Routes from './navigation';
import "react-datepicker/dist/react-datepicker.css";
import 'react-contexify/dist/ReactContexify.css';

const App: React.FC = () => {
  return (
    <ContextProvider>
      <Routes />
      <GlobalStyles />
      <Toaster />
    </ContextProvider>
  );
}

export default App;

import React, { useEffect, useMemo, useState } from 'react';
import { Column, Data } from '../types';
import { Cell } from './styles';
import { 
  formatCPF,
  formatDate,
  formatMoney,
  formatPhoneNumber,
} from '../../../utils/format';
import LookupInput from '../../TableLookups';

export type TableCellProps = {
  data: Data;
  toObserve?: Data;
  column: Column;
};

const TableCell: React.FC<TableCellProps> = ({ column, data, toObserve }) => {
  const [cellData, setCellData] = useState<any>(data ? data : '');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (data && !loaded) {
      setCellData(data);
      setLoaded(true);
    }
  }, [data, loaded]);

  useEffect(() => {
    if (toObserve && toObserve.id) {
      if (toObserve.id !== "null" && toObserve.id !== "") {
        setCellData(toObserve.id);
      } else {
        setCellData('');
      }
    } else {
      setCellData('');
    }
  }, [toObserve]);

  const CellComponent = useMemo(() => {
    if (!column) return null;
    const toShowData = cellData as string;
    switch (column.type) {
      case 'phone':
        return data === null ? '-' : formatPhoneNumber(toShowData);
      case 'cpf':
        return data === null ? '-' : formatCPF(toShowData);
      case 'money':
        return data === null ? '-' : formatMoney(toShowData);
      case 'date':
        return data === null ? '-' : formatDate(toShowData);
      case 'text':
        return data === null ? '-' : toShowData;
      case 'input':
        return <input 
                type="text" 
                value={toShowData}
                disabled={!column.editable}
                onChange={(e) => setCellData(e.target.value)}
              />;
      case 'lookup':
        return (
          <LookupInput
            key={column.id}
            endpoint={column.endpoint || ''}
            field={'nome'}
            filters={column.filters}
            disabled={!column.editable}
            onChange={() => {}}
          />
        );
      case 'checkbox':
        return <input
                type="checkbox"
              />;
      default:
        return data ? toShowData : '---';
    }
  }, [column, data, cellData]);

  return (
    <Cell {...column}>
      {CellComponent}
    </Cell>
  );
}

export default TableCell;
